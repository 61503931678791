import React from 'react';

import {ReactComponent as ChevronLeftIcon} from 'assets/svg/chevron_left.svg';
import {ReactComponent as ChevronRightIcon} from 'assets/svg/chevron_right.svg';
import styles from 'css_modules/Pagination.module.css';
import {Select} from "./Select";

export const Pagination = ({user,docs, setPage, limit, setLimit}) => {
    const goToFirst = () => setPage(1);
    const goToPrevious = () => setPage(docs.current - 1);
    const goToNext = () => setPage(docs.current + 1);
    const goToLast = () => setPage(docs.last);


    const limits = [10, 20, 30, 40, 50, 100, 200];
    let s = limits.map(lim => {
        return {id: lim, title: lim}
    })
    const selectChange = (e) => {
        let value = e.target.value;
        localStorage.setItem("limit"+user.pk, ""+parseInt(value) )
        setLimit(value);
        setPage(1);
    }

    return (
        <>
        <div className={styles.wrapper}>
            <div className={styles.paginationDiv}>
                <div className={styles.paginationPager}>
                    <div style={{ marginRight: "-0.5em" }}>Zobrazit</div>
                    <Select
                        name={'limit_select'}
                        data={s}
                        onChange={selectChange}
                        notNull={true}
                        value={limit}
                        className={styles.paginator}
                    />
                    
                </div>
            </div>
            <div className={styles.pagination}>
                <button disabled={docs.current === 1} onClick={goToFirst} className={styles.button}>
                    <ChevronLeftIcon className={styles.icon}/>
                    <ChevronLeftIcon className={styles.rightIcon}/>
                </button>
                <button disabled={docs.current === 1} onClick={goToPrevious} className={styles.button}>
                    <ChevronLeftIcon className={styles.icon}/>
                </button>
                {docs.current > 4 && docs.current === docs.last && (
                    <button
                        onClick={() => setPage(docs.current - 4)}
                        className={styles.button}
                    >
                        {docs.current - 4}
                    </button>
                )}
                {docs.current > 3 && docs.last - docs.current < 2 && (
                    <button
                        onClick={() => setPage(docs.current - 3)}
                        className={styles.button}
                    >
                        {docs.current - 3}
                    </button>
                )}
                {docs.current > 2 && (
                    <button
                        onClick={() => setPage(docs.current - 2)}
                        className={styles.button}
                    >
                        {docs.current - 2}
                    </button>
                )}
                {docs.current > 1 && (
                    <button onClick={goToPrevious} className={styles.button}>{docs.current - 1}</button>
                )}
                <button disabled className={styles.current}>{docs.current}</button>
                {docs.current < docs.last && (
                    <button onClick={goToNext} className={styles.button}>{docs.current + 1}</button>
                )}
                {docs.last - docs.current > 2 && (
                    <button
                        onClick={() => setPage(docs.current + 2)}
                        className={styles.button}
                    >
                        {docs.current + 2}
                    </button>
                )}
                {docs.current < 3 && docs.last - docs.current > 3 && (
                    <button
                        onClick={() => setPage(docs.current + 3)}
                        className={styles.button}
                    >
                        {docs.current + 3}
                    </button>
                )}
                {docs.current < 2 && docs.last - docs.current > 4 && (
                    <button onClick={() => setPage(5)} className={styles.button}>5</button>
                )}
                <button disabled={docs.current === docs.last} onClick={goToNext} className={styles.button}>
                    <ChevronRightIcon className={styles.icon}/>
                </button>
                <button disabled={docs.current === docs.last} onClick={goToLast} className={styles.button}>
                    <ChevronRightIcon className={styles.icon}/>
                    <ChevronRightIcon className={styles.rightIcon}/>
                </button>
            </div>

            <div>
            <p className={styles.paginationPar}>doklady {docs.start}-{docs.end} z {docs.count} </p>
            </div>
        </div>
    </>
    )
}