import React ,{useState,useEffect} from 'react';
import {useFormikContext} from 'formik';
import 'react-datepicker/dist/react-datepicker.css';


import {useFilter, useRequired, useFetch} from 'helpers/hooks';
import {useLocation } from 'react-router-dom';

import {DatePicker} from 'components/DatePicker';
import {FieldsWrapper} from 'components/FieldsWrapper';
import {Input} from 'components/Input';
//import {Select} from 'components/Select';
import {AutocompleteSelect} from 'components/AutocompleteSelect';

export const General = (
    {
        accountingYears, activities, centres, clientCompanies, contracts, doc, docTypes, editable,
        isAccountant, isCashDeskDocType, fetchRequiredFields, noteEditable, setNoteChanged, user, handleCustomTypeSave,docCustomType,setDocCustomType,excludedKeys,setAccountingYear,taxRates,countries
    }
) => {
    
    const {handleChange, setFieldValue, values} = useFormikContext();
    
    const [refresh,setRefresh] = useState(true)
    let location = useLocation();
    const modify = location.pathname.split("/")[2] === "modify";
    
    let requiredFields = fetchRequiredFields(user,values?.info["document_type"], values?.info["payment_type"])
    
    let [manualFire, setManualFire] = useState(0);
    const [selectedDocType, setselectedDocType] = useState("");
    const [previousDependencies, setPreviousDependencies] = useState({
        client_company: values.info.client_company,
        document_identifier: values.info.document_identifier,
        document_type: values.info.document_type,
        number: values.info.number,
        date: values.info.date,
        taxable_supply_date: values.info.taxable_supply_date,
        due_date: values.info.due_date,
        accounting_date: values.info.accounting_date,
        tax_deduction_date: values.info.tax_deduction_date,
        accounting_year: values.info.accounting_year,
        activity: values.info.activity,
        centre: values.info.centre,
        contract: values.info.contract,
        note: values.info.note
    });
    
    
    let canChangeClientCompany= true
    if (String(user.first_name+" "+user.last_name) !== doc.added_by && values.info["state"]==='tpl'){
        canChangeClientCompany= false
    }

    if (values.info["state"]==='tpl'){
        excludedKeys=[]
    }
    
    if ( (selectedDocType==="" || selectedDocType==="fpr")  && selectedDocType !== values.info.document_type ){
        setselectedDocType( values.info.document_type )
        handleCustomTypeSave(values.info.document_type )
    }
    if (docCustomType!=="" &&selectedDocType!==docCustomType){
        setselectedDocType( docCustomType)
        handleCustomTypeSave( docCustomType)
    }
    
    let allDocTypes = []
    docTypes.forEach(e => {
        if (e){allDocTypes.push(e)}
    })

    
    allDocTypes.push(allDocTypes.splice(allDocTypes.findIndex(c => c["slug"] === "rzn"), 1)[0])
    
    
    const customDocTypes = useFetch('/document_user_created_custom_types/', []);
    if (customDocTypes.data && customDocTypes.data.length>0){
        allDocTypes = []
        docTypes.forEach(e => {
            allDocTypes.push(e)
        })
        let div1 = {"name":"------", slug:"",disabled:"true"}
        let div = {"name":"------", slug:"none",disabled:"true"}
        if (!JSON.stringify(docTypes).includes(JSON.stringify(div)) && !JSON.stringify(docTypes).includes(JSON.stringify(div1))){
            docTypes.splice( docTypes.length-1, 0, div )
        }
        
        customDocTypes.data.forEach( e => {
            let t = {}
            t["id"]=e["id"]
            t["name"]=e["name"]
            t["slug"]=e["id"]
            
            if (!JSON.stringify(docTypes).includes(JSON.stringify(t))){
                docTypes.push(t)
            }
        })
    }
 
    let currentuuid =sessionStorage.getItem("currentuuid")
    if (currentuuid !== doc.uuid){
        if (currentuuid!==''){
            //window.location.reload();
        }
    }
    sessionStorage.setItem("currentuuid", doc.uuid);
    
    

    const yearFilterValue = accountingYears.find((year) => year.id === parseInt(values.info.accounting_year))?.year;
    const filteredAccountingYears = useFilter(accountingYears).sort((a, b) => b.year - a.year);;
    const filteredActivities = useFilter(activities, {year: yearFilterValue});
    const filteredCentres = useFilter(centres, {year: yearFilterValue});
    const filteredContracts = useFilter(contracts, {year: yearFilterValue});
    const activityRequired =        useRequired('activity', requiredFields);
    const centreRequired =          useRequired('centre', requiredFields);
    const contractRequired =        useRequired('contract', requiredFields);
    const documentTypeRequired =    useRequired('document_type', requiredFields);
    
    if (modify===false){
        values.info.document_type = values.info.document_type || 'fpr';
    }
    
    
    const docType = values.info.document_type;
    const documentIdentifierRequired = useRequired('document_identifier', requiredFields)
        || (isAccountant && docType === 'rzn');
    const documentNumberRequired =  useRequired('number', requiredFields);
    const noteRequired =            useRequired('note', requiredFields);
    const taxableSupplyRequired =   useRequired('taxable_supply_date', requiredFields);
    const accountingYearRequired =  useRequired('accounting_year', requiredFields);
    const dateRequired =            useRequired('date', requiredFields);

    const dueDateRequired =         useRequired('due_date', requiredFields);
    const accountingDateRequired =  useRequired('accounting_date', requiredFields);
    const taxDeductionDateRequired = useRequired('tax_deduction_date', requiredFields);

    const clientCompanyEditable = editable && !doc.added_by_client;
    const accountingSystemDataEditable = editable && values.info.client_company;

    const docNumberRequired = ((isAccountant && ['fpr', 'fpz', 'fvy', 'fvz', 'oza'].includes(docType)) || (documentNumberRequired && docType !== 'rzn')) &&  values.info["state"]!=='tpl' ;
    const taxableSupplyDateRequired =     ((isAccountant && ['fpr', 'fpz', 'fvy', 'opo', 'oza', 'pvy', 'ppr'].includes(docType))  || taxableSupplyRequired)&&  values.info["state"]!=='tpl';
    const dueDateLabel = isCashDeskDocType ? 'Datum platby' : 'Datum splatnosti';

      
    const accountingDateLabel = "Datum účetního případu";
    const taxDeductionDateLabel = "Datum odpočtu";

    const get_toggle_dates = (companyId) =>{
        let currentCompany = clientCompanies.find(e => parseInt(e["id"]) === parseInt(companyId))
        if (currentCompany!==undefined && currentCompany["toggle_dates"]===true){
            if (user["toggle_dates"] && user["toggle_dates"]===true){
                return true
            }
        }
        return false
    }

    const [toggleDates, setToggleDates] = useState(false);
    
    if (  get_toggle_dates(values.info.client_company) !== toggleDates ){
        if (get_toggle_dates(values.info.client_company) ===false &&toggleDates===true){
            [
                'info.accounting_date',
                'info.tax_deduction_date'
            ]
                .forEach(field => setFieldValue(field, null));
        }
        setToggleDates( get_toggle_dates(values.info.client_company))
    }
    
       
    const handleAccountingYearChange = (e) => {
        setFieldValue("info.accounting_year",parseInt(e))
        values.info["accounting_year"] = e

        let nullifiedVals = ['info.activity', 'info.centre', 'info.contract', 'info.num_series']
        nullifiedVals.forEach(field => setFieldValue(field, ''))

        setRefresh(!refresh)
    };

    const getMaxYearObject = (array, clientCompany) => {
        const filteredArray = array.filter(item => item.client_company === clientCompany);
        
        return filteredArray.reduce((max, current) => {
          return current.year > max.year ? current : max;
        }, filteredArray[0]);
      };

    if (!values.info["accounting_year"] && values.info["client_company"] && !modify){
        console.log("Setting latest year for the first time for CC " + values.info["client_company"])
        setFieldValue( 'info.accounting_year',getMaxYearObject(accountingYears,values.info["client_company"]).id)
        values.info["accounting_year"] = getMaxYearObject(accountingYears,values.info["client_company"]).id
    }
    const handleClientCompanyChange =(e) => {
        //handleChange(event);

        console.log("cc change")
        values.info["client_company"] = e
        
        let nullifiedVals = [
            'info.accounting_year',
            'info.activity',
            'info.centre',
            'info.contract',
            'info.account',
            'info.num_series',
            'vat_classification',
            'vat_kv_classification',
            'account_code'
        ]
        nullifiedVals.forEach(field => setFieldValue(field, ''));
        
        let newYearId = getMaxYearObject(accountingYears,e)?.id
        
        setFieldValue( 'info.accounting_year',newYearId)
        values.info["accounting_year"] = newYearId
        
        let countrySlug = null
        let oldCountrySlug = null
        setFieldValue("info.client_company",parseInt(e))
        
        
        let newCompany = clientCompanies.find(company => parseInt(company.id) === parseInt(e));
        countrySlug = newCompany ? newCompany.country.slug : null;
        let oldCompany = clientCompanies.find(company => parseInt(company.id) === parseInt(values.info["client_company"]));
        oldCountrySlug = oldCompany ? oldCompany.country.slug : null;
    
        if (countrySlug === 'sk'){
            values.info["supplier_vat"] = String(values.info["supplier_vat"]).replace(/[a-zA-Z]/g, '');
        }
        else{
            values.info["supplier_sk_ic_dph"] = ""
        }
        
        if (countrySlug===oldCountrySlug && newCompany){
            if (newCompany.toggle_items === false && oldCompany.toggle_items===true &&values.items.length>0){
                console.log("Same cc country, keeping costs")
                let interCosts = []
                values.costs.forEach(i=>{
                    let c = {}
                    c["value_original"]=i["value_original"]
                    c["tax_rate"]=i["tax_rate"]
                    c["tax"]=i["tax"]
                    c["value_final"]=i["value_final"]
                    
                    interCosts.push(c)
                })
                setFieldValue('costs', interCosts)
            }
            else if (newCompany.toggle_items === true && oldCompany.toggle_items===true){
                let interItems=[]
                values.items.forEach(i=>{
                    let c = {}
                    c["price_final"]=i["price_final"]
                    c["price_original"]=i["price_original"]
                    c["price_unit"]=i["price_unit"]
                    c["price_vat"]=i["price_vat"]
                    c["quantity"]=i["quantity"]
                    c["tax_rate"]=i["tax_rate"]
                    c["text"]=i["text"]
                    c["unit"]=i["unit"]
                    c["reverse_charge"]=i["reverse_charge"]
                    c["discount_percentage"]=i["discount_percentage"]
                    c["sympar"]=i["sympar"]
                    c["code"]=i["code"]
                    c["note"]=i["note"]
                    interItems.push(c)
                })
                setFieldValue('items', interItems)   
            }
            else{
                setFieldValue('items', [])
            }
        }
        else if (oldCountrySlug === null && countrySlug==="cz"){
            console.log("Going from null CC to " + countrySlug + " country CC")
            if (newCompany.toggle_items === false){
                console.log("New CC does not have items")
                console.log("Keeping costs")
                let interCosts = []
                values.costs.forEach(i=>{
                    let c = {}
                    c["value_original"]=i["value_original"]
                    c["tax_rate"]=i["tax_rate"]
                    c["tax"]=i["tax"]
                    c["value_final"]=i["value_final"]
                    
                    interCosts.push(c)
                })
                
                setFieldValue('costs', interCosts)
                setFieldValue('items', [])
            }
            else{
                console.log("New CC has items, doing nothing")
            }
            
        }
        else{
            console.log("Different cc country, wiping costs")
            setFieldValue('items', [])
            setFieldValue('costs', [])
        }
        
        setRefresh(!refresh)
    };

    const handleDocumentTypeChange = (e) => {
        let slug = e
        if (slug!=="none"){
            ['info.account_code', 'info.num_series'].forEach(
                field => setFieldValue(field, '')
            );
            
            setselectedDocType(slug)
            handleCustomTypeSave(slug)
            setDocCustomType(slug)
            
            values.info.document_type = slug
            setFieldValue('info.document_type', slug)
            
            if (parseInt(slug)){
                slug='rzn'
            }
            
            const getDocTypeExpense = slug => docTypes.find(docType => docType.slug === slug)?.is_expense;

            if (getDocTypeExpense(slug)!== null && getDocTypeExpense(slug) !== getDocTypeExpense(values.info.document_type)) {
                setFieldValue('info.vat_classification', '')
                setFieldValue('info.vat_kv_classification', '')
            }
            const updateReqFieldsVal = (slug === 'rzn' && isAccountant) || requiredFields.document_identifier;
            setFieldValue('required_fields.document_identifier', updateReqFieldsVal);
        }
        
    };

    const autofillVariableSymbol = () => { // autofill variable symbol by numbers from document number
        if (!values.info.variable_symbol && !['ppr', 'pvy'].includes(values.info.document_type)) {
            setFieldValue('info.variable_symbol', values.info.number.replace(/\D/g, ''));
        }
    };

    const handleNoteChange = event => {
        handleChange(event);
        setNoteChanged(true);
    }

    const handleActivityChange = (e) => {
        values.info["activity"] = e
        values.items.forEach((i)=>{
                i["activity"] = e
        })
        setRefresh(!refresh)
    }

    const handleCentreChange = (e) => {
        console.log(e)
        values.info["centre"] = e
        values.items.forEach((i)=>{
            
                i["centre"] = e
        })
        setRefresh(!refresh)
    }

    
    const handleContractChange = (e) => {
        values.info["contract"] = e
        values.items.forEach((i)=>{
                i["contract"] = e
        })
        setRefresh(!refresh)
    }
    useEffect(() => {
        
        const dependencies = {
            client_company: values.info.client_company,
            document_identifier: values.info.document_identifier,
            document_type: values.info.document_type,
            number: values.info.number,
            date: values.info.date,
            taxable_supply_date: values.info.taxable_supply_date,
            due_date: values.info.due_date,
            accounting_date: values.info.accounting_date,
            tax_deduction_date: values.info.tax_deduction_date,
            accounting_year: values.info.accounting_year,
            activity: values.info.activity,
            centre: values.info.centre,
            contract: values.info.contract,
            note: values.info.note,
        };
    
        for (const key in dependencies) {
            if (dependencies[key] !== previousDependencies[key]) {
                values.changedValues.push(key);
            }
        }
    
        if (JSON.stringify(dependencies)!==JSON.stringify(previousDependencies)){
            setPreviousDependencies(dependencies);
            setRefresh(!refresh); 
        }
        
        
    }, [values.info, manualFire, previousDependencies,values.changedValues,refresh]); 
    
        
    return (
        <>  
        {values.info["state"]==='tpl' && <Input name="info.template_name" isRequired={false}>
                <span style={{ color: 'black' }}>Název šablony</span>
        </Input>}
        

            <FieldsWrapper columns={2}>
                <Input
                    name="info.client_company"
                    component={AutocompleteSelect}
                    isRequired
                    data={clientCompanies}
                    disabled={!(clientCompanyEditable && canChangeClientCompany && user.role!=="ClientUser")}
                    selectedValue={values.info["client_company"]}
                    onChange={(newValue) => {
                        handleClientCompanyChange(newValue);
                        setManualFire(manualFire+=1)
                    }} 
                    highlighted={(modify &&values.changedValues.includes("client_company"))}
                >
                    Klientská společnost
                </Input>
                <Input
                    name="info.document_type"
                    component={AutocompleteSelect}
                    isRequired={documentTypeRequired}
                    displayAttribute="name"
                    data={allDocTypes}
                    disabled={!editable}
                    //value={selectedDocType}
                    noBlankOption={modify? false:true}
                    selectedValue={selectedDocType} 
                    onChange={(newValue) => {
                        handleDocumentTypeChange(newValue)
                        setManualFire(manualFire+=1)
                    }} 
                    highlighted={(modify &&values.changedValues.includes("document_type"))}
                >
                    Typ dokladu
                </Input>
                <Input
                    name="info.document_identifier"
                    disabled={!editable}
                    isRequired={documentIdentifierRequired}
                    highlighted={(modify &&values.changedValues.includes("document_identifier"))}
                >
                    Popis dokladu
                </Input>
                {!excludedKeys.includes("number") ? null : <Input
                    name="info.number"
                    disabled={!editable}
                    isRequired={docNumberRequired}
                    onBlur={autofillVariableSymbol}
                    highlighted={(modify &&values.changedValues.includes("number"))}
                >
                    Číslo dokladu
                </Input>}
                {!excludedKeys.includes("date") ? null  : <Input
                    name="info.date"
                    component={DatePicker}
                    disabled={!editable}
                    isRequired={dateRequired}
                    highlighted={(modify &&values.changedValues.includes("date"))}
                >
                    Datum
                </Input>}
                {!excludedKeys.includes("taxable_supply_date") ? null  :<Input
                    name="info.taxable_supply_date"
                    component={DatePicker}
                    disabled={!editable}
                    isRequired={taxableSupplyDateRequired}
                    highlighted={(modify &&values.changedValues.includes("taxable_supply_date"))}
                >
                    Datum zdanitelného plnění
                </Input>}
                {!excludedKeys.includes("due_date") ? null  :<Input
                    name="info.due_date"
                    component={DatePicker}
                    disabled={!editable}
                    isRequired={ dueDateRequired}
                    isHidden={isCashDeskDocType}
                    highlighted={(modify &&values.changedValues.includes("due_date"))}
                >
                    {dueDateLabel}
                </Input>}
                

                {( (toggleDates )
                    && !!excludedKeys.includes("accounting_date"))&&
                    <>
                        <Input
                            name="info.accounting_date"
                            component={DatePicker}
                            disabled={!editable}
                            isRequired={ accountingDateRequired}
                            isHidden={isCashDeskDocType}
                            highlighted={(modify &&values.changedValues.includes("accounting_date"))}
                        >
                            {accountingDateLabel}
                            
                        </Input>
                        <Input
                            name="info.tax_deduction_date"
                            component={DatePicker}
                            disabled={!editable}
                            isRequired={ taxDeductionDateRequired}
                            isHidden={isCashDeskDocType}
                            highlighted={(modify &&values.changedValues.includes("tax_deduction_date"))}
                        >
                            {taxDeductionDateLabel}
                        </Input>
                    </>
                }
                
                <Input name="info.accounting_year"
                       component={AutocompleteSelect}
                       data={filteredAccountingYears}
                       displayAttribute="year"
                       disabled={!accountingSystemDataEditable}
                       isRequired={accountingYearRequired}
                       noBlankOption={true}
                       highlighted={(modify &&values.changedValues.includes("accounting_year"))}
                       selectedValue={values.info["accounting_year"]} 
                       onChange={(newValue) => {
                            handleAccountingYearChange(newValue)
                            setManualFire(manualFire+=1)
                        }} 
                >
                    Účetní rok
                </Input>
                <Input
                    name="info.activity"
                    component={AutocompleteSelect}
                    data={filteredActivities}
                    disabled={!accountingSystemDataEditable}
                    isRequired={activityRequired}
                    selectedValue={values.info["activity"]} 
                    highlighted={(modify &&values.changedValues.includes("activity"))}
                    onChange={(newValue) => {
                        handleActivityChange(newValue)
                        setManualFire(manualFire+=1)
                    }} 
                >
                    Činnost
                </Input>
                <Input
                    name="info.centre"
                    component={AutocompleteSelect}
                    data={filteredCentres}
                    disabled={!accountingSystemDataEditable}
                    isRequired={centreRequired}
                    selectedValue={values.info["centre"]} 
                    highlighted={(modify &&values.changedValues.includes("centre"))}
                    onChange={(newValue) => {
                        handleCentreChange(newValue)
                        setManualFire(manualFire+=1)
                    }} 
                >
                    Středisko
                </Input>
                <Input
                    name="info.contract"
                    component={AutocompleteSelect}
                    data={filteredContracts}
                    disabled={!accountingSystemDataEditable}
                    isRequired={contractRequired}
                    selectedValue={values.info["contract"]} 
                    highlighted={(modify &&values.changedValues.includes("contract"))}
                    onChange={(newValue) => {
                        handleContractChange(newValue)
                        setManualFire(manualFire+=1)
                    }} 
                >
                    Zakázka
                </Input>
            </FieldsWrapper>
            <Input name="info.note" disabled={!(editable||noteEditable)} 
             highlighted={(modify &&values.changedValues.includes("note"))}
             isRequired={noteRequired} onChange={handleNoteChange}>Poznámka</Input>
            
        </>
    )
};