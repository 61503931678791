import React ,{useEffect,useState} from 'react';
import {useFormikContext} from 'formik';

import {useRequired} from 'helpers/hooks';
import {FieldsWrapper} from 'components/FieldsWrapper';
import {Input} from 'components/Input'
import {Select} from 'components/Select';
import {useFilter} from "helpers/hooks";
import {DatePicker} from "./DatePicker";
import {useLocation } from 'react-router-dom';
import {AutocompleteSelect} from 'components/AutocompleteSelect';

export const Payment = ({user,paymentTypes, accounts, editable, isAccountant, fetchRequiredFields,excludedKeys}) => {
    const {values} = useFormikContext();
    let [manualFire, setManualFire] = useState(0);
    const [previousDependencies, setPreviousDependencies] = useState({
        payment_type: values.info.payment_type,
        account: values.info.account,
        supplier_account_number: values.info.supplier_account_number,
        supplier_bank_code: values.info.supplier_bank_code,
        variable_symbol: values.info.variable_symbol,
        payment_date: values.info.payment_date,
    });

    let location = useLocation();
    const modify = location.pathname.split("/")[2] === "modify";
    
    let requiredFields = fetchRequiredFields(user,values?.info["document_type"], values?.info["payment_type"])
    
    const filteredAccounts = useFilter(accounts);
    const accountNumberRequired = useRequired('supplier_account_number', requiredFields);
    const bankCodeRequired = useRequired('supplier_bank_code', requiredFields);
    const variableSymbolRequired = useRequired('variable_symbol', requiredFields);
    let accountOnly = ['fvy', 'fvz', 'opo'].includes(values.info.document_type);
    let isCash = paymentTypes.find(type => type.id === parseInt(values.info.payment_type))?.code === 'cash';
    const accountEditable = editable && values.info.client_company;
    const [refresh,setRefresh] = useState(true)

    if (values.info["state"]==='tpl'){
        excludedKeys=[]
    }
    let modifyToggles = JSON.parse(sessionStorage.getItem("bulkDetailModifyToggles"))
    if (modify === true && modifyToggles.any_account_only && !values.info.document_type){
        accountOnly=true
    }
    if (modify === true && modifyToggles.any_cash &&!values.info.payment_type ){
        isCash=true
    }
    console.log(modifyToggles)
    useEffect(() => {
        console.log("effect ", manualFire);
        const dependencies = {
            payment_type: values.info.payment_type,
            account: values.info.account,
            supplier_account_number: values.info.supplier_account_number,
            supplier_bank_code: values.info.supplier_bank_code,
            variable_symbol: values.info.variable_symbol,
            payment_date: values.info.payment_date,
        };
    
        for (const key in dependencies) {
            if (dependencies[key] !== previousDependencies[key]) {
                values.changedValues.push(key);
            }
        }
        if (JSON.stringify(dependencies)!==JSON.stringify(previousDependencies)){
            setPreviousDependencies(dependencies);
            setRefresh(!refresh); 
        }
    },  [values.info, manualFire,previousDependencies,refresh,values.changedValues]);
    

    return (
        <FieldsWrapper columns={2}>
            <Input
                name="info.payment_type"
                component={AutocompleteSelect}
                data={paymentTypes}
                disabled={!editable}
                highlighted={(modify &&values.changedValues.includes("payment_type"))}
                selectedValue={values.info["payment_type"]} 
                    onChange={(newValue) => {
                        values.info["payment_type"] = newValue
                        setRefresh(!refresh)
                        setManualFire(manualFire+1)
                    }} 
            >
                Forma úhrady
            </Input>
            {accountOnly
                ? (
                    <Input
                        name="info.account"
                        component={Select}
                        data={filteredAccounts}
                        highlighted={(modify &&values.changedValues.includes("account"))}
                        disabled={!accountEditable}
                        isHidden={isCash}
                    >
                        Účet
                    </Input>
                ) : (
                    <>
                        <Input
                            name="info.supplier_account_number"
                            disabled={!editable}
                            isRequired={accountNumberRequired}
                            isHidden={isCash}
                            highlighted={(modify &&values.changedValues.includes("supplier_account_number"))}
                        >
                            Číslo účtu / IBAN
                        </Input>
                        <Input
                            name="info.supplier_bank_code"
                            disabled={!editable}
                            isRequired={bankCodeRequired}
                            isHidden={isCash}
                            highlighted={(modify &&values.changedValues.includes("supplier_bank_code"))}
                        >
                            Kód banky / SWIFT
                        </Input>
                    </>
                )}
            <Input
                name="info.variable_symbol"
                disabled={!editable}
                isRequired={variableSymbolRequired}
                isHidden={isCash}
                highlighted={(modify &&values.changedValues.includes("variable_symbol"))}
            >
                Variabilní symbol
            </Input>
            {!excludedKeys.includes("payment_date") ? <div style={{ height: '0em' }}>&nbsp;</div> :<Input
                name="info.payment_date"
                component={DatePicker}
                disabled={!editable}
                highlighted={(modify &&values.changedValues.includes("payment_date"))}
            >
                Datum úhrady
            </Input>}
        </FieldsWrapper>
    )
};