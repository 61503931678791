import React from 'react';
import {Document, pdfjs} from 'react-pdf';

import styles from 'css_modules/File.module.css';

// Loads PDF worker from CDN to avoid ejecting. Change this after (if) ejecting the app.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const File = ({file, onLoadSuccess, children}) => {
    const url = file ? process.env.REACT_APP_DOMAIN + file : null;

    const placeholderText = <p className={styles.placeholderText}>{file ? 'Soubor není dostupný' : 'Náhled'}</p>;
    return (
        <Document
            file={url}
            onLoadSuccess={onLoadSuccess}
            loading={''}
            noData={placeholderText}
            externalLinkTarget="_blank"
            className={styles.textAnnotation}
        >
            {children}
        </Document>
    )
};