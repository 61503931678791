import React, {useEffect, useState,useRef } from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {useFetch, useModal, useToast} from 'helpers/hooks';
import {ActionButton} from 'components/ActionButton';
import {DocumentsTable} from 'components/DocumentsTable';
import {FileModal} from 'components/FileModal';
import {FilePreview} from 'components/FilePreview';
import {FilterBar} from 'components/FilterBar';
import {FilterButtons} from 'components/FilterButtons';
import {Pagination} from 'components/Pagination';
import {ProgressSpinner} from 'components/ProgressSpinner';
import {RejectModal} from 'components/RejectModal';
import {RemoveModal} from 'components/RemoveModal';
import {ArchiveModal} from 'components/ArchiveModal';
import {TemplateModal} from 'components/TemplateModal';
import {MergeModal} from 'components/MergeModal';
import {RemoveAssignedModal} from 'components/RemoveAssignedModal';
import {Button} from 'components/Button';

import {SearchBar} from 'components/SearchBar';
import styles from 'css_modules/DocumentList.module.css';
import {CaptureButton} from "./CaptureButton";
import {CaptureModal} from "./CaptureModal";
import {TableSettings} from "./TableSettings";
import {request} from "../helpers/tools";
import {DuplicateSModal} from "./DuplicateSModal";
import {ExportTableModal} from "./ExportTableModal";

import Split from '@uiw/react-split';
import { DetailBulkModifyButton } from './DetailBulkModifyButton';
import { BulkRemoveButton } from './BulkRemoveButton';


export const DocumentList = ({page, setPage, activeOnlyFilter, setActiveOnlyFilter, heading, permissions, user, accountingYears, clientCompanies, activities, centres, contracts, docTypes, numSeries, accountCode, vatClassification, vatKvClassification, accounts, countries, currencies, taxRates, paymentTypes, filter, setFilter, limit, setLimit, focusedDocId, setFocusedDocId}) => {
     
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    useEffect(() => {
      const resizeHandler = () => {
        if (windowWidth!==window.innerWidth){ setWindowWidth(window.innerWidth);
            
        }
      };
      
      window.addEventListener('resize', resizeHandler);
  
      return () => {
        window.removeEventListener('resize', resizeHandler);
      };
    }); 

    const divRef = useRef(null);

    const [leftPaneWidth, setLeftPaneWidth] = useState(null);
    
    
    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width } = entry.contentRect;
                const widthInEm = width / 20;

                if (leftPaneWidth!==widthInEm){
                    setLeftPaneWidth(widthInEm)
                    if (widthInEm > 54) {
                       // setButtonsStyle(styles.buttons);        
                    } else {
                       // setButtonsStyle(location.pathname !== '/archive'? styles.buttonsNarrow : styles.buttonsNarrow);        
                    }
                }
            }
        });
    
        const currentDivRef = divRef.current;
    
        if (currentDivRef) {
            observer.observe(currentDivRef);
        }
    
        return () => {
            if (currentDivRef) {
                observer.unobserve(currentDivRef);
            }
        };
    });

    
    
    
    let prevPane = parseFloat(sessionStorage.getItem("prevPane"+user.pk))
    
    if (prevPane!==undefined && !isNaN(prevPane)){  }
    else{   prevPane=80  }

    const showToast = useToast();
    let [ordering, setOrdering] = useState('');
    const [paged, setPaged] = useState(page);
    const [search, setSearch] = useState('');
    const location = useLocation();
    
    const getBackupStateData = () => {
        const storedCheckedDoc = localStorage.getItem('storedCheckedDoc'+location.pathname);
        if (storedCheckedDoc && storedCheckedDoc!=="" && storedCheckedDoc!==undefined  ){
            setFocusedDocId(storedCheckedDoc)
        } 
    };
    getBackupStateData()
        
    const [mainChecked, setMainChecked] = useState(false);
    const [multiDuplicates, setMultiDuplicates] = useState([]);
    //const [refreshInterval] = useState(300000);
    const [refreshInterval] = useState(100000);
    const [checkedDocs, setCheckedDocs] = useState([]);
    const isOrgUnitAdmin = user.role === "OrganizationUnitAdmin";
    const history = useHistory();
    
    const [exportTableModal, toggleTableExportModal] = useModal();
    const closeExportTableModal = () => {
        toggleTableExportModal()
    }
    function handleExport () {
        toggleTableExportModal()
    }
   
    

    const canExport = true

    if (ordering !==''){
        sessionStorage.setItem("ordering", ordering);
    }

    if (ordering === '' &&  sessionStorage.getItem("ordering")!==null){
        ordering =sessionStorage.getItem("ordering")
    }
        
    const mandatoryAccountingYear = () => {
        const myVisibleFields = [...visibleFields];
        
        if (!myVisibleFields.includes('default.accounting_year')){
            myVisibleFields.unshift('default.accounting_year');
        }
        return myVisibleFields
    }
       
    const searchForDataByYear = (title, year, data) => {
        for (let i=0; i<data.length; i++){
            let c = data[i];
            if (String(c.title) === String(title) && String(c.year) === String(year) ){
                return c.id;
            }
        }
    }

    const findDataById=(id, data)=>{
        for (let i=0; i<data.length; i++){
            let c = data[i];
            if (String(c.id) === String(id) ){
                return c;
            }
        }
    }

    const handleResize = (preSize, nextSize, paneNumber) => {
        //console.log(`Drag ended. Previous size: ${preSize}, Next size: ${nextSize}, Pane number: ${paneNumber}`);
        sessionStorage.setItem("prevPane"+user.pk, nextSize);
      };
    

    useEffect(()=>{
        setPage(paged)
    }, [paged, setPage])

    const ormFormat = (str) => {
        let ormFormatedStr = str.replace("document_info", 'info').replace('.', '__');
        if(str === 'note'){
            ormFormatedStr = 'info__note';
        }
        if(ormFormatedStr.startsWith('state')){
            ormFormatedStr = 'state';
        }
        
        return ormFormatedStr;
    }

    let selectedCentre = -1;
    let selectedActivity = -1;
    let selectedContract = -1;
    let selectedNumSeries = -1;
    
    sessionStorage.setItem("currentPage", ordering);
    

    const getFilter = () => {
        //console.log('incoming filter')
        //console.log(filter)
  
        let id = -1;
        let centreFiltered = null;
        let whereCentre = -1;
        let activityFiltered = null;
        let whereActivity = -1;
        let contractFiltered = null;
        let whereContract = -1;
        let numSeriesFiltered = null;
        let whereNumSeries = -1;

        id = -1;
        let filterData = [];
        Object.keys(filter).forEach(key => {
            //console.log('key ' + ormFormat(key) + ' val ' + filter[key] )
            if ( ormFormat(key) === 'accounting_year' && parseInt(filter[key])===-1 ){}
            else{
                if (ormFormat(key)==='document_type' && parseInt(filter[key])){
                    filterData.push(ormFormat(key) + '=rzn')
                    filterData.push("document_user_created_type="+String(filter[key]) )
                }
                else{
                    filterData.push(ormFormat(key) + '=' + filter[key])
                }
                
        
                //console.log("pushing")
                //console.log(ormFormat(key) + '=' + filter[key]);
                id+=1;
                if (ormFormat(key)==='info__centre'){
                    centreFiltered=filter[key];
                    whereCentre=id;
                }
                if (ormFormat(key)==='info__activity'){
                    activityFiltered=filter[key];
                    whereActivity=id;
                }
                if (ormFormat(key)==='info__contract'){
                    contractFiltered=filter[key];
                    whereContract=id;
                }
                if (ormFormat(key)==='info__num_series'){
                    numSeriesFiltered=filter[key];
                    whereNumSeries=id;
                }
            }
        });
        
        function filterAvailableData (selectedData, whereData, dataFiltered, dataName, data) {
            /*Available data filtering*/
            if(whereData!==-1){
                if (parseInt(dataFiltered) == null ){
                    filterData.splice(whereData,1);
                }
                else if ( !filter['accounting_year'] ){
                    filterData.splice(whereData,1);
                }
                else if (dataFiltered !== null){
                    let c = findDataById(dataFiltered,data);
                    if (c===undefined ){
                        filterData.splice(whereData,1);
                    }
                    else{
                        if (String(c.year) === String(filter['accounting_year']) ){
                        //console.log("centre ok " + filter['accounting_year']);
                        selectedData = c.id;
                        }
                        else {
                            let correctCentreId = searchForDataByYear( c.title,  filter['accounting_year'], data);
                            let nc = findDataById(correctCentreId, data);
                            
                            if (nc && nc.id>=0){
                                filterData[whereData]=dataName+'='+nc.id;
                                selectedData = nc.id;
                            }
                            else{
                                filterData.splice(whereData,1);
                            }
                        }
                    }
                }
            }
            return selectedData;
        };
        
        selectedCentre = filterAvailableData(selectedCentre, whereCentre, centreFiltered, 'info__centre', centres);
        selectedActivity = filterAvailableData(selectedActivity, whereActivity, activityFiltered, 'info__activity', activities);
        selectedContract = filterAvailableData(selectedContract, whereContract, contractFiltered, 'info__contract', contracts);
        selectedNumSeries = filterAvailableData(selectedNumSeries, whereNumSeries, numSeriesFiltered, 'info__num_series', numSeries);
        
        //console.log("filter data ");
        //console.log(filterData)
        
        return '&' + filterData.join('&');
    }
    
    
    let prevFilter = sessionStorage.getItem("filter"+location.pathname);
    let currFilter = getFilter();

    if (currFilter!=='&none='){
        sessionStorage.setItem("filter"+location.pathname, getFilter());
    }
    
   
    useEffect(()=>{
        if (currFilter!=='&none=' &&currFilter !== prevFilter){
            setPage(1)
    }
    }, [prevFilter, currFilter,paged, setPage])

    
    const docs = useFetch(
        `${location.pathname}/?search=${search}&active_only=${activeOnlyFilter===undefined?false:activeOnlyFilter}&ordering=${ordering}&page=${page}&limit=${limit}${ currFilter!=='&none='  ? currFilter : (prevFilter ? prevFilter : '&') }`,
        null
    );
    
   
    let docsList = []
    
    if (docs.data){
        for (let i=0; i<docs.data?.results.length; i++){
            let el = docs.data?.results[i]
            docsList.push(el.uuid)
        }
    }

    let loc = 'myDocuments'
    if (location.pathname.includes('/archive') ){
        loc = 'Archive'
    }
    let cstmDocTypes = useFetch('/get_user_type_for_documents/?use_all=true&location='+loc)
    
    if (cstmDocTypes.data && cstmDocTypes.data.length>0) {
        let div = {"name":"------", slug:"",disabled:"true"}
        if (!JSON.stringify(docTypes).includes(JSON.stringify(div))){
            docTypes.splice( docTypes.length-1, 0, div )
        }
                
        cstmDocTypes.data.forEach( e => {
            let t = {}
            t["id"]=e["user_types"][0]["id"]
            t["name"]=e["user_types"][0]["name"]
            t["slug"]=e["user_types"][0]["id"]
            
            if (!JSON.stringify(docTypes).includes(JSON.stringify(t))){
                docTypes.push(t)
            }
        })
    }
    
    
    sessionStorage.setItem("docsList",docsList.join(',') );
    
    const [viewModal, toggleView] = useModal();
    const [rejectModal, toggleReject] = useModal();
    const [removeModal, toggleRemove] = useModal();
    const [archiveModal, toggleArchive] = useModal();
    const [templateModal, toggleTemplate] = useModal();
    const [mergeModal, toggleMerge] = useModal();
    const [unassignModal, toggleUnassign] = useModal();
    const [captureModal, toggleCapture] = useModal();

    const focusedDoc = docs.data?.results?.find(doc => doc.uuid === focusedDocId);
    const visibleState = location.pathname !== '/documents' && user?.role !== 'MaintenanceUser';
    const checkedDocsAsObjects = docs.data?.results.filter((doc) => checkedDocs.includes(doc.uuid));

    const fields = {
        default: {
            name: 'Obecné',
            items: {
                name: {
                    name: 'Název souboru',
                    type: 'string',
                    class: 'textCell',
                    width: '1fr'
                },
                last_modified: {
                    name: 'Změněno',
                    type: 'date',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.edited_by': {
                    name: 'Změnil',
                    type: 'string',
                    class: 'by',
                    width: '1fr'
                },
                added_by: {
                    name: 'Autor',
                    type: 'string',
                    class: 'by',
                    width: '1fr'
                },
                document_type: {
                    name: 'Typ',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                'state.title': {
                    name: 'Stav',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                note: {
                    name: 'Poznámka',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                external_id: {
                    name: 'Číslo v účetním systému',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.currency_title': {
                    name: 'Měna',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                client_company: {
                    name: 'Klientská společnost',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.document_identifier': {
                    name: 'Popis dokladu',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.number': {
                    name: 'Číslo dokladu',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.date': {
                    name: 'Datum',
                    type: 'date',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.taxable_supply_date': {
                    name: 'Datum zdanitelného plnění',
                    type: 'date',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.due_date': {
                    name: 'Datum splatnosti',
                    type: 'date',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.accounting_date': {
                    name: 'Datum účetního případu',
                    type: 'date',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.tax_deduction_date': {
                    name: 'Datum odpočtu',
                    type: 'date',
                    class: 'greyText',
                    width: '1fr'
                },
                accounting_year: {
                    name: 'Účetní rok',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.activity_title': {
                    name: 'Činnost',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.activity_code': {
                    name: 'Kód činnosti',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.centre_title': {
                    name: 'Středisko',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.centre_code': {
                    name: 'Kód střediska',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.contract_title': {
                    name: 'Zakázka',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.contract_number': {
                    name: 'Číslo zakázky',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
            }
        },
        partner: {
            name: 'Partner',
            items: {
                'document_info.supplier_identification_number': {
                    name: 'IČ',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.supplier_vat': {
                    name: 'DIČ',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.supplier_title': {
                    name: 'Název partnera',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.supplier_street': {
                    name: 'Ulice',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.supplier_city': {
                    name: 'Město',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.supplier_zip_code': {
                    name: 'PSČ',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.supplier_country': {
                    name: 'Země',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
            }
        },
        payment: {
            name: 'Platba',
            items: {
                'document_info.payment_type_title': {
                    name: 'Forma úhrady',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.supplier_account_number': {
                    name: 'Číslo účtu',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.supplier_bank_code': {
                    name: 'Kód banky',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.variable_symbol': {
                    name: 'Variabilní symbol',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.payment_date': {
                    name: 'Datum úhrady',
                    type: 'date',
                    class: 'greyText',
                    width: '1fr'
                },
            }
        },
        costs: {
            name: 'Náklady',
            items: {
                'document_info.total': {
                    name: 'Celková částka',
                    type: 'price',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.tax_value': {
                    name: 'DPH',
                    type: 'dph',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.value_original': {
                    name: 'Základ bez DPH',
                    type: 'price',
                    class: 'greyText',
                    width: '1fr'
                },
            }
        },
        accounting: {
            name: 'Účtování',
            items: {
                'document_info.num_series_title': {
                    name: 'Číselná řada',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.account_code_title': {
                    name: 'Předkontace',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.vat_classification_title': {
                    name: 'Členění DPH',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.vat_kv_classification_title': {
                    name: 'Členění KV DPH',
                    type: 'select',
                    class: 'greyText',
                    width: '1fr'
                },
                'document_info.reverse_charge': {
                    name: 'Přenesená daňová povinnost',
                    type: 'bool',
                    class: 'greyText',
                    width: '1fr'
                },
            }
        }
    };

        
    if (location.pathname === '/my_templates') {
        fields.template = {
            name: 'Šablona',
            items: {
                'document_info.template_name': {
                    name: 'Název šablony',
                    type: 'string',
                    class: 'greyText',
                    width: '1fr'
                }
            }
        };
        if( user && user.search_fields && !user.search_fields.includes("template.document_info.template_name")){
            user.search_fields.unshift("template.document_info.template_name");
        }

        delete fields.default.items["document_info.date"]
        delete fields.default.items["document_info.taxable_supply_date"]
        delete fields.default.items["document_info.due_date"]
        delete fields.default.items["document_info.accounting_date"]
        delete fields.default.items["document_info.tax_deduction_date"]
        delete fields.payment.items["document_info.payment_date"]
        
         if( user && user.search_fields){
              user.search_fields = user.search_fields.filter(item => !item.includes("date"));
         }
    }
    else{
        if ( user && user.search_fields && user.search_fields.indexOf("template.document_info.template_name") !== -1) {
            user.search_fields.splice(user.search_fields.indexOf("template.document_info.template_name"), 1);
        }

        
    }
    

    const defaultFields = [
        'default.name',
        'default.last_modified',
        'default.added_by',
        'default.document_type',
        'default.state.title',
        'default.note',
    ];

    
    const repairRelocatedFields = () => {
        for (var i = 0; i < user.search_fields.length; i++) {
            if (user.search_fields[i]==='default.document_info.total'){
                user.search_fields[i]='costs.document_info.total';
            }
            if (user.search_fields[i]==='default.document_info.tax_value'){
                user.search_fields[i]='costs.document_info.tax_value';
            }
            if (user.search_fields[i]==='default.document_info.value_original'){
                user.search_fields[i]='costs.document_info.value_original';
            }
            if (user.search_fields[i]==='accounting.document_info.account_code'){
                user.search_fields[i]='accounting.document_info.account_code_title';
            }
            if (user.search_fields[i]==='default.document_info.accounting_event_date'){
                user.search_fields[i]='default.document_info.accounting_date';
            }
            if (user.search_fields[i]==='accounting.document_info.num_series_title'){
                user.search_fields[i]='accounting.document_info.num_series';
            }
            if (user.search_fields[i]==='accounting.document_info.num_series'){
                user.search_fields[i]='accounting.document_info.num_series_title';
            }
        } 
    }
    if (user.search_fields != null){
        repairRelocatedFields();
    }
    
    
    let [activeFields, setActiveFields] = useState(user.search_fields ? user.search_fields : defaultFields);
    let [visibleFields, setVisibleFields] = useState(user.search_fields ? user.search_fields : defaultFields);
    let filterVisibleFields = mandatoryAccountingYear();
    
    
    if (location.pathname !== '/my_templates' && visibleFields.includes("template.document_info.template_name") && activeFields.includes("template.document_info.template_name"))
    {
        setActiveFields(activeFields.filter(item => item !== "template.document_info.template_name"))
        setVisibleFields(visibleFields.filter(item => item !== "template.document_info.template_name"))
    }
    
    if(location.pathname === '/my_templates' && (!visibleFields.includes("template.document_info.template_name") || !activeFields.includes("template.document_info.template_name"))){
        let intVisFields = JSON.parse(JSON.stringify(visibleFields))
        intVisFields.unshift("template.document_info.template_name")
        setVisibleFields(intVisFields)
        setActiveFields(intVisFields)
    }

    const update = () => {
        docs.fetchData();
        setFocusedDocId(null);
    }
    const fullUpdate = () => {
        docs.fetchData();
        setMainChecked(false);
        setCheckedDocs([]);
    }

    const actionButtonProps = {
        canEdit: user.can_edit,
        forward: location.pathname === '/my_documents',
        data: checkedDocsAsObjects,
        user: user,
        update: () => {
            if (location.pathname === '/my_documents') {
                fullUpdate();
            }
        }
    };

    const saveTableSettings = async () => {
        await request(`/search_fields/upload/`, 'POST', activeFields);
        showToast('Nastavení sloupců bylo uloženo');
        setVisibleFields(activeFields);
        user.search_fields = activeFields;
    }
    
    
    useEffect(() => { // reset states on route change
            const unsubscribe = history.listen((newLocation) => {
                const nextIsList = ['/documents', '/my_documents', '/archive', 'my_templates'].includes(newLocation.pathname);
                if (nextIsList) {
                    if (newLocation.pathname !== location.pathname) {
                        docs.setData(null);
                        setPage(1);
                        setPaged(1);
                    }
                    setMainChecked(false);
                    setCheckedDocs([]);
                    setFocusedDocId(null);
                    setOrdering('');
                    setSearch('');
                    setFilter({'none':''});
                }
            });
            return () => unsubscribe()
        // eslint-disable-next-line
        }, [docs, history, location.pathname, setPage, setPaged]
    );
    

    const fetchMetrics = React.useCallback(() => {
        docs.fetchData();
        setMainChecked(false);
        setCheckedDocs([]);
        // eslint-disable-next-line
    }, [docs]);

    useEffect(() => {
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(fetchMetrics, refreshInterval);
            return () => clearInterval(interval);
        }
    }, [refreshInterval, fetchMetrics]);

    useEffect(() => { // check and focus previously viewed
        if (location.state) {
            setFocusedDocId(location.state.focusedDocId);
        }
        // eslint-disable-next-line
    }, [location.state]);



    const exportXLSX = () => {
        //prepare data
        if  (checkedDocsAsObjects.length===0){
            showToast('Vyberte doklady pro export.')
        }
        else{
            handleExport()
        }
    }

    //let templates = useFetch('/document_template/', []);
   
    

    if (!docs.data) {
        return (
           
            <> 
        <ProgressSpinner/>
        </>
        )
    }

 
    let minWidthLeft =  Math.max(activeFields.length * 5.5, 38)



    if (location.pathname === '/my_templates'){
        return(
            <>
                <div className={styles.wrap}>
                    <header className={styles.topRow}>
                        <h1 className={styles.heading}>{heading}</h1>
                        <SearchBar search={search} setPage={setPage} setSearch={setSearch}/>
                        {
                            location.pathname === '/my_documents' &&
                            <FilterButtons activeOnly={activeOnlyFilter} setActiveOnly={setActiveOnlyFilter} user={user}/>
                        }
                    </header>
                    <FilterBar
                        filter={filter}
                        setFilter={setFilter}
                        docs={docs.data.results}
                        role={user.role}
                        accountingYears={accountingYears}
                        clientCompanies={clientCompanies}
                        activities={activities}
                        centres={centres}
                        contracts={contracts}
                        docTypes={docTypes}
                        numSeries={numSeries}
                        accountCode={accountCode}
                        vatClassification={vatClassification}
                        vatKvClassification={vatKvClassification}
                        accounts={accounts}
                        countries={countries}
                        currencies={currencies}
                        taxRates={taxRates}
                        paymentTypes={paymentTypes}
                        fields={fields}
                        visibleFields={filterVisibleFields}
                        checkedDocs={checkedDocs}
                        setCheckedDocs={setCheckedDocs}
                        focusedDocId={focusedDocId}
                        setFocusedDocId={setFocusedDocId}
                        user={user}
                    
                    />
                    <section className={styles.main}>
                        <div  className={styles.tableContainer}>
                            <div className={styles.topRowButtons}>
                                <div className={styles.leftButtons}>
                                    <TableSettings fields={fields} activeFields={activeFields} setActiveFields={setActiveFields} save={saveTableSettings} limit={limit} setLimit={setLimit} />
                                </div>
                        </div>
                        <DocumentsTable
                            docs={docs.data.results}
                            setOrdering={setOrdering}
                            focusedDocId={focusedDocId}
                            checkedDocs={checkedDocs}
                            setCheckedDocs={setCheckedDocs}
                            setFocusedDocId={setFocusedDocId}
                            mainChecked={mainChecked}
                            visibleState={visibleState}
                            setMainChecked={setMainChecked}
                            toggleReject={toggleReject}
                            toggleRemove={toggleRemove}
                            toggleMerge={toggleMerge}
                            toggleArchive={toggleArchive}
                            toggleTemplate={toggleTemplate}
                            toggleView={toggleView}
                            toggleUnassign={toggleUnassign}
                            permissions={permissions}
                            user={user}
                            toggleCapture={toggleCapture}
                            fields={fields}
                            visibleFields={visibleFields}
                            loading={docs.loading}
                            ordering={ordering}
                            docsCustomTypes={cstmDocTypes}
                        />
                        <div className={styles.footer}>
                            
                            <Pagination user={user} docs={docs.data} setPage={setPaged} limit={limit} setLimit={setLimit}/>
                            
                        </div>
                    </div>
                
                        
                    </section>
                    {rejectModal && <RejectModal doc={focusedDoc} close={toggleReject} update={update}/>}
                    {removeModal && <RemoveModal docs={checkedDocsAsObjects} close={toggleRemove} update={update} user={user.role} hasTemplate={false}/>}
                    {captureModal && <CaptureModal docs={[focusedDoc]} close={toggleCapture} update={update}/>}
                    {archiveModal && <ArchiveModal doc={focusedDoc} close={toggleArchive} update={update}/>}
                    {templateModal && <TemplateModal doc={focusedDoc} close={toggleTemplate} update={update}/>}
                    {mergeModal && <MergeModal doc={focusedDoc} docs={docs}  selectedDocs={checkedDocs} close={toggleMerge} update={update}/>}
                    {viewModal && <FileModal file={focusedDoc?.file_url} close={toggleView}/>}
                    {unassignModal && <RemoveAssignedModal doc={focusedDoc} close={toggleUnassign} update={update}/>}
                    {exportTableModal && <ExportTableModal close={closeExportTableModal} docs={checkedDocsAsObjects} fields={fields} visibleFields={visibleFields}/>}
                    {!!multiDuplicates.length &&
                        <DuplicateSModal update={fullUpdate} duplicates={multiDuplicates} setDuplicates={setMultiDuplicates}
                    />}
                    </div>
                </>
        )
    }
    else if (windowWidth > 1224) {
        return (
            <>
            <div className={styles.wrap}>
                <header className={styles.topRow}>
                    <h1 className={styles.heading}>{heading}</h1>
                    <SearchBar search={search} setPage={setPage} setSearch={setSearch}/>
                    {
                        location.pathname === '/my_documents' &&
                        <FilterButtons activeOnly={activeOnlyFilter} setActiveOnly={setActiveOnlyFilter} user={user}/>
                    }
                </header>
                <FilterBar
                    filter={filter}
                    setFilter={setFilter}
                    docs={docs.data.results}
                    role={user.role}
                    accountingYears={accountingYears}
                    clientCompanies={clientCompanies}
                    activities={activities}
                    centres={centres}
                    contracts={contracts}
                    docTypes={docTypes}
                    numSeries={numSeries}
                    accountCode={accountCode}
                    vatClassification={vatClassification}
                    vatKvClassification={vatKvClassification}
                    accounts={accounts}
                    countries={countries}
                    currencies={currencies}
                    taxRates={taxRates}
                    paymentTypes={paymentTypes}
                    fields={fields}
                    visibleFields={filterVisibleFields}
                    checkedDocs={checkedDocs}
                    setCheckedDocs={setCheckedDocs}
                    focusedDocId={focusedDocId}
                    setFocusedDocId={setFocusedDocId}
                    user={user}
                
                />
                <section className={styles.main}>
                    <Split
                    onDragEnd={handleResize}
                    >
                    <div ref={divRef} style={{ minWidth: `${minWidthLeft}em` ,  width:`${100-prevPane}%`,marginRight:"1em"} }   >
                        <div className={styles.tableContainer}>
                            <div className={styles.topRowButtons}>
                                <div className={styles.leftButtons}>
                                    <TableSettings fields={fields} activeFields={activeFields} setActiveFields={setActiveFields} save={saveTableSettings} limit={limit} setLimit={setLimit} />
                                    <div className={location.pathname !== '/archive'? styles.exportButton : styles.exportButtonArchive}>
                                        {canExport && location.pathname !== '/my_templates'&&  <Button children={"Export"} onClick={exportXLSX}  />    }
                                    </div>
                                    {true && location.pathname === '/my_documents' && <BulkRemoveButton docs={checkedDocsAsObjects} toggleRemove={toggleRemove}/>}
                                </div>
                                <div className={styles.rightButtons}>
                                        <div className={styles.bulkModifyButton}>
                                            {true && location.pathname === '/my_documents' &&
                                                <DetailBulkModifyButton
                                                    docs={checkedDocsAsObjects}
                                                />
                                            }
                                        </div>
                                        <div className={styles.captureButton}>
                                            {user.uses_ocr && location.pathname === '/my_documents' &&
                                                <CaptureButton
                                                    className={styles.captureButton}
                                                    docs={checkedDocsAsObjects}
                                                    update={fullUpdate}
                                                />
                                            }
                                        </div>
                                        <div className={styles.actionButton}>
                                            {location.pathname !== '/archive' && location.pathname !== '/my_templates' && !isOrgUnitAdmin &&
                                                <ActionButton className={styles.actionButton} {...actionButtonProps} duplicates={multiDuplicates} update={fullUpdate} setDuplicates={setMultiDuplicates}/>
                                            }
                                        </div>
                                </div>
                            </div>
                            <DocumentsTable
                                docs={docs.data.results}
                                setOrdering={setOrdering}
                                focusedDocId={focusedDocId}
                                checkedDocs={checkedDocs}
                                setCheckedDocs={setCheckedDocs}
                                setFocusedDocId={setFocusedDocId}
                                mainChecked={mainChecked}
                                visibleState={visibleState}
                                setMainChecked={setMainChecked}
                                toggleReject={toggleReject}
                                toggleRemove={toggleRemove}
                                toggleMerge={toggleMerge}
                                toggleArchive={toggleArchive}
                                toggleTemplate={toggleTemplate}
                                toggleView={toggleView}
                                toggleUnassign={toggleUnassign}
                                permissions={permissions}
                                user={user}
                                toggleCapture={toggleCapture}
                                fields={fields}
                                visibleFields={visibleFields}
                                loading={docs.loading}
                                ordering={ordering}
                                docsCustomTypes={cstmDocTypes}
                            />
                            <div className={styles.footer}>
                                <Pagination user={user} docs={docs.data} setPage={setPaged} limit={limit} setLimit={setLimit}/>
                            </div>
                        </div>
                    </div>
                    <div 
                    style={{ minWidth: "13em", width:`${prevPane}%`,marginLeft:"1em",position: "relative",zIndex:60}}>
                        <div className={styles.sticky}>
                            <FilePreview doc={focusedDoc} refresh={docs.fetchData} toggleView={toggleView}/>
                        </div>
                    </div>
                    </Split>
                    
                </section>
                {rejectModal && <RejectModal doc={focusedDoc} close={toggleReject} update={update}/>}
                {removeModal && <RemoveModal docs={checkedDocsAsObjects} close={toggleRemove} update={update} user={user.role} hasTemplate={false}/>}
                {captureModal && <CaptureModal docs={[focusedDoc]} close={toggleCapture} update={update}/>}
                {archiveModal && <ArchiveModal doc={focusedDoc} close={toggleArchive} update={update}/>}
                {templateModal && <TemplateModal doc={focusedDoc} close={toggleTemplate} update={update}/>}
                {mergeModal && <MergeModal doc={focusedDoc} docs={docs} selectedDocs={checkedDocs} close={toggleMerge} update={update}/>}
                {viewModal && <FileModal file={focusedDoc?.file_url} close={toggleView}/>}
                {unassignModal && <RemoveAssignedModal doc={focusedDoc} close={toggleUnassign} update={update}/>}
                {exportTableModal && <ExportTableModal close={closeExportTableModal} docs={checkedDocsAsObjects} fields={fields} visibleFields={visibleFields}/>}
                {!!multiDuplicates.length &&
                    <DuplicateSModal update={fullUpdate} duplicates={multiDuplicates} setDuplicates={setMultiDuplicates}
                />}
                </div>
            </>
        )
    }

else{
    return(
        <>
            <div className={styles.wrap}>
                <header className={styles.topRow}>
                    <h1 className={styles.heading}>{heading}</h1>
                    <SearchBar search={search} setPage={setPage} setSearch={setSearch}/>
                    {
                        location.pathname === '/my_documents' &&
                        <FilterButtons activeOnly={activeOnlyFilter} setActiveOnly={setActiveOnlyFilter} user={user}/>
                    }
                </header>
                <FilterBar
                    filter={filter}
                    setFilter={setFilter}
                    docs={docs.data.results}
                    role={user.role}
                    accountingYears={accountingYears}
                    clientCompanies={clientCompanies}
                    activities={activities}
                    centres={centres}
                    contracts={contracts}
                    docTypes={docTypes}
                    numSeries={numSeries}
                    accountCode={accountCode}
                    vatClassification={vatClassification}
                    vatKvClassification={vatKvClassification}
                    accounts={accounts}
                    countries={countries}
                    currencies={currencies}
                    taxRates={taxRates}
                    paymentTypes={paymentTypes}
                    fields={fields}
                    visibleFields={filterVisibleFields}
                    checkedDocs={checkedDocs}
                    setCheckedDocs={setCheckedDocs}
                    focusedDocId={focusedDocId}
                    setFocusedDocId={setFocusedDocId}
                    user={user}
                
                />
                <section className={styles.main}>
                        <div  className={styles.tableContainer}>
                        <div className={styles.topRowButtons}>
                                <div className={styles.leftButtons}>
                                    <TableSettings fields={fields} activeFields={activeFields} setActiveFields={setActiveFields} save={saveTableSettings} limit={limit} setLimit={setLimit} />
                                    <div className={location.pathname !== '/archive'? styles.exportButton : styles.exportButtonArchive}>
                                        {canExport && location.pathname !== '/my_templates'&&  <Button children={"Export"} onClick={exportXLSX}  />    }
                                    </div>
                                    {true && location.pathname === '/my_documents' && <BulkRemoveButton docs={checkedDocsAsObjects} toggleRemove={toggleRemove}/>}
                                </div>
                                <div className={styles.rightButtons}>
                                        <div className={styles.bulkModifyButton}>
                                            {true && location.pathname === '/my_documents' &&
                                                <DetailBulkModifyButton
                                                    docs={checkedDocsAsObjects}
                                                />
                                            }
                                        </div>
                                        <div className={styles.captureButton}>
                                            {user.uses_ocr && location.pathname === '/my_documents' &&
                                                <CaptureButton
                                                    className={styles.captureButton}
                                                    docs={checkedDocsAsObjects}
                                                    update={fullUpdate}
                                                />
                                            }
                                        </div>
                                        <div className={styles.actionButton}>
                                            {location.pathname !== '/archive' && location.pathname !== '/my_templates' && !isOrgUnitAdmin &&
                                                <ActionButton className={styles.actionButton} {...actionButtonProps} duplicates={multiDuplicates} update={fullUpdate} setDuplicates={setMultiDuplicates}/>
                                            }
                                        </div>
                                </div>
                            </div>
                            <DocumentsTable
                                docs={docs.data.results}
                                setOrdering={setOrdering}
                                focusedDocId={focusedDocId}
                                checkedDocs={checkedDocs}
                                setCheckedDocs={setCheckedDocs}
                                setFocusedDocId={setFocusedDocId}
                                mainChecked={mainChecked}
                                visibleState={visibleState}
                                setMainChecked={setMainChecked}
                                toggleReject={toggleReject}
                                toggleRemove={toggleRemove}
                                toggleMerge={toggleMerge}
                                toggleArchive={toggleArchive}
                                toggleTemplate={toggleTemplate}
                                toggleView={toggleView}
                                toggleUnassign={toggleUnassign}
                                permissions={permissions}
                                user={user}
                                toggleCapture={toggleCapture}
                                fields={fields}
                                visibleFields={visibleFields}
                                loading={docs.loading}
                                ordering={ordering}
                                docsCustomTypes={cstmDocTypes}
                            />
                            <div className={styles.footer}>
                                <Pagination user={user} docs={docs.data} setPage={setPaged} limit={limit} setLimit={setLimit}/>
                            </div>
                        </div>
                    
                
                    <div className={styles.sticky}>
                        <FilePreview doc={focusedDoc} refresh={docs.fetchData} toggleView={toggleView}/>
                    </div>
                    
                </section>
                {rejectModal && <RejectModal doc={focusedDoc} close={toggleReject} update={update}/>}
                {removeModal && <RemoveModal docs={checkedDocsAsObjects} close={toggleRemove} update={update} user={user.role} hasTemplate={false}/>}
                {captureModal && <CaptureModal docs={[focusedDoc]} close={toggleCapture} update={update}/>}
                {archiveModal && <ArchiveModal doc={focusedDoc} close={toggleArchive} update={update}/>}
                {templateModal && <TemplateModal doc={focusedDoc} close={toggleTemplate} update={update}/>}
                {mergeModal && <MergeModal doc={focusedDoc} docs={docs} selectedDocs={checkedDocs} close={toggleMerge} update={update}/>}
                {viewModal && <FileModal file={focusedDoc?.file_url} close={toggleView}/>}
                {unassignModal && <RemoveAssignedModal doc={focusedDoc} close={toggleUnassign} update={update}/>}
                {exportTableModal && <ExportTableModal close={closeExportTableModal} docs={checkedDocsAsObjects} fields={fields} visibleFields={visibleFields}/>}
                {!!multiDuplicates.length &&
                    <DuplicateSModal update={fullUpdate} duplicates={multiDuplicates} setDuplicates={setMultiDuplicates}
                />}
                </div>
            </>


    )
}
}