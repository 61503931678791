import React from 'react';

import {formatDate} from 'helpers/tools';
import styles from 'css_modules/DuplicateTableRow.module.css';

export const DuplicateTableRow = ({duplicate}) => {
    const url = `/${duplicate.location}/${duplicate.uuid}/metadata`
    const lastModified = `${formatDate(duplicate.last_modified_at) || '---'} (${duplicate.last_modified_by ?? '---'})`

    return (
        <tr>
            <td className={styles.uuid}>
                {duplicate.location
                    ? (
                        <a href={url} target="_blank" rel="noreferrer">{duplicate.uuid}</a>
                    ) : <span>{duplicate.uuid}</span>
                }
            </td>
            <td className={styles.padding}>
                {duplicate.state}
            </td>
            <td>
                {lastModified}
            </td>
        </tr>
    )
};