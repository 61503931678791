import React, {useState, useRef} from 'react';
import {Page} from 'react-pdf';

import {File} from 'components/File';
import {Modal} from 'components/Modal';
import styles from 'css_modules/FileModal.module.css';

import {ReactComponent as ClockwiseIcon} from 'assets/svg/clockwise_arrow.svg';
import {ReactComponent as CounterClockwiseIcon} from 'assets/svg/counterclockwise_arrow.svg';
import {ReactComponent as DownloadIcon} from 'assets/svg/arrow_down.svg';
import {ReactComponent as PrintIcon} from 'assets/svg/printer.svg';
import printJS from "print-js";
import {ReactComponent as PlusIcon} from "../assets/svg/plus.svg";
import {ReactComponent as MinusIcon} from "../assets/svg/minus.svg";


export const FileModal = ({close, docName, file}) => {
    const [numPages, setNumPages] = useState(null);
    const [rotation, setRotation] = useState(0);
    const rotateStyles = styles.rotateIcon;

    const [scale, setScale] = useState(1);

    const onLoadSuccess = ({numPages}) => setNumPages(numPages);

    const zoomInEnabled = numPages && scale <= 2;
    const zoomOutEnabled = scale > 1;
    const zoomInClassName = zoomInEnabled ? styles.zoomIconActive : styles.zoomIconInactive;
    const zoomOutClassName = zoomOutEnabled ? styles.zoomIconActive : styles.zoomIconInactive;
    const zoomIn = () => zoomInEnabled && setScale(scale + .125);
    const zoomOut = () => zoomOutEnabled && setScale(scale - .125);

    const rotateClockwise = () => setRotation( prev=> (prev+90) % 360);
    const rotateCounterClockwise = () => setRotation( prev=> (prev-90) % 360);

    const print = () => {
        if (!file){ return;}
        printJS({printable: file, type: 'pdf', properties:[]});
    }

    function downloadByUrl(url, filename) {
        fetch(url).then(function (t) {
            return t.blob().then((b) => {
                    let a = document.createElement("a");
                    a.href = URL.createObjectURL(b);
                    a.setAttribute("download", filename);
                    a.click();
                    a.remove();
                }
            );
        });
    }

    const download = () => {
        if (!file){ return;}
        downloadByUrl(file, docName);
    }

    const containerRef = useRef(null);
    console.log("sdad")
    return (
        <div className={styles.onTop} >
        <Modal large closeOnClickOutside name={docName} close={close}>
            <div ref={containerRef} className={styles.icons}>
                <PlusIcon title="Přiblížit" onClick={zoomIn} className={zoomInClassName}/>
                <MinusIcon title="Oddálit" onClick={zoomOut} className={zoomOutClassName}/>


                <CounterClockwiseIcon title="Otáčet proti směru hodinových ručiček" onClick={rotateCounterClockwise} className={rotateStyles}/>
                <ClockwiseIcon title="Otáčet po směru hodinových ručiček" onClick={rotateClockwise} className={rotateStyles}/>

                <DownloadIcon title="Stáhnout" onClick={download} className={rotateStyles}/>
                <PrintIcon title="Tisknout" onClick={print} className={rotateStyles}/>
            </div>
            <div className={styles.pdfContainer}>
                <File file={file} onLoadSuccess={onLoadSuccess}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page scale={scale} width={containerRef.current?.clientWidth * 0.97} rotate={rotation} loading={''} key={index} pageNumber={index + 1}/>
                    ))}
                </File>
            </div>
        </Modal>
       </div>
    )
};