import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style'

export const request = (url, method, body, publicRoute, fileUpload) => {
    const headers = {};

    if (!publicRoute) {
        headers['Authorization'] = `JWT ${localStorage.getItem('DocspointJWT')}`
    }
    if (!fileUpload) {
        headers['Content-Type'] = 'application/json';
        body = body ? JSON.stringify(body) : undefined
    }

    const fetchData = async () => {
        const response = await fetch(process.env.REACT_APP_API + url, {
            method: method || 'GET',
            headers: headers,
            body: body
        });
        const text = await response.text();
        return {
            ok: response.ok,
            status: response.status,
            body: text.length ? JSON.parse(text) : null
        }
    };
    return fetchData()
};

export const stringifyNullValues = obj =>
    JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? '' : v)));

export const nullifyEmptyValues = obj =>
    JSON.parse(JSON.stringify(obj, (k, v) => (v === '' ? null : v)));

export const formatDate = date =>
    date ? `${date.slice(8, 10)}/${date.slice(5, 7)}/${date.slice(2, 4)}${date.slice(11.16)? ',' : ''} ${date.slice(11, 16)}` : null;

export const formatPrice = price =>
    price ? `${price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}` : null;

export const preProcessCosts = (costs) => {
    let processed_costs = [];
    costs.forEach((c)=>{
        if(c.value_original !== '' && c.value_original) { // filters unfilled costs
            processed_costs.push(c);
        }
    })
    return processed_costs;
}


export const preProcessItems = (values) => {
    let processed_items = [];
    
    values.items?.forEach((i)=>{
        if (!i["centre"]){i["centre"] = values.info["centre"]}
        if (!i["vat_classification"]){i["vat_classification"] = values.info["vat_classification"]}
        if (!i["vat_kv_classification"]){i["vat_kv_classification"] = values.info["vat_kv_classification"]}
        if (!i["reverse_charge"]){i["reverse_charge"] = values.info["reverse_charge"]}
        if (!i["contract"]){i["contract"] = values.info["contract"]}
        if (!i["activity"]){i["activity"] = values.info["activity"]}
        if (!i["account_code"]){i["account_code"] = values.info["account_code"]}
        if (!i["expiration_date"]){i["expiration_date"] = null}

        if (!i["cost"] || !i["cost"]===''){
            i["id"] = undefined
        }

        if(i.text !== '' && (i.price_vat!=='' && i.price_original!=='')) { // filters unfilled items
            processed_items.push(i);
        }
        else if(i.text === '' && (i.price_vat!=='' && i.price_original!=='')) { // filters unfilled items
            i.text = 'Položka '+ (processed_items.length+1);
            processed_items.push(i);
        }
        else if (i.text !== '' && (i.price_vat==='' && i.price_original==='')) { // filters unfilled items
            i.price_vat = 0
            i.price_original = 0
            processed_items.push(i);
        }
        
        //processed_items.push(i);
    })
    return processed_items;
}

export const generateXLSX = (data) => {
    // generate XLSX from JSON
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const ws = XLSX.utils.json_to_sheet(data)
    const wb= {Sheets: {'data':ws}, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb,{bookType:'xlsx', type:'array'})
    const sheetData = new Blob ([excelBuffer] , {type:fileType})
    FileSaver.saveAs(sheetData, 'docspoint_export.xlsx')

}


export const generateCSV = (data) => {
    // generate CSV from JSON
    var json = data
    var fields = Object.keys(json[0])
    var replacer = function(key, value) { return value === null ? '' : value } 
    var csv = json.map(function(row){
    return fields.map(function(fieldName){
        return JSON.stringify(row[fieldName], replacer)
    }).join(',')
    })
    csv.unshift(fields.join(','))
    csv = csv.join('\r\n');
    
    //csv = csv.replace(/(['"])/g, '')

    let filename = 'docspoint_export.csv'
    
    var BOM = "\uFEFF";
    var    csvdata = BOM + csv;
    var blob = new Blob([csvdata], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    
    link.click();
    
    
}