
import {formatPrice} from 'helpers/tools';
import styles from 'css_modules/SummaryRow.module.css';

export const SummaryRow = (
    {
        docs, visibleFields, visibleCheckbox
    }
) => {
    let sumTotal = 0;
    let sumTax = 0;
    let sumOriginal = 0;
    //#const computeSums = visibleFields.indexOf("document_info.total") >0 ?

    const totalColIdx = visibleFields.indexOf("costs.document_info.total") ;
    const taxColIdx = visibleFields.indexOf("costs.document_info.tax_value") ;
    const origColIdx = visibleFields.indexOf("costs.document_info.value_original") ;


    for (const doc of docs){
        let total = parseFloat(doc["document_info"]["total"]) ;
        if (!isNaN(total)){
            sumTotal += total;
        }

        let tax = parseFloat(doc["document_info"]["tax_value"]);
        if (!isNaN(tax)){
            sumTax += tax;
        }

        let original = parseFloat(doc["document_info"]["value_original"]);
        if (!isNaN(original)){
            sumOriginal += original;
        }
    }

    sumTotal = Number((sumTotal).toFixed(2));
    sumTax = Number((sumTax).toFixed(2));
    sumOriginal = Number((sumOriginal).toFixed(2));
    
    const prepareColumn=() => {
        let columns = [];
        
        columns.push(<td key={"sum"} className={styles.firstCell}>Celkem</td>);

        visibleFields.forEach((item) => {
            let keys = item.split(/\./);
            let key = keys[keys.length-1]
                        
            if (key === "total"){
                columns.push(<td key={key} className={styles.textCell} >{formatPrice(sumTotal)}</td>);
            }
            else if (key === "tax_value"){
                columns.push(<td key={key} className={styles.textCell} >{formatPrice(sumTax)}</td>);
            }
            else if (key === "value_original"){
                columns.push(<td key={key} className={styles.textCell} >{formatPrice(sumOriginal)}</td>);
            }
            else if (visibleFields.indexOf(item) > 0 || visibleCheckbox){
                columns.push(<td key={key}></td>);
            }
        });
        
        columns.push(<td key={"fill"}></td>);
        return columns;
    }

    if (totalColIdx >= 0 || taxColIdx>=0 || origColIdx>=0){
        return (
            <tr>
                   {prepareColumn()}
            </tr>
        )

    }
    else{
        return null;
    }
    
    
};