import React from 'react';


import {Button} from 'components/Button';
import {Modal} from 'components/Modal';

import styles from 'css_modules/SaveModal.module.css';

export const SaveModal = ({close,clickedSave, clickedClose, diff}) => {
    let dict = {}
    dict["client_company"]="Klientská společnost"
    dict["docType"]="Typ dokladu"
    dict["document_type"]="Typ dokladu"
    dict["centre"]="Středisko"
    dict["activity"]="Činnost"
    dict["currency"]="Měna"
    dict["contract"]="Zakázka"
    dict["note"]="Poznámka"
    dict["costs"]="Náklady"
    dict["items"]="Položky"
    dict["document_identifier"]="Popis dokladu"
    dict["number"]="Číslo dokladu"
    dict["supplier_title"]="Název partnera"
    dict["supplier_identification_number"]="IČ partnera"
    dict["taxable_supply_date"]="Datum zdanitelného plnění"
    dict["due_date"]="Datum splatnosti"
    dict["accounting_date"]="Datum účetního případu"
    dict["tax_deduction_date"]="Datum odečtu"
    dict["variable_symbol"]="Variabilní symbol"
    dict["supplier_account_number"]="Číslo účtu / IBAN"
    dict["supplier_bank_code"]="Kód banky / SWIFT"
    dict["supplier_vat"]="DIČ partnera"
    dict["supplier_street"]="Ulice"
    dict["supplier_city"]="Město"
    dict["date"]="Datum"
    dict["supplier_country"]="Země"
    dict["supplier_zip_code"]="PSČ"
    dict["payment_date"]="Datum úhrady"
    dict["payment_type"]="Forma úhrady"
    dict["num_series"]="Číselná řada"
    dict["unknown_rate"]="Daňová sazba"
    dict["account_code"]="Číselná řada"
    dict["vat_classification"]="Členění DPH"
    dict["vat_kv_classification"]="Členění KV DPH"
    dict["reverse_charge"]="Přenesená daňová povinnost"
    dict["supplier_sk_ic_dph"]="SK IČ DPH"
    dict["rounding"]="Zaokrouhlení"

    let diff_cz = []
    
    diff.forEach (d => {
        if (dict[d]){ diff_cz.push(dict[d])}
        else{diff_cz.push(d)}
    })

    
        return (

            <div className={styles.ontop}>
                <Modal name={'Dokument byl změněn'} close={close}>
                <div className={styles.column}>
                <h4>Změněno:</h4>    
                {diff_cz.map((element) => (
                    <p key={element}>{element}</p>
                ))}
                </div>

                <div className = {styles.buttons} >
                

                <div className={styles.button}>      
                    <Button onClick={clickedClose} >Zahodit změny</Button>  
                    </div>
                    
                    <div className={styles.savebutton}>      
                    <Button onClick={clickedSave} primary={true} >Uložit</Button>  
                    </div>
                        
                
                </div>
                </Modal>
            </div>
        )
    
    
}; 