export const FilePopup = ({ pdfUrl }) => {
  const openPdfInPopup = () => {
    if (!pdfUrl) {
      alert('No PDF URL provided.');
      return;
    }

    const popupWidth = 800;  
    const popupHeight = 1000; 
    
    window.open(
      pdfUrl,
      '_blank',
      `width=${popupWidth},height=${popupHeight},noopener,noreferrer`
    );

  };
  openPdfInPopup()
};


