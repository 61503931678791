import Dropzone from "react-dropzone-uploader";

class DropzoneCustom extends Dropzone {
    handleFiles = async (files) => {
        await files.forEach( (f, i) => {
            if (f.name.includes('.pdf') 
            || f.name.includes('.PDF')
            || f.name.includes('.jpg')
            || f.name.includes('.jpeg')
            || f.name.includes('.png')
            || f.name.includes('.bmp')
            || f.name.includes('.gif')
            || f.name.includes('.tif')
            || f.name.includes('.tiff')
            || f.name.includes('.JPG')
            || f.name.includes('.PNG')
            || f.name.includes('.BMP')
            || f.name.includes('.TIF')
            || f.name.includes('.GIF')
            || f.name.includes('.TIFF')
            || f.name.includes('.JPEG')
        ) {
                 this.handleFile(f, `${new Date().getTime()}-${i}`);
            }
        })
        files.forEach( (f, i) => {
            if (f.name.includes('.isdoc')) {
                 this.handleFile(f, `${new Date().getTime()}-${i}`);
            }
        })
        const { current } = this.dropzone
        if (current) setTimeout(() => current.scroll({ top: current.scrollHeight, behavior: 'smooth' }), 150)
    }

    handleFile = async (file, id) => {
        const { name, size, type, lastModified } = file
        const { minSizeBytes, maxSizeBytes, maxFiles, getUploadParams, autoUpload, validate } = this.props

        const uploadedDate = new Date().toISOString()
        const lastModifiedDate = lastModified && new Date(lastModified).toISOString()
        const fileWithMeta = {
            file,
            meta: { name, size, type, lastModifiedDate, uploadedDate, percent: 0, id },
        }
        if (this.files.length >= maxFiles) {
            fileWithMeta.meta.status = 'rejected_max_files'
            this.handleChangeStatus(fileWithMeta)
            return
        }

        fileWithMeta.cancel = () => this.handleCancel(fileWithMeta)
        fileWithMeta.remove = () => this.handleRemove(fileWithMeta)
        fileWithMeta.restart = () => this.handleRestart(fileWithMeta)

        fileWithMeta.meta.status = 'preparing'
        this.files.push(fileWithMeta)
        this.handleChangeStatus(fileWithMeta)
        this.forceUpdate()

        if (size < minSizeBytes || size > maxSizeBytes) {
            fileWithMeta.meta.status = 'error_file_size'
            this.handleChangeStatus(fileWithMeta)
            this.forceUpdate()
            return
        }

        await this.generatePreview(fileWithMeta)

        if (validate) {
            const error = validate(fileWithMeta)
            if (error) {
                fileWithMeta.meta.status = 'error_validation'
                fileWithMeta.meta.validationError = error // usually a string, but doesn't have to be
                this.handleChangeStatus(fileWithMeta)
                this.forceUpdate()
                return
            }
        }

        if (getUploadParams) {
            if (autoUpload) {
              await this.uploadFile(fileWithMeta)
                fileWithMeta.meta.status = 'done'
            } else {
                fileWithMeta.meta.status = 'ready'
            }
        } else {
             fileWithMeta.meta.status = 'getting_upload_params'
        }
        this.handleChangeStatus(fileWithMeta)
        this.forceUpdate()
    }
    uploadFile = async (fileWithMeta) => {
        const { getUploadParams } = this.props
        if (!getUploadParams) return
        let params = null
        try {
            params = await getUploadParams(fileWithMeta)
        } catch (e) {
            console.error('Error Upload Params', e.stack)
        }
        if (params === null) return
        const { url, method = 'POST', body, fields = {}, headers = {}, meta: extraMeta = {} } = params
        delete extraMeta.status

        if (!url) {
            fileWithMeta.meta.status = 'error_upload_params'
            this.handleChangeStatus(fileWithMeta)
            this.forceUpdate()
            return
        }

        const xhr = new XMLHttpRequest()
        const formData = new FormData()
        xhr.open(method, url, false)

        for (const field of Object.keys(fields)) formData.append(field, fields[field])
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        for (const header of Object.keys(headers)) xhr.setRequestHeader(header, headers[header])
        fileWithMeta.meta = { ...fileWithMeta.meta, ...extraMeta }

        // update progress (can be used to show progress indicator)
        xhr.upload.addEventListener('progress', e => {
            fileWithMeta.meta.percent = (e.loaded * 100.0) / e.total || 100
            this.forceUpdate()
        })

        xhr.addEventListener('readystatechange', () => {
            // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
            if (xhr.readyState !== 2 && xhr.readyState !== 4) return

            if (xhr.status === 0 && fileWithMeta.meta.status !== 'aborted') {
                fileWithMeta.meta.status = 'exception_upload'
                this.handleChangeStatus(fileWithMeta)
                this.forceUpdate()
            }

            if (xhr.status > 0 && xhr.status < 400) {
                fileWithMeta.meta.percent = 100
                if (xhr.readyState === 2) fileWithMeta.meta.status = 'headers_received'
                if (xhr.readyState === 4) fileWithMeta.meta.status = 'getting_upload_params'
                this.handleChangeStatus(fileWithMeta)
                this.forceUpdate()
            }

            if (xhr.status >= 400 && fileWithMeta.meta.status !== 'error_upload') {
                fileWithMeta.meta.status = 'error_upload'
                this.handleChangeStatus(fileWithMeta)
                this.forceUpdate()
            }
        })

        formData.append('file', fileWithMeta.file)
        if (this.props.timeout) xhr.timeout = this.props.timeout
        xhr.send(body || formData)
        fileWithMeta.xhr = xhr
        fileWithMeta.meta.status = 'uploading'
        this.handleChangeStatus(fileWithMeta)
        this.forceUpdate()
    }
}

export default DropzoneCustom;