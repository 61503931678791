import React from 'react';

import styles from 'css_modules/Button.module.css';

export const Button = ({className, disabled, onClick, primary, type, children}) => {

    let buttonClassName = `${className} ${styles[primary ? 'primary' : 'secondary']}`;
    if (className === 'wideprimary'){
        buttonClassName = styles['wideprimary']
    }
    const buttonType = type ? type : 'button';



    return (
        <button type={buttonType} disabled={disabled} onClick={onClick} className={buttonClassName}>{children}</button>
    )
};

