import React, {useState} from 'react';

import styles from 'css_modules/Toast.module.css';

export const ToastContext = React.createContext(null);

export const Toast = ({children}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [text, setText] = useState('');
    const [list, setList] = useState([]);

    const containerClassName = isVisible ? styles.visible : styles.hidden;

    const showToast = (text, list) => {
        setText(text);
        setList(list ?? []);
        setIsVisible(true);
        setTimeout(() => setIsVisible(false), 3000)
    };

    const renderItems = (item) => <li key={item}>{item}</li>

    return (
        <ToastContext.Provider value={showToast}>
            <div className={containerClassName}>
                <p>{text}</p>
                {!!list.length && (
                    <ul className={styles.list}>
                        {list.map(renderItems)}
                    </ul>
                )}
            </div>
            {children}
        </ToastContext.Provider>
    )
};