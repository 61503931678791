import React from 'react';

import {useModal, useToast} from 'helpers/hooks';
import {Button} from 'components/Button';
import {CaptureModal} from './CaptureModal';
import {useLocation} from 'react-router-dom';

export const CaptureButton = ({className, docs, update, saveFunction}) => {
    const [captureModal, toggleCapture] = useModal();
    const showToast = useToast();
    const location = useLocation();

    const handleClick = () => {
        if ( ["general","supplier","payment","costs","accounting","history","metadata"].some(keyword => location.pathname.includes(keyword))){
            sessionStorage.setItem('reloadaftersave','false')
            sessionStorage.setItem("forceMove","0")
            saveFunction()
            sessionStorage.setItem("forceMove","")
            sessionStorage.setItem('reloadaftersave','true')
        }
         
        if (!docs.length) {
            return showToast('Nebyl označen žádný doklad');
        } else if (docs.some(doc => doc.awaiting_capture)) {
            return showToast('Některé doklady čekají na vytěžení');
        } else if (docs.some(doc => doc.captured)) {
            return showToast('Některé doklady jsou již vytěženy');
        } else if (docs.some(doc => !doc.file_url)) {
            return showToast('Doklady bez souboru nelze vytěžit')
        }
        toggleCapture();
    }

    return (
        <>
            <Button className={className} onClick={handleClick}>Vytěžit</Button>
            {captureModal && <CaptureModal docs={docs} close={toggleCapture} update={update}/>}
        </>
    );
};

