import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import {useFetch} from 'helpers/hooks';
import {DetailWrapper} from 'components/DetailWrapper';
import {DocumentList} from 'components/DocumentList';
import {Footer} from 'components/Footer';
import {Nav} from 'components/Nav';
import {Profile} from 'components/Profile';
import {ProgressSpinner} from 'components/ProgressSpinner';
import {Toast} from 'components/Toast';
import {Upload} from 'components/Upload';
import styles from 'css_modules/Wrapper.module.css';

export const Wrapper = () => {
    const user = useFetch('/rest-auth/user/', '');
    const clientCompanies = useFetch('/client_companies/', []);
    const accountingYears = useFetch('/accounting_years/', []);
    const activities = useFetch('/activities/', []);
    const centres = useFetch('/centres/', []);
    const contracts = useFetch('/contracts/', []);
    const docTypes = useFetch('/document_types/', []);
    const numSeries = useFetch('/num_series/', []);
    const accountCode = useFetch('/account_code/', []);
    const vatClassification = useFetch('/vat_classification/', []);
    const vatKvClassification = useFetch('/vat_kv_classification/', []);
    const accounts = useFetch('/accounts/', []);
    const countries = useFetch('/countries/', []);
    const currencies = useFetch('/currencies/', []);
    const taxRates = useFetch('/tax_rates/', []);
    const paymentTypes = useFetch('/payment_types/', []);
    const [activeOnlyFilter, setActiveOnlyFilter] = useState(false);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({'none':''});
    const [limit, setLimit] = useState(20);
    const [focusedDocId, setFocusedDocId] = useState(null);

    
    if (( parseInt((localStorage.getItem("limit"+user.data.pk)))>0)){
        let l = parseInt((localStorage.getItem("limit"+user.data.pk)))
        if (limit !== l){
            setLimit(l)
        }
        
    }
    
    if ((localStorage.getItem("activeOnly"+user.data.pk))!==undefined){
        let b = undefined
        if (localStorage.getItem("activeOnly"+user.data.pk)==="false"){b = false }
        if (localStorage.getItem("activeOnly"+user.data.pk)==="true"){b = true }
        
        if (b!==undefined && activeOnlyFilter !== b){
            try{
                setActiveOnlyFilter(b)
            }catch (error) { }
        }
    }
    
    
    //Appending user created custom document types
    
    let allDocTypes = []
    docTypes.data.forEach(e => {
        allDocTypes.push(e)
    })

    allDocTypes.push(allDocTypes.splice(allDocTypes.findIndex(c => c["slug"] === "rzn"), 1)[0])
    //allDocTypes.splice( allDocTypes.length-1, 0,  {"name":"--- Různé ---"})

    /*
    const customDocTypes = useFetch('/document_user_created_custom_types/', []);
    customDocTypes.data.forEach( e => {
        e["slug"]=e["id"]
        allDocTypes.push(e)
    })
    */
    const permissions = user.data ? {
        accessDocuments: user.data.role !== 'ClientUser',
        accessMyDocuments: user.data.role !== 'OrganizationUnitAdmin',
        accessArchive: true, //user.data.role !== 'MaintenanceUser',
        edit: user.data.can_edit,
        reject: (['ManagementUser', 'AccountantUser'].includes(user.data.role) || (['MaintenanceUser'].includes(user.data.role) && user.data.can_edit===true))===true,
        remove: user.data.can_remove,
        upload: ['ClientUser', 'AccountantUser', 'ManagementUser','MaintenanceUser'].includes(user.data.role) && user.data.can_edit
    } : {};
    

    
    if (user.loading) { // load user first so that we can use permissions for eventual redirects
        return <ProgressSpinner/>
    }
    else{
    //      console.log('NAV USER')
    //      console.log(user)
    }
    
    const userChange = () =>{
        user.fetchData()
        window.location.reload();
    }

   
    return (
        <Router>
            <Toast>
                <div className={styles.container}>
                    {user.data && <Nav permissions={permissions} user={user.data} fetchUser={userChange}/>}
                    <div className={styles.content}>
                        <main className={styles.main}>
                            <Switch>
                                <Route exact path="/upload">
                                    {permissions.upload ? <Upload/> :
                                        <Redirect to="/my_documents"/>}
                                </Route>
                                <Route exact path="/documents">
                                    {
                                        permissions.accessDocuments ?
                                            (
                                                <DocumentList
                                                    heading="Doklady"
                                                    permissions={permissions}
                                                    user={user.data}
                                                    accountingYears={accountingYears.data}
                                                    clientCompanies={clientCompanies.data}
                                                    activities={activities.data}
                                                    centres={centres.data}
                                                    contracts={contracts.data}
                                                    docTypes={allDocTypes}
                                                    numSeries={numSeries.data}
                                                    accountCode={accountCode.data}
                                                    vatClassification={vatClassification.data}
                                                    vatKvClassification={vatKvClassification.data}
                                                    accounts={accounts.data}
                                                    countries={countries.data}
                                                    currencies={currencies.data}
                                                    taxRates={taxRates.data}
                                                    paymentTypes={paymentTypes.data}
                                                    page={page}
                                                    setPage={setPage}
                                                    filter={filter}
                                                    setFilter={setFilter}
                                                    limit={limit}
                                                    setLimit={setLimit}
                                                    focusedDocId={focusedDocId}
                                                    setFocusedDocId={setFocusedDocId}
                                                />
                                            ) : <Redirect to="/my_documents"/>
                                    }
                                </Route>
                                <Route exact path="/my_documents">
                                    {
                                        permissions.accessMyDocuments ?
                                            (
                                                <DocumentList
                                                    heading="Moje doklady"
                                                    permissions={permissions}
                                                    user={user.data}
                                                    accountingYears={accountingYears.data}
                                                    clientCompanies={clientCompanies.data}
                                                    activities={activities.data}
                                                    centres={centres.data}
                                                    contracts={contracts.data}
                                                    docTypes={allDocTypes}
                                                    numSeries={numSeries.data}
                                                    accountCode={accountCode.data}
                                                    vatClassification={vatClassification.data}
                                                    vatKvClassification={vatKvClassification.data}
                                                    accounts={accounts.data}
                                                    countries={countries.data}
                                                    currencies={currencies.data}
                                                    taxRates={taxRates.data}
                                                    paymentTypes={paymentTypes.data}
                                                    activeOnlyFilter={activeOnlyFilter}
                                                    setActiveOnlyFilter={setActiveOnlyFilter}
                                                    page={page}
                                                    setPage={setPage}
                                                    filter={filter}
                                                    setFilter={setFilter}
                                                    limit={limit}
                                                    setLimit={setLimit}
                                                    focusedDocId={focusedDocId}
                                                    setFocusedDocId={setFocusedDocId}
                                                />
                                            ) : <Redirect to="/documents"/>
                                    }

                                </Route>
                                <Route exact path="/archive">
                                    {
                                        permissions.accessArchive ?
                                            <DocumentList
                                                heading="Archiv"
                                                permissions={permissions}
                                                user={user.data}
                                                accountingYears={accountingYears.data}
                                                clientCompanies={clientCompanies.data}
                                                activities={activities.data}
                                                centres={centres.data}
                                                contracts={contracts.data}
                                                docTypes={allDocTypes}
                                                numSeries={numSeries.data}
                                                accountCode={accountCode.data}
                                                vatClassification={vatClassification.data}
                                                vatKvClassification={vatKvClassification.data}
                                                accounts={accounts.data}
                                                countries={countries.data}
                                                currencies={currencies.data}
                                                taxRates={taxRates.data}
                                                paymentTypes={paymentTypes.data}
                                                page={page}
                                                setPage={setPage}
                                                filter={filter}
                                                setFilter={setFilter}
                                                limit={limit}
                                                setLimit={setLimit}
                                                focusedDocId={focusedDocId}
                                                setFocusedDocId={setFocusedDocId}
                                            /> :
                                            <Redirect to="/my_documents"/>
                                    }
                                </Route>
                                <Route exact path="/my_templates">
                                    {
                                        (permissions.accessMyDocuments || user.data.role === 'OrganizationUnitAdmin' )?
                                        (
                                            <DocumentList
                                            heading="Šablony"
                                            permissions={permissions}
                                            user={user.data}
                                            accountingYears={accountingYears.data}
                                            clientCompanies={clientCompanies.data}
                                            activities={activities.data}
                                            centres={centres.data}
                                            contracts={contracts.data}
                                            docTypes={allDocTypes}
                                            numSeries={numSeries.data}
                                            accountCode={accountCode.data}
                                            vatClassification={vatClassification.data}
                                            vatKvClassification={vatKvClassification.data}
                                            accounts={accounts.data}
                                            countries={countries.data}
                                            currencies={currencies.data}
                                            taxRates={taxRates.data}
                                            paymentTypes={paymentTypes.data}
                                            page={page}
                                            setPage={setPage}
                                            filter={filter}
                                            setFilter={setFilter}
                                            limit={limit}
                                            setLimit={setLimit}
                                            focusedDocId={focusedDocId}
                                            setFocusedDocId={setFocusedDocId}
                                        />) :
                                        <Redirect to="/documents"/>
                                        
                                    }

                                </Route>

                                <Route exact path="/profile">
                                    <Profile user={user.data} clientCompanies={clientCompanies.data} setUser={user.setData}/>
                                </Route>
                                <Route path="/:table/:uuid">
                                    <DetailWrapper
                                        permissions={permissions}
                                        user={user.data}
                                        clientCompanies={clientCompanies.data}
                                        accountingYears={accountingYears.data}
                                        activities={activities.data}
                                        centres={centres.data}
                                        contracts={contracts.data}
                                        docTypes={allDocTypes}
                                        numSeries={numSeries.data}
                                        accountCode={accountCode.data}
                                        vatClassification={vatClassification.data}
                                        vatKvClassification={vatKvClassification.data}
                                        accounts={accounts.data}
                                        countries={countries.data}
                                        currencies={currencies.data}
                                        taxRates={taxRates.data}
                                        paymentTypes={paymentTypes.data}
                                        focusedDocId={focusedDocId}
                                        page={page}
                                        setFocusedDocId={setFocusedDocId}
                                    />
                                </Route>
                                <Route>
                                    <Upload/>
                                </Route>
                            </Switch>
                        </main>
                        <Footer/>
                    </div>
                </div>
            </Toast>
        </Router>
    )
};