import React ,{useState,useEffect} from 'react';
import {useRequired} from 'helpers/hooks';
import {Autocomplete} from 'components/Autocomplete';
import {useLocation } from 'react-router-dom';
import {FieldsWrapper} from 'components/FieldsWrapper';
import {Input} from 'components/Input';
//import {Select} from 'components/Select';
import {useFormikContext} from 'formik';
import {AutocompleteSelect} from 'components/AutocompleteSelect';

export const Supplier = ({user,countries, editable, isAccountant, fetchRequiredFields,clientCompanies}) => {
    const {values,validateForm} = useFormikContext(); 
    let requiredFields = fetchRequiredFields(user,values?.info["document_type"], values?.info["payment_type"])
    
    let location = useLocation();
    const modify = location.pathname.split("/")[2] === "modify";

    const identificationNumberRequired = useRequired('supplier_identification_number', requiredFields);
    const vatRequired = useRequired('supplier_vat', requiredFields);
    const titleRequired = useRequired('supplier_title', requiredFields, isAccountant);
    const streetRequired = useRequired('supplier_street', requiredFields);
    const cityRequired = useRequired('supplier_city', requiredFields);
    const zipCodeRequired = useRequired('supplier_zip_code', requiredFields);
    const countryRequired = useRequired('supplier_country', requiredFields);
    const [refresh,setRefresh] = useState(true)

    let [manualFire, setManualFire] = useState(0);
    const [previousDependencies, setPreviousDependencies] = useState({
        supplier_title: values.info.supplier_title,
        supplier_street: values.info.supplier_street,
        supplier_city: values.info.supplier_city,
        supplier_zip_code: values.info.supplier_zip_code,
        supplier_country: values.info.supplier_country,
        supplier_identification_number: values.info.supplier_identification_number,
        supplier_sk_ic_dph: values.info.supplier_sk_ic_dph,
        supplier_vat: values.info.supplier_vat,
    });
    

    const getUserFriendlyCountries = () => {
        const prefferedcountries = ['cz', 'sk', 'de','pl','at','hu','gb','es','fr','us'];
        let userFriendlyCountries = [];
        
        prefferedcountries.forEach( (pc) => {
            userFriendlyCountries.push(countries[countries.indexOf(countries?.find(curr => curr.slug === pc))]) ;
            }
        )

        const blankCurrency = {"title" : "---", "id" : ""}
        userFriendlyCountries.push(blankCurrency)

        countries.forEach( (curr) => {
                if (!userFriendlyCountries.includes(curr)){
                    userFriendlyCountries.push(curr) ;
                }   
            }
        )

        return userFriendlyCountries
    }
    const userFriendlyCountries = getUserFriendlyCountries()
    
    const companyIdToSearch = parseInt(values.info["client_company"])
    let countrySlug = ''
    if (companyIdToSearch && clientCompanies){
    const foundCompany = clientCompanies.find(company => parseInt(company.id) === parseInt(companyIdToSearch));
    countrySlug = foundCompany ? foundCompany.country.slug : null;
    }


    useEffect(() => {
        const dependencies = {
            supplier_title: values.info.supplier_title,
            supplier_street: values.info.supplier_street,
            supplier_city: values.info.supplier_city,
            supplier_zip_code: values.info.supplier_zip_code,
            supplier_country: values.info.supplier_country,
            supplier_identification_number: values.info.supplier_identification_number,
            supplier_sk_ic_dph: values.info.supplier_sk_ic_dph,
            supplier_vat: values.info.supplier_vat,
        };
    
        for (const key in dependencies) {
            if (dependencies[key] !== previousDependencies[key]) {
                values.changedValues.push(key);
            }
        }
        if (JSON.stringify(dependencies)!==JSON.stringify(previousDependencies)){
            setPreviousDependencies(dependencies);
            setRefresh(!refresh); 
        }
    },  [values.info, manualFire,previousDependencies,refresh,values.changedValues]);

    
    if (countrySlug === 'sk'){
        return (
            <FieldsWrapper columns={2}>
                <FieldsWrapper columns={1}>
                <Input
                    name="info.supplier_title"
                    isRequired={titleRequired}
                    component={Autocomplete}
                    searchField="title"
                    placeholder="Hledat v databázi"
                    disabled={!editable}
                    onChange={validateForm}
                    highlighted={(modify &&values.changedValues.includes("supplier_title"))}
                >
                    Název
                </Input>
                <Input
                    name="info.supplier_street"
                    isRequired={streetRequired}
                    disabled={!editable}
                    highlighted={(modify &&values.changedValues.includes("supplier_street"))}
                >
                    Ulice
                </Input>
                <Input
                    name="info.supplier_city"
                    isRequired={cityRequired}
                    disabled={!editable}
                    highlighted={(modify &&values.changedValues.includes("supplier_city"))}
                >
                    Město
                </Input>
                <Input
                    name="info.supplier_zip_code"
                    isRequired={zipCodeRequired}
                    disabled={!editable}
                    highlighted={(modify &&values.changedValues.includes("supplier_zip_code"))}
                >
                    PSČ
                </Input>
               
                <Input
                    name="info.supplier_country"
                    component={AutocompleteSelect}
                    isRequired={countryRequired}
                    data={userFriendlyCountries}
                    disabled={!editable}
                    selectedValue={values.info["supplier_country"]} 
                    highlighted={(modify &&values.changedValues.includes("supplier_country"))}
                        onChange={(newValue) => {
                            values.info["supplier_country"] = newValue
                            setRefresh(!refresh)
                            setManualFire(manualFire+1)
                        }} 
                >
                    
                    Země
                </Input>
                
                
            </FieldsWrapper>
                <FieldsWrapper columns={1}>
                <Input
                    name="info.supplier_identification_number"
                    isRequired={identificationNumberRequired}
                    component={Autocomplete}
                    searchField="identification_number"
                    placeholder="Hledat v databázi/ARES"
                    disabled={!editable}
                    highlighted={(modify &&values.changedValues.includes("supplier_identification_number"))}
                    onChange={validateForm}
                >
                    IČ
                </Input>
                <Input
                    name="info.supplier_sk_ic_dph"
                    isRequired={false}
                    highlighted={(modify &&values.changedValues.includes("supplier_sk_ic_dph"))}
                    disabled={!editable}
                >
                    IČ DPH
                </Input>
                <Input
                    name="info.supplier_vat"
                    isRequired={vatRequired}
                    searchField="vat"
                    placeholder="Hledat v databázi"
                    component={Autocomplete}
                    disabled={!editable}
                    highlighted={(modify &&values.changedValues.includes("supplier_vat"))}
                    onChange={validateForm}
                >
                    DIČ
                </Input>
                
                </FieldsWrapper>
                
            </FieldsWrapper>
        )
    }
    else {
        return (
            <FieldsWrapper columns={2}>
                <Input
                    name="info.supplier_identification_number"
                    isRequired={identificationNumberRequired}
                    component={Autocomplete}
                    searchField="identification_number"
                    placeholder="Hledat v databázi/ARES"
                    disabled={!editable}
                    highlighted={(modify &&values.changedValues.includes("supplier_identification_number"))}
                    onChange={validateForm}
                >
                    IČ
                </Input>
                <Input
                    name="info.supplier_vat"
                    isRequired={vatRequired}
                    searchField="vat"
                    placeholder="Hledat v databázi"
                    component={Autocomplete}
                    disabled={!editable}
                    highlighted={(modify &&values.changedValues.includes("supplier_vat"))}
                    onChange={validateForm}
                >
                    DIČ
                </Input>
                <Input
                    name="info.supplier_title"
                    isRequired={titleRequired}
                    component={Autocomplete}
                    searchField="title"
                    placeholder="Hledat v databázi"
                    disabled={!editable}
                    highlighted={(modify &&values.changedValues.includes("supplier_title"))}
                    onChange={validateForm}
                >
                    Název
                </Input>
                <Input
                    name="info.supplier_street"
                    isRequired={streetRequired}
                    disabled={!editable}
                    highlighted={(modify &&values.changedValues.includes("supplier_street"))}
                >
                    Ulice
                </Input>
                <Input
                    name="info.supplier_city"
                    isRequired={cityRequired}
                    disabled={!editable}
                    highlighted={(modify &&values.changedValues.includes("supplier_city"))}
                >
                    Město
                </Input>
                <Input
                    name="info.supplier_zip_code"
                    isRequired={zipCodeRequired}
                    disabled={!editable}
                    highlighted={(modify &&values.changedValues.includes("supplier_zip_code"))}
                >
                    PSČ
                </Input>
                <Input
                    name="info.supplier_country"
                    component={AutocompleteSelect}
                    isRequired={countryRequired}
                    data={userFriendlyCountries}
                    highlighted={(modify &&values.changedValues.includes("supplier_country"))}
                    disabled={!editable}
                    selectedValue={values.info["supplier_country"]} 
                        onChange={(newValue) => {
                            values.info["supplier_country"] = newValue
                            setRefresh(!refresh)
                            setManualFire(manualFire+1)
                        }} 
                >
                    
                    Země
                </Input>
                
            </FieldsWrapper>
        )
    }
};

/*
<Input
                    name="info.supplier_title"
                    isRequired={titleRequired}
                    component={Autocomplete}
                    searchField="title"
                    placeholder="Hledat v databázi"
                    disabled={!editable}
                    onChange={validateForm}
                >
                    Název
                </Input>

*/