import React from 'react';

import {Button} from 'components/Button';
import {FieldsWrapper} from 'components/FieldsWrapper';
import styles from 'css_modules/ProfileFormBody.module.css';

export const ProfileFormBody = ({button, children}) => {

    return (
        <>
            <FieldsWrapper columns={3}>
                {children}
            </FieldsWrapper>
            <div className={styles.row}>
                <Button primary={button.primary} type="submit">{button.text}</Button>
            </div>
        </>

    )
};

