import React, {useState} from 'react';
import {useFetch} from 'helpers/hooks';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import styles from 'css_modules/ExportModal.module.css';


export const ExportModal = ({close,uuid}) => {
    let [isdoctitle,setisdoctitle] = useState("Připravuji ISDOC...")
    let [isdocbusy,setisdocbusy] = useState(true)

    /*
    let [csvtitle,setcsvtitle] = useState("Připravuji CSV...")
    let [csvbusy,setcsvbusy] = useState(true)
    */
    /*
    let [xlstitle,setxlstitle] = useState("Připravuji XLS...")
    let [xlsbusy,setxlsbusy] = useState(true)
    */
   
    function download(filename, text) {
        var BOM = "\uFEFF";
        text = BOM + text;
       
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }

    let isdoc = useFetch('/get_generated_isdoc/?documents='+[uuid])
    
    if (isdoc.data && isdocbusy===true){
        if (isdoc.data[uuid]){
            setisdoctitle("Exportovat do ISDOC")
            setisdocbusy(false)
        }
        else{
            if (isdoctitle !== "Export ISDOC se nezdařil"){
                setisdoctitle("Export ISDOC se nezdařil")
            }
        }
    }

    /*
    let csv = useFetch('/get_generated_csv/?documents='+[uuid])
    
    if (csv.data && csvbusy===true){
        if (csv.data[uuid]){
            setcsvtitle("Exportovat jako CSV")
            setcsvbusy(false)
        }
        else{
            if (csvtitle !== "Export CSV se nezdařil"){
                setcsvtitle("Export CSV se nezdařil")
            }
        }
    }
    */
   
    const exportISDOC = () => {
        download('document.isdoc', isdoc.data[uuid]);
        close()
    }

    /*
    const exportCSV = () => {
        download('document.csv', csv.data[uuid]);
        close()
    }
    */

    const modaltitle = 'Exportovat doklad'
    return (
        <>
        <div className={styles.ontop}>
        <Modal name={modaltitle} close={close} >
            <div className={styles.column}>
                <div className={styles.singlebutton}>
                    <Button children={isdoctitle} onClick={exportISDOC} className={"wideprimary"} disabled={isdocbusy} />
                </div>

                
                
                
            </div>
        </Modal>
        </div>
        </>
    )
};

/*
<div className={styles.singlebutton}>
                    <Button children={csvtitle} onClick={exportCSV} className={"wideprimary"} disabled={csvbusy} />
                </div>
                */