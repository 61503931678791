import React from 'react';

import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import {request} from 'helpers/tools';
import styles from 'css_modules/Modal.module.css';
import {useToast} from 'helpers/hooks';

export const RemoveModal = ({close, docs, update, user, hasTemplate}) => {
    const showToast = useToast();
    let message = 'Přesunout mezi smazané?';
    if (hasTemplate){
        message = 'Přesto přesunout mezi smazané?';
    }

    const removeTemplate = async (template) => {
        close();
        const markAsDeleted = () => request('/remove_template/', 'POST', 
        {"document":String(template.uuid),
        "id":String(template.id)})
        await markAsDeleted();
        update()
    };

    const remove = async () => {
        close();
        if (docs.length === 1 ){
            if (hasTemplate){removeTemplate(hasTemplate)}
            const markAsDeleted = () => request(`/my_documents/${docs[0].uuid}/remove/`, 'POST');
            await markAsDeleted();
            update()
        }
        else{
            const url = '/remove_many_docs/';
            const data = {uuids: docs.map(doc => doc.uuid)};
            await request(url, 'POST', JSON.stringify(data));
            showToast('Doklady byly smazány');
            await new Promise(resolve => setTimeout(resolve, 1000));
            window.location.reload();
        }
    };

    
    if (!docs || docs.length===0){
        docs = [JSON.parse(sessionStorage.getItem("removeDoc"))]
        sessionStorage.setItem("removeDoc"+user.pk, undefined)
    }
    
    let modalName = docs[0].name
    if (docs.length>1 && docs.length<5){
        modalName = docs.length+" doklady" 
    }
    if (docs.length>=5){
        modalName = docs.length+" dokladů" 
    }
    return (
        <div className={styles.ontop}>
        <Modal closeOnClickOutside name={modalName} close={close}>
            {hasTemplate && 
            <p className={styles.body}>K dokumentu je navázána šablona:  {hasTemplate.name}</p>
            }
            <p className={styles.body}>{message}</p>
            <div className={styles.buttons}>
                <Button onClick={close}>Zavřít</Button>
                <Button primary onClick={remove}>Smazat</Button>
            </div>
        </Modal>
        </div>
    )
};