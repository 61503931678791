import React from 'react';

import styles from 'css_modules/Footer.module.css';

export const Footer = () => (
    <footer className={styles.footer}>
        <p className={styles.text}>
            <a href="https://www.youtube.com/playlist?list=PLieL8NZ8IhuVwlDNcMPEuMtZs79dCoYmS" target="_blank" rel="noreferrer">Videonávody</a>
            <span className={styles.pipe}>|</span>
            <a href="https://docspoint.cz/cs/faq-nejcastejsi-dotazy-navody/" target="_blank" rel="noreferrer">Často kladené dotazy (FAQ)</a>
            <span className={styles.pipe}>|</span>
            <a href="mailto:servis@docspoint.cz">servis@docspoint.cz</a>
            <span className={styles.pipe}>|</span>
            <span className={styles.registered}>®</span>
            <a href="https://docspoint.cz/" target="_blank" rel="noreferrer">Docspoint</a>
            
        </p>
    </footer>
);