import React from 'react';

import styles from 'css_modules/FilterButtons.module.css';

export const FilterButtons = ({activeOnly, setActiveOnly,user}) => {
    const leftButtonClass = activeOnly ? styles.leftButton : styles.leftButtonActive;
    const rightButtonClass = activeOnly ? styles.rightButtonActive : styles.rightButton;

    const showAll = () => {
        setActiveOnly(false);
        localStorage.setItem("activeOnly"+user.pk, "false")
    }
    const showActiveOnly = () => {
        setActiveOnly(true);
        localStorage.setItem("activeOnly"+user.pk, "true")
        console.log("activeOnly"+user.pk)
    }

    return (
        <div className={styles.container}>
            <button onClick={showAll} className={leftButtonClass}>Zobrazit vše</button>
            <button onClick={showActiveOnly} className={rightButtonClass}>Jen aktivní</button>
        </div>
    );
};