import * as yup from "yup";

const tooLongMsg = 'Příliš mnoho znaků';
const requiredMsg = 'Toto pole je povinné';
//const ibanWrFormatMsg = 'Ověření Iban selhalo';
const digitsOnlyMsg = 'Toto pole musí sestávat pouze z číslic';
//const swiftWrFormatMsg = 'Zadejte číslo účtu ve formátu SWIFT';
const atleastTwoMsg = 'Toto pole potřebuje alespoň dva znaky';
const noSpecialCharsMsg = 'Používejte pouze písmena, číslice, pomlčku nebo lomítko'

export var validationSchema = yup.object().shape({
    info: yup.object().shape({
        country: yup.string(),
      
        supplier_identification_number: yup.string()
            .when('supplier_country', {
                is: 'cz',
                then: yup.string()
                    .matches(/^[0-9]*$/, 'IČ partnera z ČR musí sestávat pouze z číslic')
                    .length(8, 'IČ partnera z ČR musí mít 8 znaků'),
                otherwise: yup.string()
                    .max(15, tooLongMsg)
            }).test(
                'conditional_test', requiredMsg, function test(value){
                    let property = this.path.split('.')[1];
                    return !(this.options.from[1].value.required_fields[property] && value === undefined);
                }
            ),
        payment_type: yup.string(),

        supplier_account_number: yup.string().matches(
             /^[0-9a-zA-z\-/]*$/,
              noSpecialCharsMsg
            )
            .max(39, tooLongMsg)
            .when(["document_type", "payment_type"], {
                is: (document_type, payment_type) => {
                    return payment_type === "2" || !["rzn", "fpr"].includes(document_type)
                },
                then: yup.string().test(
                    'other_condition', 'test passed by', function test(){
                        return true;
                    }
                ),
                otherwise: yup.string().test('isRequiredAndEmpty', requiredMsg, function test(value){
                        let property = this.path.split('.')[1];
                        return !(this.options.from[1].value.required_fields[property] && value === undefined);
                })
            }),
        supplier_bank_code: yup.string()
            .max(11, tooLongMsg)
            .matches(
                /^[0-9a-zA-z\-/]*$/,
                noSpecialCharsMsg
            )
            .when(["document_type", "payment_type"], {
                is: (document_type, payment_type) => {
                    if (payment_type === "2"){ // Represents payment in cash, which is not require bank data verification
                        return true;
                    }
                    return !["rzn", "fpr"].includes(document_type)
                },
                then: yup.string().test(
                    'other_condition', 'passes by', function test(){
                        return true;
                    }
                ),
                otherwise: yup.string().test(
                    'conditional_test', requiredMsg, function test(value){
                        let property = this.path.split('.')[1];
                        return !(this.options.from[1].value.required_fields[property] && value === undefined);
                    }
                )
            }),
        document_type: yup.string().test(
            'conditional_test', requiredMsg, function test(value){
                let property = this.path.split('.')[1];
                return !(this.options.from[1].value.required_fields[property] && value === undefined);
            }
        ),
        document_identifier: yup.string().test(
            'conditional_test', requiredMsg, function test(value){
                let property = this.path.split('.')[1];
                return !(this.options.from[1].value.required_fields[property] && value === undefined);
            }
        ).min(2, atleastTwoMsg).max(90, tooLongMsg),
        activity: yup.string().nullable(true).test(
            'conditional_test', requiredMsg, function test(value){
                let property = this.path.split('.')[1];
                return !(this.options.from[1].value.required_fields[property] && value === undefined);
            }
        ),
        centre: yup.string().nullable(true).test(
            'conditional_test', requiredMsg, function test(value){
                let property = this.path.split('.')[1];
                return !(this.options.from[1].value.required_fields[property] && value === undefined);
            }
        ),
        contract: yup.string().nullable(true).test(
            'conditional_test', requiredMsg, function test(value){
                let property = this.path.split('.')[1];
                return !(this.options.from[1].value.required_fields[property] && value === undefined);
            }
        ),
        supplier_city: yup.string().test(
            'conditional_test', requiredMsg, function test(value){
                let property = this.path.split('.')[1];
                return !(this.options.from[1].value.required_fields[property] && value === undefined);
            }
        ),
        supplier_country: yup.string().test(
            'conditional_test', requiredMsg, function test(value){
                let property = this.path.split('.')[1];
                return !(this.options.from[1].value.required_fields[property] && value === undefined);
            }
        ),
        supplier_street: yup.string().test(
            'conditional_test', requiredMsg, function test(value){
                let property = this.path.split('.')[1];
                return !(this.options.from[1].value.required_fields[property] && value === undefined);
            }
        ),

        supplier_title: yup.string().when(["document_type"], {
            is: (document_type) => {
                return document_type === "rzn";
            },
            then: yup.string().test(
                'other_condition', 'passes by', function test(){
                    return true;
                }
            ),
            otherwise: yup.string().test(
                'conditional_test', requiredMsg, function test(value){
                    let property = this.path.split('.')[1];
                    return !(this.options.from[1].value.required_fields[property] && value === undefined);
                }
            )
        }),
        supplier_vat: yup.string().test(
            'conditional_test', requiredMsg, function test(value){
                let property = this.path.split('.')[1];
                return !(this.options.from[1].value.required_fields[property] && value === undefined);
            }
        ),
        supplier_zip_code: yup.string().test(
            'conditional_test', requiredMsg, function test(value){
                let property = this.path.split('.')[1];
                return !(this.options.from[1].value.required_fields[property] && value === undefined);
            }
        ),
        variable_symbol: yup.string()
            .when(["document_type", "payment_type"], {
                is: (document_type, payment_type) => {
                    if (payment_type === "2"){ // Represents payment in cash
                        return true;
                    }
                    return !["fvz", "opo", "fpr", 'fpz', 'fvy', 'oza'].includes(document_type)
                },
                then: yup.string().test(
                    'other_condition', 'passes by', function test(){
                        return true;
                    }
                ),
                otherwise: yup.string().test(
                    'conditional_test', requiredMsg, function test(value){
                        //Do check if this field even displyed in form
                        let property = this.path.split('.')[1];
                        return !(this.options.from[1].value.required_fields[property] && value === undefined);
                    }
                ).max(15, tooLongMsg).matches(/^[0-9]*$/, digitsOnlyMsg) //originally 10, changed to 15 after customer request
           }),

        number: yup.string().when(["document_type"], {
            is: (document_type) => {
                return ['fpr', 'fpz', 'fvy', 'fvz', 'oza'].includes(document_type)
            },
            then: yup.string().test(
                'type_role_req_test', requiredMsg, function test(value){
                    let property = this.path.split('.')[1];
                    if(this.options.from[1].value.user_role === 'AccountantUser' || this.options.from[1].value.required_fields[property]){
                        return undefined!==value;
                    }
                    return true;
                }
            ),
            otherwise: yup.string().test(
                'conditional_test', requiredMsg, function test(value){
                    let property = this.path.split('.')[1];
                    return !(this.options.from[1].value.required_fields[property] && value === null);
                }
            )
        }),

        note: yup.string().test(
            'conditional_test', requiredMsg, function test(value){
                let property = this.path.split('.')[1];
                return !(this.options.from[1].value.required_fields[property] && value === undefined);
            }
        ),
        taxable_supply_date: yup.string()
            .nullable(true).defined()
            .test(
                'conditional_test', requiredMsg, function test(value){
                    let property = this.path.split('.')[1];
                    return !(this.options.from[1].value.required_fields[property] && value === null);
            }),
        date: yup.string()
            .nullable(true).defined()
            .test(
            'conditional_test', requiredMsg, function test(value) {
                    let property = this.path.split('.')[1];
                    return !(this.options.from[1].value.required_fields[property] && value === null);
            }),
        due_date:yup.string()
            .nullable(true)
            .when(["document_type"], {
                is: (document_type) => {
                    return ['ppr', 'pvy'].includes(document_type)
                },
                then: yup.string().test(
                    'other_condition', 'test passed by', function test(){
                        return true;
                    }
                ),
                otherwise: yup.string().test(
                    'conditional_test', requiredMsg, function test(value){
                        let property = this.path.split('.')[1];
                        return !(this.options.from[1].value.required_fields[property] && value === null);
                    }
                )
            }),
        accounting_year: yup.string().when(["document_type"], {
            is: (document_type) => {
                return document_type === "rzn";
            },
            then: yup.string().test(
                'other_condition', 'passes by', function test(){
                    return true;
                }
            ),
            otherwise: yup.string().test(
                'conditional_test', requiredMsg, function test(value){
                    let property = this.path.split('.')[1];
                    return !(this.options.from[1].value.required_fields[property] && value === undefined);
                }
            )
        }),
        num_series: yup.string().when(["document_type"], {
            is: (document_type) => {
                return document_type === "rzn" || !document_type;
            },
            then: yup.string().test(
                'other_condition', 'passes by', function test(){
                    return true;
                }
            ),
            otherwise: yup.string().test(
                'conditional_test', requiredMsg, function test(value){
                    let property = this.path.split('.')[1];
                    return !(this.options.from[1].value.required_fields[property] && value === undefined);
                }
            )
        }),
    })
});
