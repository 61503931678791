import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useFormikContext} from 'formik';

import {ToastContext} from 'components/Toast';

export const useFetch = (url, initialValue)=> {
    const [data, setData] = useState(initialValue);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const isMounted = useRef(true);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetch(process.env.REACT_APP_API + url, {
                headers: {
                    'Authorization': 'JWT ' + localStorage.getItem('DocspointJWT'),
                    'Content-Type': 'application/json'
                },
            });
            if (response.ok) {
                const json = await response.json();
                if (isMounted.current) {
                    setData(json);
                }
            } else if (response.status === 401) { // redirect to login when session ends etc.
                window.location.href = '/auth/login';
            } else {
                if (isMounted.current) {
                    setError(true);
                }
            }
        } catch (error) {
            console.log(error.message);
            if (isMounted.current) {
                setError(true);
            }
        } finally {
            if (isMounted.current) {
                setLoading(false);
            }
        }
    }, [url]);


    useEffect(() => {
        isMounted.current = true;
        fetchData();
        return () => {
            isMounted.current = false;
        }
    }, [fetchData, url]);

    return {data, setData, fetchData, error, loading}
};

export const useModal = () => {
    const [viewModal, setViewModal] = useState(false);

    const toggleView = () => setViewModal(!viewModal);

    return [viewModal, toggleView]
};

export const useFilter = (options, additionalFilters) => {
    const [filteredOptions, setFilteredOptions] = useState([]);

    const {values} = useFormikContext();

    const filtersString = JSON.stringify(additionalFilters) // stringify to prevent useEffect loop

    useEffect(() => { // filter options
        if (options) {
            setFilteredOptions(options.filter(option => {
                const clientCompanyFilter = option.client_company === parseInt(values.info.client_company);
                if (filtersString) {
                    const filters = Object.entries(JSON.parse(filtersString));
                    return clientCompanyFilter && filters.every(([key, value]) => option[key] === value);
                }
                return clientCompanyFilter
            }))
        }
    }, [
        filtersString,
        options,
        values.info.client_company,
    ]);

    return filteredOptions
};

export const useRequired = (fieldName, requiredFields, alternativeCondition) => {
    const {values} = useFormikContext();
    return (requiredFields?.[fieldName] || alternativeCondition) && values.info.document_type !== 'rzn' &&  values.info["state"]!=='tpl';
}

export const useToast = () => useContext(ToastContext);

export const dynamicSort = (property) => {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    function resolve(path, obj) {
        return path.split('.').reduce(function (prev, curr) {
            return prev ? prev[curr] : null
        }, obj)
    }

    var isDate = function (date) {
        const regex = RegExp('^\\d\\d\\d\\d-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])(\\s|[T])(00|0?[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])');
        if (regex.test(date)) {
            return (new Date(date) !== "Invalid Date" && !isNaN(new Date(date))) ? true : false;
        } else {
            return false;
        }
    }

    return function (a, b) {
        var a_val = resolve(property, a);
        var b_val = resolve(property, b);
        var result = false;
        if (isDate(a_val) && isDate(b_val)) {
            var a_time = new Date(a_val).getTime();
            var b_time = new Date(b_val).getTime();
            // console.log('a_time=' + a_time + ' b_time=' + b_time);
            result = (a_time < b_time) ? -1 : (a_time > b_time) ? 1 : 0;
        } else {
            // console.log('Not Dates, a=' + a_val + " b=" + b_val);
            result = (a_val < b_val) ? -1 : (a_val > b_val) ? 1 : 0;
        }

        if (a_val === null) {
            return 1;
        }

        if (b_val === null) {
            return -1;
        }

        return result * sortOrder;
    }
}
