import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import {request} from 'helpers/tools';
import styles from 'css_modules/Modal.module.css';

export const RemoveAssignedModal = ({close, doc, update}) => {
    const remove = async () => {
        close();
//        const unassign = () => request(`/my_documents/${doc.uuid}/unsubscribe/`,  'POST');
        await request(`/documents/unassign/`, 'POST', [doc.uuid]).catch(err => console.log(err));
        update()
    };

    const message = 'Opravdu chcete zrušit přiřazení dokumentu?';
    return (
        <div className={styles.ontop}>
        <Modal closeOnClickOutside name={doc.name} close={close}>
            <p className={styles.body}>{message}</p>
            <div className={styles.buttons}>
                <Button onClick={close}>Zavřít</Button>
                <Button primary onClick={remove}>Zrušit</Button>
            </div>
        </Modal>
        </div>
    )
};