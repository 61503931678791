import React, {useCallback, useEffect} from 'react';
import {Link, NavLink, useLocation,useHistory} from 'react-router-dom';

import {Avatar} from 'components/Avatar'
import {request} from 'helpers/tools';
import styles from 'css_modules/Nav.module.css';
import logo from 'assets/png/logo_dark.png';
import {Button} from 'components/Button';
export const Nav = ({fetchUser, permissions, user}) => {

    const userFullName = `${user.first_name} ${user.last_name}`;

    const logout = useCallback(async () => {
        await request('/rest-auth/logout/', 'POST', null, true);
        localStorage.removeItem('DocspointJWT');
        window.location.href = '/auth/login';
    }, []);

    useEffect(() => { // fetch user when JWT changes or logout when it is removed (e.g. logout in another tab)
        const handleTokenChange = e => {
            if (e.key === 'DocspointJWT') {
                if (e.newValue) {
                    fetchUser();
                } else {
                    logout();
                }
            }
        }
        window.addEventListener('storage', handleTokenChange)
        return () => window.removeEventListener('storage', handleTokenChange)
    }, [fetchUser, logout])

    let onRoleChange = async (e) => {
        let role = user.roles[parseInt(e.target.id.substring(1))];

        await request('/user/role/', 'POST', role).catch(err => console.log(err));
        fetchUser();
    }
    const localize = (role)=>{
        switch (role){
            case 'clientuser':
                return 'Základní uživatel'
            case 'accountantuser':
                return 'Účetní uživatel'
            case 'maintenanceuser':
                return 'Obslužný uživatel'
            case 'managementuser':
                return 'Schvalovací uživatel'
            case 'organizationunitadmin':
                return 'Admin uživatel  '
            default:
                return 'Neznama roli'
        }
    }
    // let prettifyRole = (role) => {return role.replace('unit', ' ').replace('user', ' user');}
    let prettifyRole = (role) => {return localize(role);}

    let populateRoleChoices = () => {
        if (user.roles.length === 1) {return}

        return user.roles.map((role, i) =>
            <div className={styles.choice} key={'c'+ i}>
                <input type='radio' key={'r'+ i} id={'r'+i} name='role' defaultChecked={user.role.toLowerCase() === role} onChange={onRoleChange}/>
                <label key={'role_' + i} htmlFor={'r'+i}>
                {prettifyRole(role)}</label>
            </div>
        )
    };
    
    let location = useLocation();
    const history = useHistory();
    const navToUpload = (e) => {
        //let reload = false
        if (String(sessionStorage.getItem("template"))!=='undefined'){
            sessionStorage.setItem("template",undefined)
        //    reload = true
        }
        history.push('/upload');
        //if (reload){window.location.reload();}
    }
    const navToMyTemplates = (e) =>{
        if(location.pathname.endsWith('my_templates') ) {
            e.preventDefault()
            console.log('denied to mytemplates')
        }
    } 
    const navToMyDocuments = (e) =>{
        if(location.pathname.endsWith('my_documents') ) {
            e.preventDefault()
            console.log('denied to mydocs')
        }
    } 
    const navToDocuments = (e) =>{
        if( !location.pathname.endsWith('my_documents') && location.pathname.endsWith('documents') ){
            e.preventDefault()
            console.log('denied to docs')
        } 
    } 
    const navToArchive = (e) =>{
        if( location.pathname.endsWith('archive') ){
            e.preventDefault()
            console.log('denied to arch')
        } 
    } 
    
    return (
        <nav className={styles.container}>
            <div className={styles.sticky}>
                <section className={styles.top}>
                    <img src={logo} alt="logo" className={styles.logo}/>
                    <div className={styles.menu}>
                        {
                            permissions.upload &&
                            <NavLink to="/upload" onClick={navToUpload } activeClassName={styles.menuItemActive} className={styles.menuItem}>
                                Nahrát
                            </NavLink>
                        }
                        {
                            permissions.accessDocuments && (
                                <NavLink
                                    to="/documents"
                                    activeClassName={styles.menuItemActive}
                                    className={styles.menuItem}
                                    onClick={navToDocuments}
                                >
                                    Doklady
                                </NavLink>
                            )}
                        {
                            permissions.accessMyDocuments && (
                                <NavLink    to="/my_documents" activeClassName={styles.menuItemActive} 
                                            className={styles.menuItem} onClick={navToMyDocuments}>
                                    Moje doklady
                                </NavLink>
                            )
                        }
                        {
                            permissions.accessArchive &&
                            <NavLink to="/archive" activeClassName={styles.menuItemActive} 
                                      onClick={navToArchive}  className={styles.menuItem}>
                                Archiv
                            </NavLink>
                        }
                        {
                            user.toggle_document_templates===true &&
                            <NavLink to="/my_templates" activeClassName={styles.menuItemActive} 
                                      onClick={navToMyTemplates}  className={styles.menuItem}>
                                Šablony
                            </NavLink>
                        }
                    </div>
                </section>
                <section className={styles.bottom}>
                    <div className={styles.radioContainer}> {populateRoleChoices()} </div>
                    <div className={styles.userContainer}>
                        <div className={styles.avatar}>
                            <Link to="/profile">
                                <Avatar user={user}/>
                            </Link>
                        </div>
                        <NavLink to="/profile" activeClassName={styles.menuItemActive} className={styles.userFullName}>
                            {userFullName}
                        </NavLink>
                        <Link to="/profile" className={styles.userCompany}>{user.company_title}</Link>
                        <Button onClick={logout} className={styles.logOut}>Odhlásit se</Button>
                    </div>
                </section>
            </div>
        </nav>
    )
};