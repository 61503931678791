import React from 'react';
import {Form, Formik} from 'formik';

import {useToast} from 'helpers/hooks';
import {Input} from 'components/Input';
import {ProfileFormBody} from 'components/ProfileFormBody';
import {request} from 'helpers/tools';

export const PasswordChange = () => {
    const showToast = useToast();

    const buttonProps = {
        text: 'Změnit heslo'
    };

    return (
        <>
            <h2>Změna hesla</h2>
            <Formik
                initialValues={{
                    old_password: '',
                    new_password1: '',
                    new_password2: ''
                }}
                onSubmit={async (values, {setErrors, resetForm}) => {
                    const response = await request('/rest-auth/password/change/', 'POST', values);
                    if (response.ok) {
                        resetForm();
                        showToast(response.body.detail);
                    } else {
                        setErrors(response.body)
                    }
                }}
            >
                <Form>
                    <ProfileFormBody button={buttonProps}>
                        <input hidden type="text" autoComplete="username"/>
                        <Input name="old_password" type="password" autoComplete="current-password" required>
                            Původní heslo
                        </Input>
                        <Input name="new_password1" type="password" autoComplete="new-password" required>
                            Nové heslo
                        </Input>
                        <Input name="new_password2" type="password" autoComplete="new-password" required>
                            Nové heslo pro potvrzení
                        </Input>
                    </ProfileFormBody>
                </Form>
            </Formik>
        </>
    )
};