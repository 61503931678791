import React from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';

import {Welcome} from 'components/Welcome';
import {Wrapper} from 'components/Wrapper'

const PrivateRoute = ({component: Component, ...rest}) => { // redirect unauthorized users to login
    return (
        <Route
            {...rest}
            render={props =>
                localStorage.getItem('DocspointJWT') ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth/login',
                            state: {from: props.location}
                        }}
                    />
                )
            }
        />
    );
};

export const App = () => {
    return (
        <Router>
            <Switch>
                <Route path="/auth">
                    {({history}) => <Welcome history={history}/>}
                </Route>
                <PrivateRoute component={Wrapper}/>
            </Switch>
        </Router>
    );
};