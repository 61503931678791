import React from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {AuthForm} from 'components/AuthForm';
import {Input} from 'components/Input';
import {request} from "helpers/tools";

export const PasswordReset = () => {
    const history = useHistory();
    const {token, uid} = useParams();

    const authProps = {
        heading: 'Obnovení hesla',
        button: 'Změnit heslo',
        initialValues: {
            uid: uid,
            token: token,
            new_password1: '',
            new_password2: ''
        },
        handleSubmit: async (values, {setErrors}) => {
            const url = '/rest-auth/password/reset/confirm/';
            const response = await request(url, 'POST', values, true);
            if (!response.ok) {
                return setErrors(response.body)
            }
            history.push('/auth/login')
        }
    };

    return (
        <AuthForm {...authProps}>
            <input hidden type="text" autoComplete="username"/>
            <Input name="new_password1" type="password" autoComplete="new-password" required>
                Nové heslo
            </Input>
            <Input name="new_password2" type="password" autoComplete="new-password" required>
                Potvrzení hesla
            </Input>
        </AuthForm>
    )
};