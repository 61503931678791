import React from 'react';

import {MetadataRow} from 'components/MetadataRow';

import {useFetch} from 'helpers/hooks';

export const MetadataTable = ({doc,clientCompanies, currencies, detail, docState, docTypes, externalId}) => {
    const humanize = (data, property, identifier, target) => {
        const value = detail.info[property]
        if (data && value) {
            const item = data.find(item => item[identifier ?? 'id'] === value);
            return item ? item[target ?? 'title'] : '';
        }
        return ""
    };
    const formatDate = (date) => date ? `${date.slice(8, 10)}/${date.slice(5, 7)}/${date.slice(0, 4)}` : null;
    const costReducer = (total, cost) => {
        const accumulator = total ? parseFloat(total) : 0;
        const currentValue = cost.value_final ? parseFloat(cost.value_final) : 0;
        return accumulator + currentValue
    };

    let docCustomType = useFetch('/get_user_type_for_documents/?documents='+[doc.uuid])
    
    //const customDocTypes = useFetch('/document_user_created_custom_types/', []);
    
    let myDocType = ""
    if (docCustomType.data && docCustomType.data.length>0){
        myDocType = String(docCustomType.data[0].user_types[0].name) 
    }
    
    let state = doc["document_info"]["state"]
    const clientCompany = humanize(clientCompanies, 'client_company');
    let docType = humanize(docTypes, 'document_type', 'slug', 'name');
    if (myDocType!== ""){
        docType = myDocType
    }   
    const currency = humanize(currencies, 'currency');
    const taxableSupplyDate = formatDate(detail.info.taxable_supply_date);
    const dueDate = formatDate(detail.info.due_date);
    const accountingDate = formatDate(detail.info.accounting_date);
    const taxDeductionDate = formatDate(detail.info.tax_deduction_date);
    const paymentDate = formatDate(detail.info.payment_date);
    const totalCost = detail.costs.length
        ? detail.costs.reduce(costReducer, 0).toFixed(2).replace('.', ',')
        : '---';
    const externalIdValue = docState.id === 'imp' ? externalId : '---';
    return (
            <dl>
                <MetadataRow property="Klientská společnost" value={clientCompany}/>
                <MetadataRow property="Typ dokladu" value={docType}/>
                <MetadataRow property="Popis dokladu" value={detail.info.document_identifier}/>
                <MetadataRow property="Číslo dokladu" value={detail.info.number}/>
                <MetadataRow property="Název partnera" value={detail.info.supplier_title}/>
                <MetadataRow property="IČ partnera" value={detail.info.supplier_identification_number}/>
                {state !== "tpl" && <MetadataRow property="Datum zdanitelného plnění" value={taxableSupplyDate}/>}
                {state !== "tpl" &&<MetadataRow property="Datum splatnosti" value={dueDate}/>}
                {state !== "tpl" &&<MetadataRow property="Datum účetního případu" value={accountingDate}/>}
                {state !== "tpl" &&<MetadataRow property="Datum odečtu" value={taxDeductionDate}/>}
                <MetadataRow property="Celková částka s DPH" value={totalCost}/>
                <MetadataRow property="Měna" value={currency}/>
                <MetadataRow property="Variabilní symbol" value={detail.info.variable_symbol}/>
                <MetadataRow property="Forma úhrady" value={detail.info.payment_type_title}/>
                <MetadataRow property="Číslo účtu / IBAN" value={detail.info.supplier_account_number}/>
                <MetadataRow property="Kód banky / SWIFT" value={detail.info.supplier_bank_code}/>
                {state !== "tpl" &&<MetadataRow property="Datum úhrady" value={paymentDate}/>}
                <MetadataRow property="Číslo v účetním systému" value={externalIdValue}/>
            </dl>
    )
};