import React, {useState} from 'react';

import {useToast} from 'helpers/hooks';
import {Button} from 'components/Button';
import {DuplicateTableRow} from 'components/DuplicateTableRow';
import {Modal} from 'components/Modal';
import {request} from 'helpers/tools';
import styles from 'css_modules/DuplicateModal.module.css';

export const DuplicateSModal = ({duplicates, setDuplicates, update}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const showToast = useToast();
    const [marked, setMarked] = useState([]);
    const buttonText = 'Odeslat';

    const handleCheck = (e, id)=>{
        if(e.currentTarget.checked){
            setMarked(prev=>[...prev, id]);
        }else{
            setMarked(prev=>prev.filter(m=>m !== id));
        }
    }

    const forceSave = async () => {
        setIsSubmitting(true);
        if(duplicates.length > 1){
            if(marked.length) {
                const response = await request('/my_documents/forward/?force=true', 'POST', [...marked]);
                let missing = response.body.filter(dok=>dok.missing_fields.length);
                if(missing.length){
                    let message = [];
                    missing.forEach(dok=>{
                            message.push(duplicates.filter(ddok => ddok.document === dok.document)[0].name + ':');
                            dok.missing_fields.forEach(mf => message.push(mf));
                    })
                    setTimeout(()=>
                        showToast("Vyplňte povinná pole:\n", message)
                    ,3*800);
                    setTimeout(()=>
                        showToast("Vyplňte povinná pole:\n", message)
                    ,4*800);
                    showToast(`Doklady s chybějícími povinnými poli nebyly odeslány. Odesláno ${(response.body.length - missing.length)}/${response.body.length} dokladů.`)
                }else{
                    showToast('Odesláno');
                }
            }
            else{
                showToast('Nebyl označen žádný doklad.');
                setIsSubmitting(false);
                close();
                return;
            }
        }else{
            const response = await request('/my_documents/forward/?force=true', 'POST', [duplicates[0].document]);
            if (response.body[0].missing_fields.length) {
                showToast('Vyplňte povinná pole:', response.body[0].missing_fields)
            }else{
                showToast('Odesláno');
            }
        }
        setIsSubmitting(false);
        update();
        // showToast('Nebyl označen žádný doklad.');
        close();
    }

    const close = () => {
        setDuplicates([]);
    }

    return (
        <div className={styles.ontop}>
        <Modal name="Duplicitní záznamy" close={close}>
            <main className={styles.body}>
            {duplicates.map((duplicate, i) =>
                <div className={styles.block} key={duplicate.document + (""+ i)}>
                    <p>{`${duplicate.name} jsou shodné s ${duplicate.duplicates.length > 1 ? 'jinými doklady' : 'jiným dokladem'}:`}</p>
                    <table className={styles.table}>
                        <thead>
                        <tr>
                            <th>
                                ID
                            </th>
                            <th>
                                Stav
                            </th>
                            <th>Poslední úprava</th>
                        </tr>
                        </thead>
                        <tbody>
                        {duplicate.duplicates.map(duplicate => <DuplicateTableRow key={duplicate.uuid} duplicate={duplicate}/>)}
                        </tbody>
                    </table>
                    {duplicates.length > 1 &&
                    <input className={styles.checkbox} onChange={(e)=>handleCheck(e, duplicate.document)} type="checkbox" id={"doc"+i} value={i + " checked"}/>
                    }
                    <p className={styles.pShort}>Chcete přesto odeslat tento doklad?</p>
            </div>
            )}

            </main>
                <footer className={styles.buttons}>
                    <Button onClick={close}>Zrušit</Button>
                    <Button primary disabled={isSubmitting} onClick={forceSave}>{buttonText}</Button>
                </footer>
            </Modal>
        </div>
    )
};