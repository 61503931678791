import React, {useState,useEffect} from 'react';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import styles from 'css_modules/ForwardPickerModal.module.css';
import {Select} from 'components/Select';

export const LoadTemplateModal = ({close, templates, accountingYears, year, ignoreYear, user,loadFromTemplate}) => {
    
    let templatesList = []
    if (templates){
        
        templates.data.results.forEach(e=>{
            let d = {}
            d["title"] = e.document_info["template_name"]
            d["info"] = e.document_info
            d["uuid"] = e["uuid"]
            d["id"] = templatesList.length
            
            if (e.accounting_year!==year)  {
                //d["off"]="true"
                //d["title"] += " | rok " +e.accounting_year
            }
            templatesList.push(d)
        })
    }
    templatesList.sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' }));

    
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [selectedYear, setSelectedYear] = useState(0);
    

    const handleTemplateChange = (e) => {
        
        setSelectedTemplate( templatesList.find(item => item.id === parseInt(e.target.value) ) )
        
        let sy = 0
        try{
           sy = accountingYears.find(y=>y.id === parseInt((templatesList.find(item => item.id === parseInt(e.target.value) ).info.accounting_year ))).year
        }
        catch (err){console.log(err)}
        if (selectedYear!==sy){setSelectedYear(sy)}
       
        console.log(selectedYear,year)
        setDoc("")
    }

    let [doc,setDoc] = useState("")
    let [shouldFetch, setShouldFetch] = useState(false);
    
    
    if (selectedTemplate!==""){
        if (shouldFetch === false &&!doc){
            setShouldFetch(true)
        }
    }
    
    useEffect(() => {
        if (shouldFetch && selectedTemplate)  {
            let url = `/detail/${selectedTemplate.uuid}/`
            fetch(process.env.REACT_APP_API + url, {
                headers: {
                    'Authorization': 'JWT ' + localStorage.getItem('DocspointJWT'),
                    'Content-Type': 'application/json'
                },
            })
            .then(response => response.json())
            .then(result => {
                //setDoc(result)
                sessionStorage.setItem("template",JSON.stringify(result)) 
                setShouldFetch(false)
            })
            }
      }, [shouldFetch,selectedTemplate]);
    
    
    const selectTemplateOverwrite = () =>{
        if (selectedTemplate){
            loadFromTemplate(true)
            close()
        }
    }

    const selectTemplateAdd = () =>{
        if (selectedTemplate){
            loadFromTemplate(false)
            close()
        }
    }

    let snonconform = `<br> Účetní rok šablony (${selectedYear})  neodpovídá <br> účetnímu roku cílového dokladu (${year})`;
    

    let onlyOverwrite = false
    if (onlyOverwrite===true || ignoreYear===true){
        if (selectedYear!==year){setSelectedYear(year)}
    }
    

    if (templatesList.length===0){
    }
    else{
        return (
            <div className={styles.ontop}>
            <Modal name={"Vyberte šablonu"} close={close}  >

                <div className={styles.docColumn} style={{ minWidth: '19em' }}>
               
                <div className={styles.column}>
                            <Select
                                name = {'TemplateSelect'}
                                onChange={handleTemplateChange}
                                data={templatesList}
                                noBlankOption={true}
                                >
                                Vyberte šablonu
                            </Select>
                            {
                               (parseInt(selectedYear)!==parseInt(year) && selectedYear!==0) && <div dangerouslySetInnerHTML={{ __html: snonconform }} />
                            }
                            {
                                (onlyOverwrite===false && selectedTemplate && ((parseInt(selectedYear)===parseInt(year) && selectedYear!==0) || ignoreYear===true)) &&  <div style={{ display: 'flex' }}>
                                    <div className={styles.twinbutton} style={{ marginBottom: '0em', paddingBottom: '0.5em' }}>      
                                        <Button onClick={selectTemplateOverwrite} > {"Načíst data"}   </Button>
                                    </div>
                                    <div className={styles.twinbutton} style={{ marginBottom: '0em', paddingBottom: '0.5em' }}>      
                                        <Button onClick={selectTemplateAdd} > {"Doplnit data"}   </Button>
                                    </div>
                                </div>
                            }
                            {
                                (onlyOverwrite===true &&selectedTemplate ) &&  <div >
                                    <div className={styles.bottombutton} style={{ marginBottom: '0em', paddingBottom: '0.5em' }}>      
                                        <Button onClick={selectTemplateOverwrite} > {"Načíst data"}   </Button>
                                    </div>
                                </div>
                            }
                            
  
                </div>
                </div>
            </Modal>
            </div>
        )
    }

    
    
}; 