import React from 'react';

import {PasswordChange} from 'components/PasswordChange';
import {ProgressSpinner} from 'components/ProgressSpinner';
import {User} from 'components/User';
import {ClientCompanyExport} from 'components/ClientCompanyExport';
import styles from 'css_modules/Profile.module.css';

export const Profile = ({user,clientCompanies, ...props}) => {
    if (!user) {
        return <ProgressSpinner/>
    }
    
    return (
        <section className={styles.container}>
            <h1>Uživatelský účet</h1>
            <User user={user} {...props}/>
            <PasswordChange {...props}/>
            {user["role"] === "OrganizationUnitAdmin" && <ClientCompanyExport user={user} clientCompanies={clientCompanies} />
}
        </section>
    )
};