import React from 'react';

import {useToast} from 'helpers/hooks';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import {request} from 'helpers/tools';
import styles from 'css_modules/SaveModal.module.css';

export const MergeModal = ({close,clickedMerge, clickedClose,update,doc,docs,selectedDocs}) => {

const index = selectedDocs.indexOf(String(doc.uuid));
if (index !== -1) {
    selectedDocs.splice(index, 1);
}
    
const showToast = useToast();
const merge = async  => {
    close();

    

    request('/add_as_attachments/', 'POST',
     {"document":String(doc.uuid), "attachments":[...selectedDocs]})
     .then(response=> {
        if (response && String(response.status)===String(200)){
            showToast('Úspěsně sloučeno');
            window.location.reload();
        }
        else{
            showToast('Chyba při slučování dokumentů.');
        }
     })
   
};


console.log(docs.data.results)

const filteredData = docs.data.results.filter(item => selectedDocs.includes(item.uuid)).map(item => item.document_info.state);  


const hasImpState = filteredData.includes('imp'); 

        return (
            <div className={styles.ontop}>
                <Modal name={'Sloučit doklady'} close={close}>
                
                
                {hasImpState===false &&
                    <div className={styles.column}><br></br>Sloučit vybrané doklady do tohoto dokladu?</div>
                }
                {hasImpState===true &&
                    <div className={styles.column}><br></br>Nelze připojit doklady ve stavu "Zaúčtováno". Došlo by k jejich smazání.<br></br><br></br>
                        Zaúčtované doklady: 
                        {docs.data.results
                            .filter(item => selectedDocs.includes(item.uuid) && item.document_info.state === "imp")
                            .map(item => (
                                <li key={item.uuid}>
                                    {item.document_info.document_identifier ? item.document_info.document_identifier : item.uuid}
                                </li>
                        ))}
                    </div>
                }
                <div className = {styles.buttons} >
                
                <div className={styles.button}>      
                    <Button onClick={close} >Storno</Button>  
                    </div>
                    

                    {hasImpState===false &&
                        <div className={styles.savebutton}>      
                        <Button onClick={merge} primary={true} >Sloučit</Button>  
                        </div>
                    }


                
                </div>
                    
                    
                </Modal>
            </div>
        )
    
    
}; 