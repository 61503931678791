import React from 'react';

import {useToast} from 'helpers/hooks';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import {request} from 'helpers/tools';
import styles from 'css_modules/Modal.module.css';

export const RemoveFileModal = ({close, doc, file, refresh, setFile}) => {
    const showToast = useToast();

    const attachment = doc.attachments.find(attachment => attachment.url === file);
    const message = `Smazat přílohu ${attachment.filename}?`;

    const remove = async () => {
        setFile(doc.file_url);
        close();
        const url =`/${doc.is_active ? 'my_documents' : 'archive'}/${doc.uuid}/delete_attachment/`;
        await request(url, 'POST', {id: attachment.id});
        refresh();
        showToast('Příloha byla smazána');
    };

    return (
        <div className={styles.ontop}>
        <Modal closeOnClickOutside name={doc.name} close={close}>
            <p className={styles.body}>{message}</p>
            <div className={styles.buttons}>
                <Button onClick={close}>Zavřít</Button>
                <Button primary onClick={remove}>Smazat</Button>
            </div>
        </Modal>
        </div>
    )
};