import React from 'react';
import {Form, Formik} from 'formik';

import {useToast} from 'helpers/hooks';
import {Button} from 'components/Button';
import {Input} from 'components/Input';
import {Modal} from 'components/Modal';
import {request} from 'helpers/tools';
import styles from 'css_modules/Modal.module.css';

export const RejectModal = ({close, doc, update}) => {
    const showToast = useToast();

    const reject = async rejectNote => {
        close();
        const response = await request(`/my_documents/${doc.uuid}/reject/`, 'POST', rejectNote);

        
        let toastContent = ''
        if (Boolean(response.body.returnedTo)===false ){
            toastContent = 'Doklad není komu vrátit.'
            showToast(toastContent);
        }
        else{
            let returnedToUser = response.body.returnedTo
            toastContent = 'Doklad vrácen: ' + response.body.returnedTo

            if (returnedToUser === "maintenance"){toastContent='Doklad vrácen obslužnému uživateli'}
            if (returnedToUser === "rejected"){toastContent='Doklad vrácen'}

            
            showToast(toastContent);
            update()
        }

        
        
    };

    return (
        <div className={styles.ontop}>
            <Modal name={doc.name} close={close}>
                <Formik initialValues={{reject_note: ''}} onSubmit={values => reject(values)}>
                    <Form>
                        <Input name="reject_note" component="textarea" rows="8" className={styles.body}>
                            Komentář k vrácení
                        </Input>
                        <div className={styles.buttons}>
                            <Button onClick={close}>Zavřít</Button>
                            <Button primary type="submit">Vrátit</Button>
                        </div>
                    </Form>
                </Formik>
            </Modal>
        </div>
    )
};