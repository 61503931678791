import {React,useState} from 'react';
import {Formik} from 'formik';
import {Button} from 'components/Button';
import {Input} from 'components/Input';
import {Select} from 'components/Select';
import styles from 'css_modules/ClientCompanyExport.module.css';

import {DatePicker} from 'components/DatePicker';
import 'react-datepicker/dist/react-datepicker.css';

import {FieldsWrapper} from 'components/FieldsWrapper';
export const ClientCompanyExport = ({user,clientCompanies, ...props}) => {


    const [selectedCompanyId, setSelectedCompanyId] = useState("");
    const [buttonTitle, setButtonTitle] = useState("Stáhnout zálohu");
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [busy, setBusy] = useState(false);
    
    const companiesWithIds = clientCompanies.map(company => ({
        id: company.id,
        title: company.title
      }))
  
    if (!selectedCompanyId){
        setSelectedCompanyId(companiesWithIds[0].id)
    }
    
    const generateArchvieTitle = () => {
        
        let archiveTitle = "export.zip"
        
        return archiveTitle
    }

    const handleCompanySelection = event => {
        setSelectedCompanyId(event.target.value)
    }
    
    

    function download(blob) {
        setButtonTitle("Stáhnout zálohu")
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download =     generateArchvieTitle() 
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setBusy(false)
      }
      
    const  doFetchDownload = async () => {
        setButtonTitle("Generuji data...")
        setBusy(true)
         fetch(process.env.REACT_APP_API + '/get_client_company_export/?client_company='+selectedCompanyId+'&dateFrom='+formatDate(dateFrom)+'&dateTo='+formatDate(dateTo)+'&', {
            method: 'GET',
            headers: {
                'Authorization': 'JWT ' + localStorage.getItem('DocspointJWT')
            }
          }).then((response) => {
            response.blob().then(blob => download(blob))})
        
    }

      
    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
    
    const today = new Date();
    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    
    if (dateFrom===""){setDateFrom((oneYearAgo))}
    if (dateTo===""){setDateTo((today))}
    

    const handleChangeDateFrom = event => {
        setDateFrom((event))
    }

    const handleChangeDateTo = event => {
        setDateTo((event  ))
    }

    
    return (
      <>
        <br></br><br></br>
        <h2>Export dokladů</h2>
        <div class={styles.formContainer}>
            <div className={styles.field}>
            <Formik initialValues={{ }}>
                {({ values }) => (
                    <FieldsWrapper columns={3}>
                    <Input
                        name="companies"
                        component={Select}
                        data={companiesWithIds}
                        notNull={true}
                        onChange={handleCompanySelection}
                    >
                        Klientská společnost
                    </Input>
                    <Input
                        name="exfrom"
                        component={DatePicker}
                        selected={dateFrom}
                        customOnChange={handleChangeDateFrom}
                    >
                        Od data
                    </Input>
                    
                    <Input
                    name="exto"
                    component={DatePicker}
                    selected={dateTo}
                    customOnChange={handleChangeDateTo}
                >
                    Do data
                </Input>
                </FieldsWrapper>
                )}
                </Formik>
                </div>
            <div className={styles.button}>
            </div>
        </div>
            
        <div style={{ textAlign: 'right' }}>
            <Button onClick={doFetchDownload} disabled={busy}>{buttonTitle}</Button>
        </div>
    
        </>    
    
    )
};