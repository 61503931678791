import React from 'react';

import {AuthForm} from 'components/AuthForm';
import {Input} from 'components/Input';
import {request} from "helpers/tools";

export const Login = ({history}) => {
    const authProps = {
        heading: 'Přihlášení',
        button: 'Přihlásit se',
        anchor: 'Ztráta hesla',
        href: '/auth/reset',
        initialValues: {
            username: '',
            password: '',
            device_id: 'web'
        },
        handleSubmit: async (values, {setStatus}) => {
            const loginResponse = await request('/auth/login/', 'POST', values, false);
            if (!loginResponse.ok) {
                return setStatus(loginResponse.body.detail)
            }
            const refreshResponse = await request('/auth/refresh/', 'POST', {
                device_id: 'web',
                refresh_token: loginResponse.body.refresh_token
            });
            localStorage.setItem('DocspointJWT', refreshResponse.body.access_token);
            history.push('/my_documents');
        }
    };


    return (
        <AuthForm {...authProps}>
            <Input required name="username" type="text" autoComplete="username">Uživatelské jméno</Input>
            <Input required name="password" type="password" autoComplete="current-password">Heslo</Input>
        </AuthForm>
    )
};