import React from 'react';
import {Form, Formik} from 'formik';

import {useToast} from 'helpers/hooks';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import {request} from 'helpers/tools';
import styles from 'css_modules/TemplateModal.module.css';
import {Input} from 'components/Input';

export const TemplateModal = ({close, doc, update}) => {
    const showToast = useToast();
    
    const astemplate = async templateName => {
        
        if (templateName['template_name']===''){
            templateName['template_name']='Nová šablona'
            //showToast('Zadejte jméno šablony')
            //return
        }
        close();
        request('/as_template/', 'POST',
         {"document":String(doc.uuid), "templateName":templateName['template_name']})
         .then(response=> {
            if (response && response.body===true){
                showToast('Šablona "' + templateName['template_name'] +'" vytvořena');
                window.location.href = 'my_templates';
            }
            if (response &&response.body===false){
                showToast('Šablona již existuje');
            }
         })
       
    };
    
    
    return (
        <div className={styles.ontop}>
            <Modal name={"Přidat mezi šablony"} close={close}>
                <div className={styles.docColumn}><h4>Název šablony</h4>
                <Formik initialValues={{template_name: ''}} onSubmit={values => astemplate(values)}>
                    <Form>
                    <Input name="template_name" className={styles.body} placeholder={'Nová šablona'}>
                    </Input>
                    
                    
                        <h4> Z dokumentu: </h4>
                        {doc.uuid}
                        
                        
                        <div className={styles.buttons}>
                            <Button onClick={close}>Zavřít</Button>
                            <Button primary type="archive">Přidat</Button>
                        </div>
                    </Form>
                </Formik>
                </div>
            </Modal>
        </div>
    )
};