import React from 'react';

import styles from 'css_modules/FieldsWrapper.module.css';

export const FieldsWrapper = ({columns, children}) => {
    const getClassName = () => {
        if (columns === 2) return styles.two;
        if (columns === 3) return styles.three;
        if (columns === 4) return styles.four;
    };

    return (
        <div className={getClassName()}>
            {children}
        </div>
    )
};