import React from 'react';
import {Form, Formik} from 'formik';

import {useToast} from 'helpers/hooks';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import {request} from 'helpers/tools';
import styles from 'css_modules/Modal.module.css';

export const ArchiveModal = ({close, doc, update}) => {
    const showToast = useToast();

    const reject = async () => {
        close();
        await request(`/my_documents/${doc.uuid}/archive/`, 'POST');
        showToast('Dokument byl archivován.');
        update()
    };

    return (
        <div className={styles.ontop}>
            <Modal name={"Archivovat dokument"} close={close}>
                <Formik initialValues={{reject_note: ''}} onSubmit={values => reject(values)}>
                    <Form>
                    <div className={styles.body}>{doc.name}</div>
                        <div className={styles.buttons}>
                            <Button onClick={close}>Zavřít</Button>
                            <Button primary type="archive">Archivovat</Button>
                        </div>
                    </Form>
                </Formik>
            </Modal>
        </div>
    )
};