import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useFormikContext} from 'formik';

import {useToast} from 'helpers/hooks';
import {Button} from 'components/Button';
import {DuplicateTableRow} from 'components/DuplicateTableRow';
import {Modal} from 'components/Modal';
import {nullifyEmptyValues, stringifyNullValues, preProcessCosts, preProcessItems, request} from 'helpers/tools';
import styles from 'css_modules/DuplicateModal.module.css';

export const DuplicateModal = ({duplicates, forwarding, setDuplicates, setForwarding, update}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const history = useHistory();
    const {uuid} = useParams();

    const {submitForm, validateForm, values} = useFormikContext();

    const showToast = useToast();

    const message = `Vyplněné údaje jsou shodné s ${duplicates.length > 1 ? 'jinými doklady' : 'jiným dokladem'}:`
    let buttonText = forwarding ? 'Odeslat' : 'Uložit';

    const close = () => {
        setForwarding(false);
        setDuplicates([]);
    }
    const forceSave = async () => {
        setIsSubmitting(true)
        await validateForm();
        if (forwarding) {
            if (sessionStorage.getItem("userforwardtoggle")==="0"){
                console.log('Old duplicate modal save')
                await submitForm();
                const response = await request('/my_documents/forward/?force=true', 'POST', [uuid]);
                if (response.body[0].missing_fields.length) {
                    showToast('Vyplňte povinná pole:', response.body[0].missing_fields)
                    close();
                } else {
                    showToast('Odesláno');
                    setForwarding(false);
                    history.push('/my_documents');
                }
            }
            else if (sessionStorage.getItem("userforwardtoggle")==="1"){
                //console.log('Proceeding to new duplicate modal save')
                sessionStorage.setItem("forceforward", "2");
                close();
            }
            
            /**/
        } else {
            let outputData = {
                ...values,
                costs: nullifyEmptyValues(preProcessCosts(values.costs)),
                items: stringifyNullValues(preProcessItems(values))
            }

            for (let i = 0; i < outputData.items.length; i++){
                if (outputData.items[i].guarantee === '' ){ outputData.items[i].guarantee = 0; }
                if (outputData.items[i].reverse_charge === '' ){ outputData.items[i].reverse_charge = false; }
                if (outputData.items[i].expiration_date === '' ){ outputData.items[i].expiration_date = null; }
            }

            console.log('Saving data:')
            console.log(outputData)
            const response = await request(`/detail/${uuid}/?force=true`, 'PUT', outputData );
            

            if(response.ok){
                showToast('Dokument byl uložen');
                update();
            }else{
                showToast('Dokument nebyl uložen');// should happen only in case of network error
            }
            setIsSubmitting(false);
            close();
            window.location.reload();
        }
    };

    return (
        <div className={styles.ontop}>
        <Modal name="Duplicitní záznam" close={close}>
            <main className={styles.body}>
                <p>{message}</p>
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <th>
                            ID
                        </th>
                        <th>
                            Stav
                        </th>
                        <th>Poslední úprava</th>
                    </tr>
                    </thead>
                    <tbody>
                    {duplicates.map(duplicate => <DuplicateTableRow key={duplicate.uuid} duplicate={duplicate}/>)}
                    </tbody>
                </table>
                <p>Chcete přesto pokračovat?</p>
            </main>
            <footer className={styles.buttons}>
                <Button onClick={close}>Zrušit</Button>
                <Button primary disabled={isSubmitting} onClick={forceSave}>{buttonText}</Button>
            </footer>
        </Modal>
        </div>
    )
};