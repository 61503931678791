import React from 'react';

import {HistoryTableRow} from 'components/HistoryTableRow';
import styles from 'css_modules/HistoryTable.module.css';

export const HistoryTable = ({logs}) => {
    const actionEvents = ['crt', 'rea', 'asg', 'fil', 'apr', 'sbm', 'rej', 'imp', 'err', 'rem', 'eml', 'uns','crtitm','remitm','chaut'];

    let highlighted = false;

    return (
        <table className={styles.table}>
            <thead>
            <tr>
                <th></th>
                <th>Událost</th>
                <th>Čas</th>
                <th>Autor</th>
                <th></th>
            </tr>
            </thead>
            <tbody className={styles.body}>
            {logs && logs.map((log, index) => {
                let highlight = false;
                if (actionEvents.includes(log.event.id) && !highlighted) {
                    highlight = true;
                    highlighted = true;
                }
                return <HistoryTableRow key={index} log={log} highlight={highlight}/>
            })}
            </tbody>
        </table>
    )
};