import React from 'react';

//import {ReactComponent as ArrowUpIcon} from 'assets/svg/arrow_up.svg';
import styles from 'css_modules/HistoryDetail.module.css';

export const HistoryDetail = ({log}) => {
        const changes = JSON.parse(JSON.stringify(log.data));
        const getDetailHeaderText = () => {
            switch (log.event.id) {
                case 'rej':
                    return 'Komentář';
                case 'imp':
                case 'chn':
                    return 'Číslo v účetním systému';
                case 'err':
                    return 'Zpráva o chybě';
                case 'crt':
                    return 'ID dokladu';
                case 'upl':
                    return 'Název souboru';
                case 'tts':
                    return 'Časové razítko';
                case 'aat':
                    return 'Název souboru';
                case 'dat':
                    return 'Název souboru';
            	case 'watchdocs':
        	        return 'ID aplikace';
    	        case 'mobile':
 	                return 'ID aplikace';
                case 'ttsaat':
                    return 'Časové razítko (příloha)';
                case 'isdoc':
                    return 'Vyplnění údaje z ISDOC dokladu';
                case 'isdcst':
                    return 'Vyplnění nákladů z ISDOC dokladu'
                case 'remitm':
                    return 'Položka smazána'
                case 'chaut':
                    return 'Uživatelský typ dokladu'    
                case 'eml':
                    return 'Email';
                case 'tpl':
                    return 'Zdrojový doklad';
                default:
                    throw new Error(`Unreachable: ${log.event.id}`);
            }
        };
        const getChangeRowClassName = index => changes.length === index + 1 ? styles.changeBottom : styles.changeRow;
        /*const getCostsRowClassName = (index) => {
            if (log.data.length === index + 1){
                if (log.data[0].length === 4) return styles.costsBottom;
                else return styles.costsBottomShort;
            }
            else {
                if (log.data[0].length === 4) return styles.costsRow;
                else return styles.costsRowShort;
            }
        } ;*/
        const renderChanges = (field, index) => (
            <tr key={index} className={getChangeRowClassName(index)}>
                <td>{field[0]}:</td>
                <td className={styles.greyText}>{log.data[0][0] === 'Přenesená daňová povinnost' ? (field[1] ? 'ANO' : 'NE') : field[1]}</td>
                <td className={styles.greyText}>{log.data[0][0] === 'Přenesená daňová povinnost' ? (field[2] ? 'ANO' : 'NE') : field[2]}</td>
            </tr>
        );

        const renderItemChanges = (field, index) => (
            ( (String(field[0])==="_meta_Text"  || !String(field[0]).startsWith("_meta") ) &&
            (field[0] === 'id' ? null :
            (<tr key={index} className={getChangeRowClassName(index)}>
                <td>{(String(field[0])==="_meta_Text" ? "Původní název" : field[0])}:</td>
                <td className={styles.greyText}>{(field[1] === true ? 'ANO' : field[1] === false ? 'NE': field[1]    )}</td>
                <td className={styles.greyText}>{(field[2] === true ? 'ANO' : field[2] === false ? 'NE': field[2]    )}</td>
            </tr>)))
        );

        const renderItemCreated = (field, index) => (
            field[0] === 'id' ? null :
            (   field[1] ===""? null:(            
                field[1] ===null? null:( 
                <tr key={index} className={getChangeRowClassName(index)}>
                    <td>{field[0]}:</td>
                    <td className={styles.greyText}>{'---'}</td>
                    <td className={styles.greyText}>{(field[1] === true ? 'ANO' : field[1] === false ? 'NE': field[1]    )}</td>
                </tr>)
            ))
        );

        const renderItemRemoved = (field, index) => (
            field[0] === 'id' ? null :
            (   field[1] ===""? null:(            
                field[1] ===null? null:( 
                <tr key={index} className={getChangeRowClassName(index)}>
                    <td>{field[0]}:</td>
                    <td className={styles.greyText}>{(field[1] === true ? 'ANO' : field[1] === false ? 'NE': field[1]    )}</td>
                    <td className={styles.greyText}>{''}</td>
                </tr>)
            ))
        );
        /*
        const renderCosts = (cost, index) => {
            // check if this is old format used before 21/2/2021 (to be deleted here + BE when no longer relevant)
            const isOldFormat = typeof cost[0] === 'string'
            const renderValue = (value, i) => {
                const isEqual = value[0] === value[1];
                const parsedRecords = value.map(record => record === '' ? '–––' : record);
                const cellClassName = isEqual ? styles.costValue : styles.costValueChanged;

                return (
                    <td key={i} className={cellClassName}>
                        {isEqual ? parsedRecords[0] : (
                            <>
                                <span>{parsedRecords[0]}</span>
                                <ArrowUpIcon className={styles.arrowIcon}/>
                                <span>{parsedRecords[1]}</span>
                            </>
                        )}
                    </td>
                )
            }

            return (
                <tr key={index} className={getCostsRowClassName(index)}>
                    <td>{index + 1}</td>
                    {isOldFormat
                        ? (
                            <>
                                <td className={styles.greyText}>{cost[0] || '–––'}</td>
                                <td className={styles.greyText}>{cost[1] ? cost[1].replace('.', ',') : '–––'}</td>
                                <td className={styles.greyText}>{cost[2] ? cost[2].replace('.', ',') : '–––'}</td>
                            </>
                        )
                        : cost.map(renderValue)
                    }
                </tr>
            )
        };
        */
        switch (log.event.id) {
            case 'cha':
            case 'isdoc':
            case 'chaitm':
                return (
                    <>
                        <tr className={styles.changeHeader}>
                            <td></td>
                            <td>Původní hodnota</td>
                            <td>Nová hodnota</td>
                        </tr>
                        {changes.map(renderItemChanges)}
                    </>
                );
            case 'crtitm':
                return (
                    <>
                        <tr className={styles.changeHeader}>
                            <td></td>
                            <td>Původní hodnota</td>
                            <td>Nová hodnota</td>
                        </tr>
                        {changes.map(renderItemCreated)}
                    </>
                );
                case 'remitm':
                return (
                    <>
                        <tr className={styles.changeHeader}>
                            <td></td>
                            <td>Hodnota</td>
                            <td></td>
                        </tr>
                        {changes.map(renderItemRemoved)}
                    </>
                );
           case 'chacpt':
                return (
                    <>
                        <tr className={styles.changeHeader}>
                            <td></td>
                            <td>Původní hodnota</td>
                            <td>Nová hodnota</td>
                        </tr>
                        {changes.map(renderChanges)}
                    </>
                );
            case 'isdcst':
            case 'cst':
            case 'cstcpt':
            case 'chaut':
                return (
                    <>
                        <tr className={styles.changeHeader}>
                            <td></td>
                            <td>Původní hodnota</td>
                            <td>Nová hodnota</td>
                        </tr>
                        {changes.map(renderChanges)}
                    </>
                );
            
            default:
                return (
                    <>
                        <tr className={styles.header}>
                            <td>{getDetailHeaderText()}</td>
                        </tr>
                        <tr className={styles.bottom}>
                            <td>{log.data}</td>
                        </tr>
                    </>
                );
        }
    }
;