import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import {Login} from 'components/Login'
import {LostPassword} from 'components/LostPassword';
import {PasswordReset} from 'components/PasswordReset';
import styles from 'css_modules/Welcome.module.css';
import logo from 'assets/png/logo_light.png';

export const Welcome = props => {
    return (
        <main className={styles.container}>
            <section className={styles.leftBox}>
                <div className={styles.form}>
                    <Router>
                        <Switch>
                            <Route exact path="/auth/login">
                                <Login {...props}/>
                            </Route>
                            <Route exact path="/auth/reset">
                                <LostPassword/>
                            </Route>
                            <Route path="/auth/reset/:uid/:token">
                                <PasswordReset/>
                            </Route>
                        </Switch>
                    </Router>
                </div>
            </section>
            <section className={styles.rightBox}>
                <div className={styles.welcome}>
                    <img src={logo} alt="logo" className={styles.logo}/>
                    <h2 className={styles.welcomeHeadingTop}>Přihlásit se do</h2>
                    <h2 className={styles.welcomeHeadingBottom}>Docspoint</h2>
                    <p className={styles.welcomeText}>
                        PŘEJÍT NA WEB <a href="https://docspoint.cz">DOCSPOINT.CZ</a>     
                    </p>
                </div>
            </section>
        </main>
    )
};