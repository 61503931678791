import React from 'react';

import styles from 'css_modules/MetadataRow.module.css';

export const MetadataRow = ({property, value}) => {
    return (
        <div className={styles.wrapper}>
            <dt className={styles.property}>{property}</dt>
            <dd className={styles.value}>{value}</dd>
        </div>
    )
};