import React, {useState} from 'react';

import {AuthForm} from 'components/AuthForm';
import {Input} from 'components/Input';
import {request} from "helpers/tools";

export const LostPassword = () => {
    const [messages, setMessages] = useState(null);

    const authProps = {
        messages: messages,
        info: 'Na zadaný email obdržíte pokyny pro obnovení hesla.',
        heading: 'Ztráta hesla',
        button: 'Odeslat email',
        anchor: 'Zpět na přihlášení',
        href: '/auth/login',
        initialValues: {
            email: ''
        },
        handleSubmit: async values => {
            await request('/rest-auth/password/reset/', 'POST', values, true);
            setMessages([
                'Návod na nastavení hesla byl odeslán na zadanou e-mailovou adresu, pokud účet s takovou adresou ' +
                'existuje. Měl by za okamžik dorazit.',
                'Pokud e-mail neobdržíte, ujistěte se, že zadaná e-mailová adresa je stejná jako ta registrovaná ' +
                'u vašeho účtu a zkontrolujte složku nevyžádané pošty, tzv. spamu.'
            ])
        }
    };

    return (
        <AuthForm {...authProps}>
            <Input required type="email" name="email" autoComplete="email">Email</Input>
        </AuthForm>
    )
};