import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import 'react-dropzone-uploader/dist/styles.css';
import DropzoneCustom from "./DropzoneCustom";

import {request} from 'helpers/tools';
import {useToast} from 'helpers/hooks';
import {ReactComponent as UploadIcon} from 'assets/svg/upload.svg';
import {ReactComponent as FileIcon} from 'assets/svg/file.svg';
import {ReactComponent as CancelIcon} from 'assets/svg/cancel.svg';
import {ReactComponent as CheckIcon} from 'assets/svg/check.svg';
import {ReactComponent as EditIcon} from 'assets/svg/edit.svg'
import styles from 'css_modules/Upload.module.css'


let newuuids = []


const Layout = ({input, previews, dropzoneProps, files}) => {
    
    if (newuuids &&newuuids.length>0){
        sessionStorage.setItem("newUuids", newuuids.join())
    }
    
    return (
        <section className={styles.wrapper}>
            <figure className={styles.dropzoneBox}>
                <div {...dropzoneProps}>
                    {input}
                </div>
                <figcaption className={styles.hint}>
                    <span className={styles.hintSymbol}>?</span>
                    <span className={styles.hintText}>
                        Doklad je také možné nahrát prostřednictvím emailové adresy či mobilní aplikace.
                    </span>
                </figcaption>
            </figure>
            <div className={styles.progressBox}>
                {files.length > 0 && <h3>Nahrávané soubory</h3>}
                {previews}
            </div>
        </section>
    )
};

const cloneByTemplate = (newuuid) => {
    let json = JSON.parse(sessionStorage.getItem("template"))
    let cloneParams =  {"target_uuid":newuuid, "source_uuid": json["templateuuid"]}
    request('/load_from_template/', 'POST',cloneParams)
    
    sessionStorage.setItem("template",undefined)
}

const Preview = (e) => {
    const {name, percent, size, status} = e.fileWithMeta.meta;
    const cancel = e.fileWithMeta.cancel;
    const isError = ['error_upload', 'error_file_size'].includes(status);
    const errorMessage = status === 'error_upload'
        ? 'Soubor se nepodařilo nahrát.'
        : 'Soubor je příliš velký. Maximální velikost je 30MB.';
    const fileNameClassName = status === 'done' ? styles.fileNameDone : styles.fileNameDefault;

    const uuid = (e) => {
        if (typeof e.fileWithMeta.xhr !== 'undefined') {
            if (e.fileWithMeta.xhr.response !== "") {
                return JSON.parse(e.fileWithMeta.xhr.response)['uuid'];
            }
        }
    };
    let newuuid = uuid(e)
    if (!newuuids.includes(newuuid) && newuuid!==undefined){
        newuuids.push(newuuid)
    }
    
    const detail = `/my_documents/${newuuid}/general`;

    const formatBytes = b => {
        const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let l = 0;
        let n = b;

        while (n >= 1024) {
            n /= 1024;
            l += 1
        }

        return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`
    };
    
    if (status==="done"){
        if (String(sessionStorage.getItem("template"))!=='undefined'){
            cloneByTemplate(newuuid)
        }
    }

    return status === 'aborted' ? null : (
        <div className={styles.previewContainer}>
            <FileIcon className={styles.fileIcon}/>
            <div className={styles.progressContainer}>
                <div className={styles.progressRow}>
                    <span className={fileNameClassName}>{name}</span>
                    <span className={styles.fileSize}>{formatBytes(size)}</span>
                    {status === 'done' && (
                        <>
                            <Link to={detail}><EditIcon className={styles.editIcon}/></Link>
                            <CheckIcon className={styles.checkIcon}/>
                        </>
                    )}
                    {status === 'uploading' && <CancelIcon onClick={cancel} className={styles.cancelIcon}/>}
                    {isError && <div className={styles.errorIcon}>!</div>}
                </div>
                <div className={styles.progressPlaceholder}>
                    {status === 'uploading' && <progress max="100" value={percent}/>}
                </div>
                {isError && <p className={styles.errorMessage}>{errorMessage}</p>}
            </div>
        </div>
    )
};

const Content = () => {
    return (
        <React.Fragment key="key">
            <UploadIcon className={styles.uploadIcon}/>
            <h3>Nahrávání souborů</h3>
            <span className={styles.dropzoneCaption}>
                Dokumenty nahrajete přetažením<br/>
                nebo můžete nahrát více souborů kliknutím.<br/><br/>
                Podporované formáty dokumentu: <br></br> PDF, ISDOC
                <br></br> JPG, PNG, TIF, BMP, GIF
            </span>
        </React.Fragment>
    )
};


export const Upload = () => {
        
    const [json, setJson] = useState("");
    const [refresh,setRefresh] = useState(true)
    if (refresh === true){
        setJson( String(sessionStorage.getItem("template"))!=='undefined' ? JSON.parse(sessionStorage.getItem("template")):"" )
        setRefresh(false)
    }
    let templateName = undefined
    if (json){
        if (json["templatename"]==="" || json["templatename"]===undefined){
            templateName = json["templateuuid"]
        }
        else{
            templateName = json["templatename"]
        }
    }
    


    const showToast = useToast();
    const [rerender, setRerender] = useState(false);
    if (rerender === false){
        setRerender(!rerender);
    }
    
    
    

    const getUploadParams = ({file}) => {
        const body = new FormData();
        body.append('document_file', file);
        return {
            url: process.env.REACT_APP_API + '/upload/',
            headers: {'Authorization': 'JWT ' + localStorage.getItem('DocspointJWT')},
            body
        }
    };
    const handleStatusChange = ({meta}) => {
        if (meta.status === 'rejected_file_type') {
            showToast('Nahrajte soubor ve formátu PDF')
        }
    };

    return (
        <>
            {!templateName && <h1>Nahrát soubory</h1>}
            {templateName && <h1>Nahrát soubory (použitá šablona: {templateName})</h1>}
            <DropzoneCustom
                LayoutComponent={Layout}
                PreviewComponent={Preview}
                inputContent={Content}
                inputWithFilesContent={Content}
                getUploadParams={getUploadParams}
                onChangeStatus={handleStatusChange}
                accept="application/pdf, application/xml, application/isdoc,application/octet-stream,"// exemplary files had type = '', but still some of isdocs could be xml or isdoc typed
                maxSizeBytes={31457280}
                classNames={{
                    dropzone: styles.dropzone,
                    inputLabel: styles.inputLabel,
                    inputLabelWithFiles: styles.inputLabel
                }}
                autoUpload={true}
            />
        </>
    )
};