import React from 'react';
import {Form, Formik} from 'formik';

import {useToast} from 'helpers/hooks';
import {Avatar} from 'components/Avatar';
import {Input} from 'components/Input';
import {ProfileFormBody} from 'components/ProfileFormBody';
import {ReactComponent as EditIcon} from 'assets/svg/edit.svg';
import {request} from 'helpers/tools';
import styles from 'css_modules/User.module.css';

export const User = ({setUser, user}) => {
    const showToast = useToast();

    const bodyProps = {
        button: {
            text: 'Uložit změny',
            primary: true
        }
    };

    const handleImageUpload = (event, setFieldValue) => {
        user.avatar = URL.createObjectURL(event.target.files[0]);
        return setFieldValue('avatar', event.target.files[0]);
    };

    return (
        <Formik
            initialValues={{
                first_name: user.first_name || '',
                last_name: user.last_name || '',
                username: user.username || '',
                email: user.email || '',
                telephone: user.telephone || '',
                id: user.pk || '',
                company_title: user.company_title || '',
                country: user.country.title || ''
            }}
            onSubmit={async (values, {setErrors}) => {
                const data = new FormData();
                if (!user.is_admin) {
                    values.company_title = user.company_title;
                }
                Object.entries(values).map(value => data.append(...value));
                const url = '/rest-auth/user/';
                const response = await request(url, 'PATCH', data, false, true);
                if (response.ok) {
                    setUser(response.body);
                    showToast('Změny byly uloženy');
                } else {
                    setErrors(response.body)
                }
            }}
        >
            {({setFieldValue}) =>
                <Form className={styles.form}>
                    <label className={styles.avatarUpload}>
                        <input name="avatar"
                               type="file"
                               accept="image/*"
                               onChange={event => handleImageUpload(event, setFieldValue)}
                               hidden
                        />
                        <div className={styles.avatarContainer}>
                            <Avatar user={user}/>
                        </div>
                        <EditIcon className={styles.editIcon}/>
                        <span className={styles.avatarUploadText}>Změnit profilové foto</span>
                    </label>
                    <ProfileFormBody {...bodyProps}>
                        <Input name="first_name" autoComplete="given-name" required>
                            Jméno
                        </Input>
                        <Input name="last_name" autoComplete="family-name" required>
                            Příjmení
                        </Input>
                        <Input name="username" autoComplete="username" required>
                            Uživatelské jméno
                        </Input>
                        <Input name="telephone" type="tel" autoComplete="tel">
                            Telefon
                        </Input>
                        <Input name="email" type="email" autoComplete="email">
                            Email
                        </Input>
                        <Input name="id"  disabled>
                            ID uživatele
                        </Input>
                        <Input name="country" autoComplete="country" disabled>
                            Země
                        </Input>
                        <Input name="company_title" autoComplete="organization" disabled={!user.is_admin}>
                            Název společnosti
                        </Input>
                    </ProfileFormBody>
                </Form>
            }
        </Formik>
    )
};