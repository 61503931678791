import React from 'react';

import styles from 'css_modules/DocumentsTableHeader.module.css';
import {ReactComponent as ChevronDownIcon} from 'assets/svg/chevron_down.svg'
import {ReactComponent as ChevronUpIcon} from 'assets/svg/chevron_up.svg';

export const DocumentsTableHeader = ({setOrdering, orderBy, ordering, children,selected, parent}) => {
    const ascend = () => setOrdering(orderBy);
    const descend = () => setOrdering(`-${orderBy}`)

    const removeOrdering = () =>
    {
        setOrdering(...ordering,'')
        sessionStorage.setItem("ordering", null);
    } 


    if (!selected){
        selected = 0
    }
    
    return (
        <th>
            {orderBy && <div className={selected!==0? styles.selectedtext : styles.normaltext} >{children}</div>}
            {orderBy && (
                <>
                    <ChevronDownIcon onClick={selected===1? removeOrdering : descend} className={selected===1? styles.selectedicon :styles.icon }/>
                    <ChevronUpIcon onClick={selected===-1? removeOrdering : ascend}  className={selected===-1? styles.selectedicon :styles.icon }/>
                </>
            )}
        </th>
    )
};