import React from 'react';
import {Button} from "./Button";
import {useModal} from "../helpers/hooks";
import {TableSettingsModal} from "./TableSettingsModal";

export const TableSettings = (
    {
        fields, activeFields, setActiveFields, save
    }
) => {
    const [modal, toggleModal] = useModal();

    const saveAndClose = () => {
        toggleModal();
        save();
    }
    return (
        <>
            <Button children={'Nastavení sloupců'} onClick={toggleModal} />
            {modal && <TableSettingsModal close={toggleModal} fields={fields} activeFields={activeFields} setActiveFields={setActiveFields} save={saveAndClose} />}
        </>
    )
}