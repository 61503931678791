import React from 'react';
import {useField} from 'formik';

import styles from 'css_modules/Radio.module.css';

export const Radio = ({label, name, value, ...props}) => {
    const [field, meta, helpers] = useField(name);

    const checked = meta.value === value
        || (meta.value === true && value === 'true')
        || (meta.value === false && value === 'false');

    const handleChange = () => {
        const getValue = () => {
            switch (value) {
                case 'true':
                    return true;
                case 'false':
                    return false;
                default:
                    return value;
            }
        }
        helpers.setValue(getValue())
    }

    return (
        <label className={styles.label}>{label}
            <input
                name={field.name}
                type="radio"
                value={value}
                onChange={handleChange}
                checked={checked}
                {...props}
            />
        </label>
    )
}