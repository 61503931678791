import React from 'react';


import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import {Select} from "./Select";
import styles from 'css_modules/Modal.module.css';

export const TableSettingsModal = ({close, fields, activeFields, setActiveFields, save}) => {
    let options = [];
    Object.keys(fields).forEach((key) => {
        let items = fields[key].items;
        Object.keys(items).forEach((itemKey) => {
            options.push({
                title: fields[key].name + ' - ' + items[itemKey].name,
                id: key + '.' + itemKey
            });
        });
    });

    const selectChange = (e) => {
        let key = e.target.getAttribute('data-input-key');
        let value = e.target.value;

        let fields = [...activeFields];
        fields[key] = value;

        setActiveFields(fields);
    }

    const removeSelect = (id) => {
        let fields = [...activeFields];
        fields.splice(id, 1);

        if (fields.length > 0) {
            setActiveFields(fields);
        }
    }

    const addSelect = () => {
        let fields = [...activeFields];
        fields.push(availableO(options, -1)[0].id);

        setActiveFields(fields);
    }

    const availableO = (options, id) => {
        let unavalible = [...activeFields].filter( (o,i)=> i !== id);
        return  options.filter( o => !unavalible.includes(o.id));
    }

    const items = () => {
        let items = [];
        activeFields.forEach((item, id) => {
            if (item === "template.document_info.template_name"){
                items.push(<div key={'columnDiv' + id} className={styles.columnDiv}>
                    <Select key={'column' + id} data-input-key={id} value={item}
                            data={availableO(options, id)}
                            onChange={selectChange} notNull={true}
                            disabled={true}
                    />
                     <Button children={'Odebrat'} disabled={true} onClick={() => {removeSelect(id)}} />
                </div>);
            }
            else{
                items.push(<div key={'columnDiv' + id} className={styles.columnDiv}>
                    <Select key={'column' + id} data-input-key={id} value={item}
                            data={availableO(options, id)}
                            onChange={selectChange} notNull={true}
                    />
                    <Button children={'Odebrat'} onClick={() => {removeSelect(id)}} />
                </div>);
            }
            
        })
        return items;
    };

    return (
        <div className={styles.ontop}>
        <Modal name={'Nastavení sloupců'} close={close}>
            <div className={styles.scroll}>
                {items()}
                <div className={styles.columnDiv}>
                    {
                        activeFields.length < 10 &&
                        <Button children={'Přidat sloupec'} onClick={addSelect}/>
                    }
                    <Button children={'Uložit'} primary={true} onClick={save} />
                </div>
            </div>
        </Modal>
        </div>
    )
};