import React, { useState } from 'react';

import {useToast} from 'helpers/hooks';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import {request} from 'helpers/tools';
import styles from 'css_modules/Modal.module.css';


export const CaptureModal = ({close, docs, update}) => {
    const showToast = useToast();

    const [pageCount, setPageCount] = useState(-1);
    const [buttonTitle, setButtonTitle] = useState("Vytěžit");
    const [buttonDisabled, setButtonDisabled] = useState(false);

    let selectPages = true
    let message = docs.length > 1 ? 'Vytěžit vybrané dokumenty?' : 'Vytěžit dokument?';

    const [capturedFromIsdoc, setCapturedFromIsdoc] = useState(0);

    let countFromIsdoc = docs.filter(item => item.captured_from_isdoc === true).length;
    if (countFromIsdoc && countFromIsdoc >0){
        if (capturedFromIsdoc!== countFromIsdoc){
            setCapturedFromIsdoc(countFromIsdoc)
        }
    }
    
    if (docs.length>1){selectPages=false}

    let multimessage = ""

    if (docs.length===1 && countFromIsdoc>0){
        message += " Data již byla načtena z ISDOC."
        if (buttonTitle === "Vytěžit"){ setButtonTitle("Přesto vytěžit")}
    }
    else if (docs.length>1 && countFromIsdoc>0){ 
        if (countFromIsdoc===1) { multimessage +=   "Jeden dokument již byl načten z ISDOC:"}
        else if (countFromIsdoc===2) { multimessage +=  "Dva dokumenty již byly načteny z ISDOC:"}
        else if (countFromIsdoc===3) { multimessage +=  "Tři dokumenty již byly načteny z ISDOC:"}
        else if (countFromIsdoc===4) { multimessage +=  "Čtyři dokumenty již byly načteny z ISDOC:"}
        else { multimessage +=  "" + countFromIsdoc + " dokumentů již bylo načteno z ISDOC:"}
        if (buttonTitle === "Vytěžit"){ setButtonTitle("Přesto vytěžit všechny")}
    }

    

    const filteredUuids = docs
    .filter(item => item.captured_from_isdoc === true)
    .map(item => item.document_info?.document_identifier || item.uuid);


    const handleClick = async () => {
        close();
        const url = '/my_documents/capture/';
        
        const data = {
            uuids: docs.map(doc => doc.uuid),
            pageFrom: -1,
            pageTo: -1 
          };
        
          console.log(pageFrom, pageTo)
          if (selectPages === true && pageCount>1 && !(pageFrom===1 && pageTo===pageCount)){
              data["pageFrom"]=outpageFrom
              data["pageTo"]=outpageTo
          }
        
        const response = await request(url, 'POST', JSON.stringify(data));
        if (response.ok) {
            if (response.body){
                showToast('Odesláno k vytěžení (strany '+response.body+")");
                request('/delete_cropped/?uuid='+docs[0].uuid)
            }
            else{
                showToast('Odesláno k vytěžení');
            }
            
            update();
        } else {
            showToast('Došlo k chybě')
        }
         
    };

    const handleClickOnlyEmpty = async () => {
        close();
        const url = '/my_documents/capture/';
        
        const capturedFromIsDocFalse = docs.filter(item => item.captured_from_isdoc === false);

        const data = {
            uuids: capturedFromIsDocFalse.map(doc => doc.uuid),
            pageFrom: -1,
            pageTo: -1 
          };
        
        console.log(pageFrom, pageTo)
        if (selectPages === true && pageCount>1 && pageFrom!==1 && pageTo!==pageCount){
            data["pageFrom"]=outpageFrom
            data["pageTo"]=outpageTo
        }

        const response = await request(url, 'POST', JSON.stringify(data));
        if (response.ok) {
            if (response.body){
                showToast('Odesláno k vytěžení (strany '+response.body+")");
                request('/delete_cropped/?uuid='+docs[0].uuid)
            }
            else{
                if (capturedFromIsDocFalse.length === 1) {
                    showToast('Jeden dokument odeslán k vytěžení');
                } else if (capturedFromIsDocFalse.length >= 2 && capturedFromIsDocFalse.length <= 4) {
                    showToast(capturedFromIsDocFalse.length + ' dokumenty odeslány k vytěžení');
                } else {
                    showToast(capturedFromIsDocFalse.length + ' dokumentů odesláno k vytěžení');
                }
            }
            
            update();
        } else {
            showToast('Došlo k chybě')
        }
    };

  
    const handleSubmit = (e) => {
      e.preventDefault();
    };

        
    if (docs && docs[0] && docs[0].uuid) {
        request('/get_num_pages/?uuid=' + docs[0].uuid)
            .then(response => { 
                setPageCount(response.body);
            })
            .catch(error => {
                // Handle error if necessary
                console.error('Error fetching page count:', error);
            });
    }
    
    const [pageFrom, setPageFrom] = useState(1);
    const [pageTo, setPageTo] = useState(0);

    const [outpageFrom, setoutPageFrom] = useState(1);
    const [outpageTo, setoutPageTo] = useState(0);

    if ( pageTo === 0 && pageCount!==-1 && pageCount!==pageTo ){
        setPageTo(pageCount)
        setoutPageTo(pageCount)
    }
    


    if (selectPages === true && parseInt(pageCount)>1){
        if (pageFrom > pageTo || pageFrom<1 || pageTo<1 || pageTo>pageCount){
            if (buttonDisabled!==true){setButtonDisabled(true)}
        }
        else{
            if (buttonDisabled!==false){setButtonDisabled(false)}
        }

        if (outpageTo>pageCount){
            let s = "Dokument má pouze " +  pageCount + " stran"
            if (buttonTitle!==s) {setButtonTitle(s)}
        }
        else if (parseInt(outpageFrom) === 1 && parseInt(outpageTo) === pageCount){
            if (buttonTitle!=="Vytěžit celý doklad") {setButtonTitle("Vytěžit celý doklad")}
        }
        else if (parseInt(outpageFrom) !== parseInt(outpageTo)){
            let s = "Vytěžit strany " + outpageFrom + " až " +  outpageTo
            if (buttonTitle!==s) {setButtonTitle(s)}
        }
        else{
            let s = "Vytěžit stranu " + outpageFrom 
            if (buttonTitle!==s) {setButtonTitle(s)}
        }
    }
    
      
    return (
        <div className={styles.ontop}>
        <Modal name={docs.length === 1 ? docs[0].name : 'Vytěžit metadata'} close={close}>
            <p className={styles.body}>
                {message}
                {multimessage!=="" && (
                    <div>
                    <br></br>
                    {multimessage}
                    {filteredUuids.map((uuid, index) => (
                        <p key={index}>• {uuid}</p>
                    ))}
                    </div>
                )}
            </p>

            { (selectPages ===true && parseInt(pageCount)>1)&&
            <div className={styles.body}>
                
                <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '10px' }}>Od strany:</label>
                    <input
                        type="number"
                        value={pageFrom}
                        onChange={(e) => {
                            let value = parseInt(e.target.value);
                                if (value===0){value=1}
                                setPageFrom(value);
                                if (value <= parseInt(pageCount) && value >= 1) {
                                    setoutPageFrom(value)
                                }
                            }}
                        required
                        style={{ marginRight: '10px', width: '50px' }}
                    />
                    <label style={{ marginRight: '10px' }}>do strany</label>
                    <input
                        type="number"
                        value={pageTo}
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                                setPageTo(value);
                                if (value <= parseInt(pageCount) && value >= 1) {
                                    setoutPageTo(value)
                                }
                            }}
                        required
                        style={{ marginRight: '10px', width: '50px' }}
                    />
                    </form>
            </div>
            }


            <div className={styles.buttons}>
                <Button onClick={close}>Zavřít</Button>
                { (countFromIsdoc>0 &&docs.length>1)  && <div style={{ marginLeft: '10px', marginRight: '10px' }}><Button primary disabled={buttonDisabled}   onClick={handleClickOnlyEmpty}>{"Pouze ty bez ISDOC"}</Button></div>}
                <Button primary disabled={buttonDisabled} onClick={handleClick}>{buttonTitle}</Button>
               
            </div>
        </Modal>
        </div>
    )
};