import React, {useState} from 'react';
import {useFetch} from 'helpers/hooks';
import {useFormikContext} from 'formik';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import styles from 'css_modules/ForwardPickerModal.module.css';
import {Select} from 'components/Select';

export const ForwardPickerModal = ({close, body,user, clicked,clickedDirectForward,forward,miscellaneousDocType,directForceForward,docsInfos}) => {
    const selectedManager = useFormikContext();
    const selectedMaintenance = useFormikContext();
    const assignable_users = useFetch('/get_assignable_users/?documents='+[...body])
    let toggle_direct_forward_to_accountant =  String(user["toggle_direct_forward_to_accountant"])==="true" ? true:false

    
    let [selectedManagerId, setSelectedManagerId] = useState (-1)
    let [selectedMaintenanceId, setSelectedMaintenanceId] = useState (-100)
    
    let [managerButtonTitle,setManagerButtonTitle] = useState("")
    let [maintenanceButtonTitle,setMaintenanceButtonTitle] = useState("")
   
    let management_users = []
    let maintenance_users = []
    let management_users_list = []
    let maintenance_users_list = []
    let docsToApproval_noTree = []
    let docsToApproval_withTree = []
    let docsWithoutApproval = []
    
    if (assignable_users.data){ 
        management_users = assignable_users.data["assignable_users"]["management_users"]
        maintenance_users = assignable_users.data["assignable_users"]["maintenance_users"]

        docsToApproval_noTree = assignable_users.data["documents_with_approval_required"]["documents_without_approval_tree"]
        docsToApproval_withTree = assignable_users.data["documents_with_approval_required"]["documents_with_approval_tree"]
        docsWithoutApproval = assignable_users.data["documents_without_approval_required"]
    }
    let infos_docsToApproval_noTree = []
    let infos_docsToApproval_withTree = []
    let infos_docsWithoutApproval = []
    
    body.forEach( (id,i)=> {
        
        if (docsInfos.length>0){
            let tit = ""
            if (docsInfos[i]["number"]!==""){
                tit +=docsInfos[i]["number"]
            }
            if (docsInfos[i]["supplier_title"]!==""){
                if (tit !==""){tit+=", "}
                tit +=docsInfos[i]["supplier_title"]
            }
            if (tit===""){
                tit = id
            }
            
            docsToApproval_noTree.forEach(d => {
 
                if (d.includes(id)){
                    infos_docsToApproval_noTree.push(tit)
                }
            })
          
            docsToApproval_withTree.forEach(d => {
                console.log(d)
                if (d["document"].includes(id)){
                    if (d["next_user_name"] !== undefined && d["next_user_name"]!==null){
                        tit += ' → '+ d["next_user_name"] +' (Úr. '+d["next_management_level"]+ ')'
                    }
                    else if (d["next_management_level"]){
                        tit += ' → Kdokoliv (Úr. '+d["next_management_level"]+ ')'
                    }
                    else{
                        tit += ' → Kdokoliv (účetní)'
                    }
                    
                    infos_docsToApproval_withTree.push(tit)
                }
            })

            docsWithoutApproval.forEach(d => {
                if (d.includes(id)){
                    infos_docsWithoutApproval.push(tit)
                }
            })
          
        }
        else{
            docsToApproval_noTree.forEach(d => {
                if (d.includes(id)){
                    infos_docsToApproval_noTree.push(d)
                }
            })
            
            docsToApproval_withTree.forEach(d => {
                if (d["document"].includes(id)){
                    infos_docsToApproval_withTree.push(d["document"])
                }
            })

            docsWithoutApproval.forEach(d => {
                if (d.includes(id)){
                    infos_docsWithoutApproval.push(d)
                }
            })
        }
        

        }
    )
    
    
    let maxlevel = 0
    let minlevel = 100
    if (management_users.length>0){
        let levels = []
        
        for (let i = 0; i<management_users.length; i++){
            let l = management_users[i]["management_level"]
            if (!levels.includes(l)){
                levels.push(parseInt(l) )
                if (l>maxlevel){
                    maxlevel = l
                }
                if (l<minlevel){
                    minlevel = l
                }
            }
        }
        
        levels = levels.sort()

        
        levels.forEach(l=>{
                management_users_list.push({
                    id : (-l*10),
                    title : "———————— Úroveň " +l +" ———————— ",
                    name : "► Kdokoliv",
                    level : l,
                    off: "true"
                })
                management_users_list.push({
                    id : (-l),
                    title : "► Kdokoliv",
                    name : "► Kdokoliv",
                    level : l
                })
                if (managerButtonTitle===""){
                    setManagerButtonTitle( 'Úroveň ' + management_users_list[1]["level"] + ": "    + management_users_list[1]["name"].substr(2))
                }
                

                for (let i = 0; i<management_users.length; i++){
                    let u = management_users[i]
                    let ml = u["management_level"]
                    if (ml===l){
                        management_users_list.push({
                            //id : parseInt(u["management_user_id"]),
                            id : parseInt(u["user_id"]),
                            title : '● '+u["first_name"]+' '+u["last_name"],
                            name : '● ' +u["first_name"]+' '+u["last_name"],
                            level : parseInt(u["management_level"])
                        })
                    }
                }
            }
        )
    }
    
    if (maintenance_users.length>0){
        for (let i = 0; i<maintenance_users.length; i++){
            let u = maintenance_users[i]
            maintenance_users_list.push({
                    //id : parseInt(u["maintenance_user_id"]),
                    id : parseInt(u["user_id"]),
                    title :'○ '+ u["first_name"]+' '+u["last_name"],
                    name : '○ '+u["first_name"]+' '+u["last_name"]
            })
        }

        if (maintenance_users_list.length>0){
            maintenance_users_list.unshift({
                id : -100,
                title : "► Kdokoliv",
                name : "► Kdokoliv",
            })
        }
        if (maintenanceButtonTitle===""){
            setMaintenanceButtonTitle( maintenance_users_list[0]["name"].substr(2))
        }
    }
  

    const getSelectedMaintenanceUsers = () => {
        let selectedUsers = []
        let id = undefined
        let u = undefined
        let myname = undefined
        //console.log(management_users)
        if (parseInt(selectedMaintenanceId) === -100){
            selectedUsers.push( {"maintenance_user_id" : -1, "name" :"Obsluha - kdokoliv" }  ) 
        }
        else{
            for (let i = 0; i<maintenance_users.length; i++){
                u = maintenance_users[i]
                id = u["user_id"] //u["management_user_id"]
                myname = "Obsluha: " +u["first_name"]+' '+u["last_name"]
                if (parseInt(id) === parseInt(selectedMaintenanceId) ){
                    selectedUsers.push( {"maintenance_user_id" : id ,
                    "name" : myname}  ) 
                }
            }
        }
        return selectedUsers
    }

    const getSelectedManagerUsers = () => {
        let selectedUsers = []
        let id = undefined
        let level = undefined
        let u = undefined
        let myname = undefined
        if (selectedManagerId===-1){
            selectedManagerId=-(minlevel)
        }
        if (parseInt(selectedManagerId) === -100){
                selectedUsers.push( {"management_user_id" : -100 , "level":1 }  ) 
        }
        else if (parseInt(selectedManagerId) < 0){
            selectedUsers.push( {
            "management_user_id" : parseInt(selectedManagerId),
            "level" : -parseInt(selectedManagerId) ,
            "name" : "Kdokoliv"
         }  ) 
        }
        else{
            for (let i = 0; i<management_users.length; i++){
                u = management_users[i]
                id = u["user_id"] //u["management_user_id"]
                level = u["management_level"]
                myname = 'Úroveň '+level +': '+u["first_name"]+' '+u["last_name"]
                if (parseInt(id) === parseInt(selectedManagerId) ){
                    selectedUsers.push( {"management_user_id" : id , "level" : level, 
                    "name" : myname}  ) 
                }
            }
        }
        return selectedUsers
    }
    
    
    
    const directForwardClick = ()=>{
        clickedDirectForward([docsToApproval_noTree, docsToApproval_withTree],docsWithoutApproval, "direct")
    }

    const selectManagerChange = (e) => {
        setSelectedManagerId(e.target.value)
        let u =  management_users_list.find(x => parseInt(x["id"])  ===  parseInt(e.target.value) )
        setManagerButtonTitle( 'Úroveň ' + u["level"] + ": "    + u["name"].substr(2))
    }

    const selectMaintenanceChange = (e) => {
        setSelectedMaintenanceId(e.target.value)
        let u =  maintenance_users_list.find(x => parseInt(x["id"])  ===  parseInt(e.target.value) )
        setMaintenanceButtonTitle( u["name"].substr(2))
    }

    const managerClick = () =>{
        const data = getSelectedManagerUsers()
        clicked(data, [docsToApproval_noTree, docsToApproval_withTree],docsWithoutApproval, "management_users")
    }

    const maintenanceClick = () =>{
        const data = getSelectedMaintenanceUsers()
        clicked(data, [docsToApproval_noTree, docsToApproval_withTree],docsWithoutApproval, "maintenance_users")
    }

    const topManagerClick = () => {
        clicked([{ "name" : ""} ], [docsToApproval_noTree, docsToApproval_withTree], docsWithoutApproval, "top_manager")
    }

    const assignToMyself = () => {
        clicked([{ "name" : ""} ], [docsToApproval_noTree, docsToApproval_withTree], docsWithoutApproval, "assign_to_myself")
    }


    let modaltitle = 'Výběr schvalovatele'
    if (maintenance_users_list.length>0 && management_users_list.length===0){
        modaltitle = 'Výběr obslužného uživatele'
    }

    

    if (!forward && (docsToApproval_noTree || docsWithoutApproval) && !docsToApproval_withTree){
        assignToMyself()
    }

    if (assignable_users.data && management_users_list.length===0 && maintenance_users_list.length===0){
        // TOP MANAGER CASE
        topManagerClick()
    }

    //direct force forward if doc type has suddenly become miscellaneous after save
    if (miscellaneousDocType===true){
        directForceForward()
    }

    if (!management_users && !maintenance_users && !docsToApproval_noTree && !docsToApproval_withTree && !docsWithoutApproval ) { 
        return (
            <></>//<ProgressSpinner></ProgressSpinner>
        )
    }
    else if (infos_docsToApproval_noTree.length===0 && infos_docsToApproval_withTree===0 && infos_docsWithoutApproval===0){
        return (
            <></>//<ProgressSpinner></ProgressSpinner>
        )
    }
    else{
        
        return (
            <div className={styles.ontop}> 
            <Modal name={modaltitle} close={close}>

                <div className={styles.docColumn}>
                
                { (docsToApproval_withTree.length>0 && user["role"]!=="ClientUser")   && <>
                <h4>Dokumenty ke schválení (nastavena sch. cesta):</h4>
                <div className={styles.approvalDocuments}>
                    { 
                    infos_docsToApproval_withTree.map((d) => <>  &#8226; {d} <br></br></>
                    )}
                </div>
                </>
                }
                {(docsWithoutApproval.length>0 && user["role"]!=="ClientUser") && <>
                <h4>Dokumenty, které není třeba schvalovat:</h4>
                <div className={styles.withoutApprovalDocuments}>
                    {
                    infos_docsWithoutApproval.map((d) => <>  &#8226; {d} <br></br></>
                    )}
                </div>
                </>
                }
                 {( user["role"]==="ClientUser") && <>
                <h4>Dokumenty k odeslání:</h4>
                <div className={styles.approvalDocuments}>
                    { 
                    infos_docsToApproval_noTree.map((d) => <>  &#8226; {d} <br></br></>
                    )}
                    { 
                    infos_docsToApproval_withTree.map((d) => <>  &#8226; {d} <br></br></>
                    )}
                    { 
                    infos_docsWithoutApproval.map((d) => <>  &#8226; {d} <br></br></>
                    )}
                </div>
                </>
                }
                { (docsToApproval_noTree.length>0 && user["role"]!=="ClientUser")   && <>
                <h4>Dokumenty ke schválení (vybrat schvalovatele):</h4>
                <div className={styles.approvalDocuments}>
                    { 
                    infos_docsToApproval_noTree.map((d) => <>  &#8226; {d} <br></br></>
                    )}
                </div>
                </>
                }
                </div>
                <div className={styles.body}>
                
                { (maintenance_users_list.length>0 && user["role"]==="ClientUser") &&
                <div className={styles.column}>
                            
                            <Select
                                name = {selectedMaintenance}
                               // component={Select}
                                onChange={selectMaintenanceChange}
                                data={maintenance_users_list}
                                onlyvalid ={true}
                                //noBlankOption={true}
                                >
                                Asistentka
                            </Select>

                            <div className={styles.bottombutton}>      
                            <Button primary children={<><span><b>Vybrat obslužného uživatele</b> </span> <br></br> <span>{maintenanceButtonTitle}</span></>} onClick={maintenanceClick} />  
                            </div>
                            {toggle_direct_forward_to_accountant ===true &&
                                <div className={styles.directbutton}>      
                                    <Button children={'Odeslat přímo k účetní'} onClick={directForwardClick} />  
                                </div>
                            }
                </div>
                }
                {(management_users_list.length>0 && (docsToApproval_noTree.length!==0)) &&
                <div className={styles.column}>
                            <Select
                                name = {selectedManager}
                               // component={Select}
                                onChange={selectManagerChange}
                                data={management_users_list}
                                onlyvalid ={true}
                                //noBlankOption={true}
                                >
                                Schvalovatel
                            </Select>

                            <div className={styles.bottombutton}>      
                            <Button primary children={<><span><b>Vybrat schvalovatele</b> </span> <br></br> <span>{managerButtonTitle}</span></> } onClick={managerClick} />  
                            </div>
                            {toggle_direct_forward_to_accountant ===true &&
                                <div className={styles.directbutton}>      
                                    <Button children={'Odeslat přímo k účetní'} onClick={directForwardClick} />  
                                </div>
                            }

                </div>
                }
                {
                    
                    ((management_users_list.length===0 && maintenance_users_list.length===0) ||
                     (docsToApproval_noTree.length===0)) &&
                     <div className={styles.column}>
                    <div className={styles.bottombutton}>      
                    <Button children={'Odeslat'} primary onClick={topManagerClick} />  
                    
                    </div>
                 
                    </div>
                }
                 
                 
                 
                </div>
                
            </Modal>
            </div>
        )
    }
    
}; 