import React from 'react';
import {Link} from 'react-router-dom';
import {Formik, Form} from 'formik';

import {Button} from 'components/Button';
import styles from 'css_modules/AuthForm.module.css';

export const AuthForm = ({anchor, button, handleSubmit, heading, href, initialValues, info, messages, children}) => {
    return (
        <>
            <h1 className={styles.heading}>{heading}</h1>
            {messages ?
                messages.map((message, index) => <p className={styles.info} key={index}>{message}</p>) :
                <>
                    {info && <p className={styles.info}>{info}</p>}
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        {({status}) =>
                            <Form className={styles.form}>
                                {children}
                                <Button primary type="submit">{button}</Button>
                                {status && <p>{status}</p>}
                            </Form>
                        }
                    </Formik>
                    {anchor && <Link to={href} className={styles.link}>{anchor}</Link>}
                </>
            }
        </>
    )
};