import React from 'react';

import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import styles from 'css_modules/ExportModal.module.css';
import {generateXLSX, generateCSV, formatDate, formatPrice} from "../helpers/tools";
import {useFetch} from 'helpers/hooks';

export const ExportTableModal = ({close,docs,fields,visibleFields}) => {
    
   
    const format = (type, text) => {
        if (type === 'date') {
            return formatDate(text);
        } else if (type === 'bool'){
            return text ? 'Ano' : 'Ne';
        } else if(type === 'price'){
            return formatPrice(text);
        } else if(type === 'dph'){
            return formatPrice(text);
        } else {
            return text;
        }
    }

    const prepareJSON = () => {
        let json = []
        docs.forEach( doc => {
            let docdata = {}
            visibleFields.forEach( field => {
                let keys = field.split(/\./);
                let val = ''
                let name = field
                if (keys.length === 2) {
                    val = format(fields[keys[0]].items[keys[1]].type, doc[keys[1]])
                    name = fields[keys[0]].items[keys[1]].name
                }
                else{
                    val = format(fields[keys[0]].items[keys[1] + '.' + keys[2]].type, doc[keys[1]][keys[2]])
                    name = fields[keys[0]].items[keys[1]+"."+keys[2]].name
                }
                docdata[name] = val
            })
            json.push(docdata)
        })
        return json
    }

    const exportCSV = () => {
        generateCSV(prepareJSON())
        close()
    }

    
    const exportXLSX = () => {
        generateXLSX(prepareJSON())
        close()
    }

    let uuids = []
    docs.forEach(d=>{
        uuids.push(d.uuid)
    })
 
    
    function downloadSingle(text) {
        var BOM = "\uFEFF";
        text = BOM + text;
       
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', "document.isdoc");
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }

    function download(blob) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = "docspoint_export.zip"
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }

    const  doFetchDownload = async () => {
         fetch(process.env.REACT_APP_API + '/get_generated_isdoc/?documents='+[...uuids], {
            method: 'GET',
            headers: {
                'Authorization': 'JWT ' + localStorage.getItem('DocspointJWT')
            }
          }).then((response) => {
            response.blob().then(blob => download(blob))})
    }

    let isdoc = useFetch('/get_generated_isdoc/?documents='+[uuids[0]])
    function exportISDOC(){
        if (uuids.length>1){
            doFetchDownload()
        }
        else{
            downloadSingle(isdoc.data[uuids[0]]);
        }
            close()
        }
        
    
    const modaltitle = 'Exportovat'
    return (
        <>
        <div className={styles.ontop}>
        <Modal name={modaltitle} close={close} >
            <div className={styles.column}>
            <div className={styles.singlebutton}>
                    <Button children={"Metadata do ISDOC"} onClick={exportISDOC} className={"wideprimary"} disabled={false} />
                </div>

                <div className={styles.singlebutton}>
                    <Button children={'Náhled do XLSX'} onClick={exportXLSX}  className={"wideprimary"} />
                </div>

                <div className={styles.singlebutton}>
                    <Button children={'Náhled  do CSV'} onClick={exportCSV} className={"wideprimary"}  />
                </div>
                
                
            </div>
        </Modal>
        </div>
        </>
    )
};