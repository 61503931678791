import React from 'react'

import styles from 'css_modules/Avatar.module.css';

export const Avatar = ({user}) => {
    const avatar = user.avatar;
    const initials = `${user.first_name} ${user.last_name}`.split(' ').map(word => word[0]).join('');

    return (
        avatar ?
            <img src={avatar} alt='avatar' className={styles.profilePicture}/> :
            <div className={styles.circle}>
                <p className={styles.initials}>{initials}</p>
            </div>
    )
};
