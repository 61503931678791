import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Form, Field, withFormik} from 'formik';

import {ReactComponent as SearchIcon} from 'assets/svg/search.svg';
import styles from 'css_modules/SearchBar.module.css';

const MyForm = ({handleSubmit, resetForm, values}) => {
    const location = useLocation();

    useEffect(() => { // auto submit
        const handle = setTimeout(handleSubmit, 250); // debouncer
        return () => clearTimeout(handle);
    }, [handleSubmit, values.search]);

    useEffect(() => { // clear when moving between tabs
        resetForm();
    }, [location.pathname, resetForm]);

    return (
        <Form className={styles.container}>
            <SearchIcon className={styles.icon}/>
            <Field
                name="search"
                placeholder="Hledat v metadatech"
                className={styles.input}
            />
        </Form>
    )
};

export const SearchBar = withFormik({
    mapPropsToValues: ({search}) => ({ search }),
    handleSubmit: (values, {setSubmitting, props}) => {
        // props.setPage(1);
        props.setSearch(values.search);
        setSubmitting(false)
    },
    enableReinitialize: true
})(MyForm);